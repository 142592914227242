import { trackFocusVisible } from "@zag-js/focus-visible";
import { useEffect, useState } from "react";

export function useFocusVisible() {
  const [isFocusVisible, setIsFocusVisible] = useState(false);

  useEffect(() => {
    return trackFocusVisible(setIsFocusVisible);
  }, []);

  const focusVisibleProps = {
    "data-focus-visible": isFocusVisible ? "" : undefined,
  };

  return {
    isFocusVisible,
    focusVisibleProps,
  };
}
