import * as checkbox from "@daangn/sprout-machines-checkbox";
import { useEvent } from "@daangn/sprout-react-utils";
import { normalizeProps, useMachine } from "@zag-js/react";
import { useId } from "react";

import type { CheckboxProps } from "./types";

export function useCheckbox(props: CheckboxProps): checkbox.Api {
  const {
    isDisabled: disabled = false,
    isIndeterminate: indeterminate = false,
    isInvalid: invalid = false,
    isRequired: required = false,
    isSelected: selected,
    defaultSelected = false,
    name,
    value,
    onChange,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    "aria-describedby": ariaDescribedBy,
    "aria-details": ariaDetails,
    "aria-errormessage": ariaErrorMessage,
  } = props;

  const id = useId();

  const initialContext: checkbox.Context = {
    id,
    disabled,
    indeterminate,
    invalid,
    required,
    selected: selected ?? defaultSelected,
    name,
    value,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    "aria-describedby": ariaDescribedBy,
    "aria-details": ariaDetails,
    "aria-errormessage": ariaErrorMessage,
    onChange: useEvent(onChange),
  };

  const context = {
    ...initialContext,
    selected,
  };

  const [state, send] = useMachine(checkbox.machine(initialContext), {
    context,
  });

  const api = checkbox.connect(state, send, normalizeProps);

  return api;
}
