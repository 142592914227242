import fulfillVersion from "./fulfillVersion";
import isCrashPossible from "./isCrashPossible";

export const featureVersions = {
  externalRouting: {
    Android: "23.16.0",
    iOS: "23.9.0",
  },
  inAppBrowser: {
    Android: "23.16.0",
    iOS: "23.17.0",
  },
};

export default function supportsFeature(
  feature: keyof typeof featureVersions,
  {
    appVersion,
    platform,
    url,
  }: {
    appVersion: string;
    platform: string;
    url: string;
  },
) {
  return (
    fulfillVersion(
      appVersion,
      featureVersions[feature][platform as "Android" | "iOS"],
    ) &&
    !isCrashPossible(feature, {
      appVersion,
      platform,
      url,
    })
  );
}
