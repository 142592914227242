import "@daangn/sprout-css/callout/index.css";

import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import type { Ref } from "react";
import React from "react";

import type { SeedCalloutProps } from "./types";

const Callout = (props: SeedCalloutProps, ref: Ref<HTMLDivElement>) => {
  const { variant = "normal", children, icon, suffix, ...otherProps } = props;
  const { styleProps } = useStyleProps(otherProps);

  return (
    <div
      ref={ref}
      {...styleProps}
      data-variant={variant}
      data-part="root"
      className={composeClassName("seed-callout", styleProps.className)}
    >
      {icon && <div data-part="icon">{icon}</div>}
      <div data-part="content">{children}</div>
      <div data-part="suffix">{suffix}</div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _Callout = React.forwardRef(Callout);
export { _Callout as Callout };
