import { vars } from "@seed-design/design-token";

import type {
  BaseStyleProps,
  ColorValue,
  ContainerStyleProps,
  FlexStyleProps,
  TextStyleProps,
} from "./types";

type StyleName = string[];
type StyleHandler = (value: any) => string | undefined;
export type StyleHandlers<T extends Record<string, any>> = {
  [key in keyof T]: [StyleName, StyleHandler];
};

export function identical<T>(value: T): T {
  return value;
}

function handleColor(value: ColorValue) {
  return (
    vars.$scale.color[value] ??
    vars.$static.color[value] ??
    vars.$semantic.color[value]
  );
}

function handleHidden(value: boolean) {
  return value ? "none" : undefined;
}

function handleFlex(value: boolean | number | string) {
  if (typeof value === "boolean") {
    return value ? "1" : undefined;
  }

  return "" + value;
}

function handleFlexAlign(value: FlexStyleProps["justifyContent"] | string) {
  if (value === "start") {
    return "flex-start";
  }

  if (value === "end") {
    return "flex-end";
  }

  return value;
}

function handleWrap(value: boolean | "wrap" | "nowrap" | "wrap-reverse") {
  if (typeof value === "boolean") {
    return value ? "wrap" : undefined;
  }

  return value;
}

export const basePropHandlers: StyleHandlers<BaseStyleProps> = {
  margin: [["margin"], identical],
  marginLeft: [["marginLeft"], identical],
  marginRight: [["marginRight"], identical],
  marginTop: [["marginTop"], identical],
  marginBottom: [["marginBottom"], identical],
  marginX: [["marginLeft", "marginRight"], identical],
  marginY: [["marginTop", "marginBottom"], identical],
  width: [["width"], identical],
  height: [["height"], identical],
  minWidth: [["minWidth"], identical],
  minHeight: [["minHeight"], identical],
  maxWidth: [["maxWidth"], identical],
  maxHeight: [["maxHeight"], identical],
  isHidden: [["display"], handleHidden],
  alignSelf: [["alignSelf"], identical],
  justifySelf: [["justifySelf"], identical],
  position: [["position"], identical],
  zIndex: [["zIndex"], identical],
  top: [["top"], identical],
  bottom: [["bottom"], identical],
  start: [["start"], identical],
  end: [["end"], identical],
  left: [["left"], identical],
  right: [["right"], identical],
  order: [["order"], identical],
  flex: [["flex"], handleFlex],
  flexGrow: [["flexGrow"], identical],
  flexShrink: [["flexShrink"], identical],
  flexBasis: [["flexBasis"], identical],
  gridArea: [["gridArea"], identical],
  gridColumn: [["gridColumn"], identical],
  gridColumnEnd: [["gridColumnEnd"], identical],
  gridColumnStart: [["gridColumnStart"], identical],
  gridRow: [["gridRow"], identical],
  gridRowEnd: [["gridRowEnd"], identical],
  gridRowStart: [["gridRowStart"], identical],
};

export const containerPropHandlers: StyleHandlers<ContainerStyleProps> = {
  ...basePropHandlers,
  backgroundColor: [["backgroundColor"], handleColor],
  borderWidth: [["borderWidth"], identical],
  borderStartWidth: [["borderStartWidth"], identical],
  borderEndWidth: [["borderEndWidth"], identical],
  borderTopWidth: [["borderTopWidth"], identical],
  borderBottomWidth: [["borderBottomWidth"], identical],
  borderXWidth: [["borderLeftWidth", "borderRightWidth"], identical],
  borderYWidth: [["borderTopWidth", "borderBottomWidth"], identical],
  borderColor: [["borderColor"], handleColor],
  borderStartColor: [["borderStartColor"], handleColor],
  borderEndColor: [["borderEndColor"], handleColor],
  borderTopColor: [["borderTopColor"], handleColor],
  borderBottomColor: [["borderBottomColor"], handleColor],
  borderXColor: [["borderLeftColor", "borderRightColor"], handleColor],
  borderYColor: [["borderTopColor", "borderBottomColor"], handleColor],
  borderRadius: [["borderRadius"], identical],
  borderTopStartRadius: [["borderTopStartRadius"], identical],
  borderTopEndRadius: [
    ["borderTopRightRadius", "borderTopLeftRadius"],
    identical,
  ],
  borderBottomStartRadius: [["borderBottomStartRadius"], identical],
  borderBottomEndRadius: [
    ["borderBottomRightRadius", "borderBottomLeftRadius"],
    identical,
  ],
  padding: [["padding"], identical],
  paddingLeft: [["paddingLeft"], identical],
  paddingRight: [["paddingRight"], identical],
  paddingTop: [["paddingTop"], identical],
  paddingBottom: [["paddingBottom"], identical],
  paddingX: [["paddingLeft", "paddingRight"], identical],
  paddingY: [["paddingTop", "paddingBottom"], identical],
  overflow: [["overflow"], identical],
};

export const flexPropHandlers: StyleHandlers<FlexStyleProps> = {
  ...containerPropHandlers,
  direction: [["flexDirection"], identical],
  wrap: [["flexWrap"], handleWrap],
  justifyContent: [["justifyContent"], handleFlexAlign],
  alignItems: [["alignItems"], handleFlexAlign],
  alignContent: [["alignContent"], handleFlexAlign],
  gap: [["gap"], identical],
  rowGap: [["rowGap"], identical],
  columnGap: [["columnGap"], identical],
};

export const textPropHandlers: StyleHandlers<TextStyleProps> = {
  ...basePropHandlers,
  color: [["color"], handleColor],
  textAlign: [["textAlign"], identical],
  textDecoration: [["textDecoration"], identical],
  textTransform: [["textTransform"], identical],
  whiteSpace: [["whiteSpace"], identical],
  wordBreak: [["wordBreak"], identical],
  textOverflow: [["textOverflow"], identical],
  overflowWrap: [["overflowWrap"], identical],
};
