import "@daangn/sprout-css/avatar/index.css";

import {
  composeClassName,
  containerPropHandlers,
  useStyleProps,
} from "@daangn/sprout-style-utils";
import { classNames } from "@seed-design/design-token";
import type { Ref } from "react";
import React from "react";

import { AvatarGroupContext } from "./context";
import type { SeedAvatarGroupProps } from "./types";

const typographyClassNameMap: Record<
  Required<SeedAvatarGroupProps>["size"],
  string
> = {
  large: classNames.$semantic.typography.title2Regular,
  medium: classNames.$semantic.typography.label3Regular,
  small: classNames.$semantic.typography.label5Regular,
  xsmall: classNames.$semantic.typography.label6Regular,
  xxsmall: classNames.$semantic.typography.label6Regular,
};

const AvatarGroupOverflowCount = ({
  size,
  overflowCount,
}: {
  overflowCount: number;
  size: Required<SeedAvatarGroupProps>["size"];
}) => {
  return (
    <span
      data-group-excess-size={size}
      className={composeClassName(
        "seed-avatar-group-excess",
        typographyClassNameMap[size],
      )}
    >
      +{overflowCount}
    </span>
  );
};

const AvatarGroup = (props: SeedAvatarGroupProps, ref: Ref<HTMLDivElement>) => {
  const {
    children,
    size = "large",
    topItem = "lastItem",
    limit,
    ...otherProps
  } = props;
  const { styleProps } = useStyleProps(otherProps, containerPropHandlers);

  const avatars = React.Children.toArray(children);
  const slicedAvatars = limit == null ? avatars : avatars.slice(0, limit);
  const renderedAvatars =
    topItem === "firstItem" ? slicedAvatars.reverse() : slicedAvatars;
  const overflowCount = avatars.length - renderedAvatars.length;

  return (
    <AvatarGroupContext.Provider value={{ size }}>
      <div
        ref={ref}
        {...styleProps}
        data-group-size={size}
        data-top-item={topItem}
        className={composeClassName("seed-avatar-group", styleProps.className)}
      >
        {topItem === "lastItem" && renderedAvatars}
        {overflowCount > 0 && (
          <AvatarGroupOverflowCount overflowCount={overflowCount} size={size} />
        )}
        {topItem === "firstItem" && renderedAvatars}
      </div>
    </AvatarGroupContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _AvatarGroup = React.forwardRef(AvatarGroup);
export { _AvatarGroup as AvatarGroup };
