import createScheme, { createMinikarrotScheme } from "./createScheme";
import type { LinkParams, MinikarrotParams, SchemeParams } from "./types";
import supportsFeature from "./supportsFeature";
import getAppInfo from "./getAppInfo";

export const createExternalBrowserProps = ({ url, stage }: LinkParams) => {
  const { appVersion, platform } = getAppInfo();

  const supportExternalRouting = supportsFeature("externalRouting", {
    appVersion,
    platform,
    url,
  });

  return supportExternalRouting
    ? {
        href: createScheme({
          stage,
          path: "external-routing",
          searchParams: new URLSearchParams({
            target_url: url,
          }),
        }),
      }
    : {
        href: url,
        target: "_blank",
      };
};

export const openExternalBrowser = ({ url, stage }: LinkParams) => {
  const { href, target } = createExternalBrowserProps({
    url,
    stage,
  });

  if (target) {
    window.open(href, target);
  } else {
    location.href = href;
  }
};

export const createInAppBrowserScheme = ({ url, stage }: LinkParams) => {
  const { appVersion, platform } = getAppInfo();

  const supportInAppBrowser = supportsFeature("inAppBrowser", {
    appVersion,
    platform,
    url,
  });

  return supportInAppBrowser
    ? createScheme({
        stage,
        path: "in-app-browser",
        searchParams: new URLSearchParams({
          target_url: url,
        }),
      })
    : createMinikarrotScheme({
        url,
        stage,
        navbar: true,
        scrollable: true,
        presentTop: true,
      });
};

export const openInAppBrowser = ({ url, stage }: LinkParams) => {
  location.href = createInAppBrowserScheme({
    url,
    stage,
  });
};

export { createMinikarrotScheme };

export const openMinikarrotWebview = (params: MinikarrotParams) => {
  location.href = createMinikarrotScheme(params);
};

export { createScheme };

export const openScheme = ({ stage, path, searchParams }: SchemeParams) => {
  location.href = createScheme({
    stage,
    path,
    searchParams,
  });
};
