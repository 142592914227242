/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
import { createMachine } from "@zag-js/core";
import {
  dispatchInputCheckedEvent,
  trackFormControl,
} from "@zag-js/form-utils";
import { compact } from "@zag-js/utils";

import { dom } from "./checkbox.dom";
import type {
  MachineContext,
  MachineState,
  UserDefinedContext,
} from "./checkbox.types";

export function machine(userContext: UserDefinedContext) {
  const ctx = compact(userContext);
  return createMachine<MachineContext, MachineState>(
    {
      id: "checkbox",
      initial: "ready",

      context: {
        selected: false,
        indeterminate: false,
        value: "on",
        ...ctx,
      },

      watch: {
        disabled: "removeFocusIfNeeded",
        selected: "syncInputElement",
      },

      activities: ["trackFormControlState"],

      on: {
        "SELECTED.TOGGLE": {
          actions: ["toggleSelected"],
        },
        "SELECTED.SET": {
          actions: ["setSelected"],
        },
        "CONTEXT.SET": {
          actions: ["setContext"],
        },
      },

      states: {
        ready: {},
      },
    },
    {
      activities: {
        trackFormControlState(ctx, _evt, { send, initialContext }) {
          return trackFormControl(dom.getHiddenInputEl(ctx), {
            onFieldsetDisabledChange(disabled) {
              ctx.disabled = disabled;
            },
            onFormReset() {
              send({
                type: "SELECTED.SET",
                selected: !!initialContext.selected,
              });
            },
          });
        },
      },

      actions: {
        setContext(ctx, evt) {
          Object.assign(ctx, evt.context);
        },
        syncInputElement(ctx) {
          const inputEl = dom.getHiddenInputEl(ctx);
          if (!inputEl) return;
          inputEl.checked = ctx.selected;
          inputEl.indeterminate = ctx.indeterminate;
        },
        removeFocusIfNeeded(ctx) {
          if (ctx.disabled && ctx.focused) {
            ctx.focused = false;
          }
        },
        setSelected(ctx, evt) {
          set.selected(ctx, evt.selected);
        },
        toggleSelected(ctx) {
          set.selected(ctx, ctx.selected);
        },
      },
    },
  );
}

const invoke = {
  change: (ctx: MachineContext) => {
    // invoke fn
    ctx.onChange?.(ctx.selected);

    // form event
    const inputEl = dom.getHiddenInputEl(ctx);
    dispatchInputCheckedEvent(inputEl, {
      checked: ctx.selected,
      bubbles: true,
    });
  },
};

const set = {
  selected: (ctx: MachineContext, selected: boolean) => {
    ctx.selected = selected;
    invoke.change(ctx);
  },
};
