/* eslint-disable @typescript-eslint/no-shadow */
import { createMachine } from "@zag-js/core";
import { observeAttributes } from "@zag-js/mutation-observer";

import { dom } from "./avatar.dom";
import type {
  MachineContext,
  MachineState,
  UserDefinedContext,
} from "./avatar.types";

export function machine(ctx: UserDefinedContext) {
  return createMachine<MachineContext, MachineState>(
    {
      id: "avatar",
      initial: "loading",

      context: {
        fallbackDelay: 0,
        ...ctx,
      },

      activities: ["trackSrcChange"],

      states: {
        loading: {
          activities: ["trackImageLoad"],
          entry: ["invokeOnLoadStart"],
          after: {
            FALLBACK_DELAY: "fallback",
          },
          on: {
            IMAGE_LOAD: "loaded",
            IMAGE_ERROR: "error",
            SRC_CHANGE: "loading",
          },
        },

        loaded: {
          activities: ["trackSrcChange"],
          entry: ["invokeOnLoadSuccess"],
          on: {
            SRC_CHANGE: "loading",
          },
        },

        fallback: {
          activities: ["trackImageLoad"],
          entry: ["invokeOnLoadFallback"],
          on: {
            IMAGE_LOAD: "loaded",
            IMAGE_ERROR: "error",
            SRC_CHANGE: "loading",
          },
        },

        error: {
          entry: ["invokeOnLoadError"],
          on: {
            SRC_CHANGE: "loading",
          },
        },
      },
    },
    {
      activities: {
        trackSrcChange(ctx, _evt, { send }) {
          const img = dom.getImageEl(ctx);
          return observeAttributes(img, ["src", "srcSet"], () => {
            return send("SRC_CHANGE");
          });
        },
        trackImageLoad(ctx, _evt, { send }) {
          const img = dom.getImageEl(ctx);
          if (img?.complete) {
            if (img?.naturalHeight > 0) {
              send("IMAGE_LOAD");
            } else {
              send("IMAGE_ERROR");
            }
          }
        },
      },
      actions: {
        invokeOnLoadStart(ctx) {
          ctx.onLoadStart?.();
        },
        invokeOnLoadSuccess(ctx) {
          ctx.onLoadSuccess?.();
        },
        invokeOnLoadError(ctx) {
          ctx.onLoadError?.();
        },
        invokeOnLoadFallback(ctx) {
          ctx.onLoadFallback?.();
        },
      },
      delays: {
        FALLBACK_DELAY: (ctx) => ctx.fallbackDelay,
      },
    },
  );
}
