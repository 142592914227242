const regex =
  /(?<userAgent>TowneersApp\/(?<versionName>\d+\.\d+\.\d+)\s\((?<versionCode>\d+);\s*(?<osType>iOS|Android)\s(?<osVersion>[^;]+);\s*(?<appType>\w+);\s*(?<buildType>\w+)\))/;

const minimumAppVersionCode = {
  iOS: 234000,
  Android: 234000,
};

export default function checkVersionCode(): boolean {
  if (typeof navigator === "undefined") {
    return false;
  }

  const { userAgent } = navigator;

  const result = userAgent.match(regex);

  const versionCode = result?.groups?.versionCode;
  const platform = result?.groups?.osType;

  if (!versionCode || !platform) {
    return false;
  }

  return (
    parseInt(versionCode, 10) >=
    minimumAppVersionCode[platform as keyof typeof minimumAppVersionCode]
  );
}
