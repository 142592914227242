import { useCallback, useEffect, useRef, useState } from "react";
import { EVENT_NAMES, logEvent } from "../event";
import type { Bridge } from "../types";
import { getAppInfo } from "../utils";
import useTransferIdentification from "./use-transfer-identification";

interface VerifyIdentificationOptions {
  bridge: Bridge;

  agreementUrl: string;
  transferIdentificationScheme: string;
  newIdentificationScheme: string;

  fetchVerify: () => Promise<Response>;
  onError?: (e: Error) => void;
  onNotIdentified: () => void;
  onIdentified: () => void;
  onMoveAgreement: () => void;
}

export default function useVerifyIdentification({
  bridge,

  agreementUrl,
  transferIdentificationScheme,
  newIdentificationScheme,

  fetchVerify,
  onError,
  onIdentified,
  onNotIdentified,
  onMoveAgreement,
}: VerifyIdentificationOptions) {
  const [tryTransferOrNewIdentification, setTryTransferOrNewIdentification] =
    useState(false);

  const { setShouldOpen, previous_screen_name } = useTransferIdentification();

  const isEventSent = useRef(false);

  useEffect(() => {
    if (isEventSent.current) return;

    logEvent(bridge, EVENT_NAMES.clientShownIdentificationBottomSheet, {
      previous_screen_name,
    });

    isEventSent.current = true;
  }, [bridge, previous_screen_name]);

  const error = useCallback(
    (e: Error) => {
      if (onError === undefined) {
        alert("일시적인 오류가 발생했어요. 다시 시도해주세요.");
        return;
      }
      onError(e);
    },
    [onError],
  );

  const moveToNewIdentification = useCallback((): void => {
    // [Test]
    // setShouldOpen(true);
    // return;

    const appInfo = getAppInfo();
    if (!appInfo || Number.parseInt(appInfo.versionCode, 10) < 234600) {
      setShouldOpen(true);
      return;
    }

    setTryTransferOrNewIdentification(true);
    window.location.href = newIdentificationScheme;
  }, [newIdentificationScheme, setShouldOpen]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    async function handleVisibilityChange() {
      if (!tryTransferOrNewIdentification) return;

      if (document.visibilityState === "visible") {
        // [Test]
        // setShouldDialogueOpen(true);
        // return;

        try {
          const res = await fetchVerify();

          if (res.status === 200) {
            onIdentified();
            return;
          }

          if (res.status === 404) {
            onNotIdentified();
            return;
          }

          error(
            new Error(
              "handleVisibilityChange's fetchVerify response status is not 200 or 404",
            ),
          );
        } catch (e) {
          if (e instanceof Error) {
            error(e);
          } else {
            error(
              new Error(
                "handleVisibilityChange's fetchVerify catch unknown error",
              ),
            );
          }
        }
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [
    fetchVerify,
    onIdentified,
    onNotIdentified,
    onError,
    tryTransferOrNewIdentification,
    error,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const handler = (e: MessageEvent) => {
      const url = new URL(agreementUrl);
      if (e.origin !== url.origin) return;

      if (e.data.type === "primary") {
        logEvent(
          bridge,
          "client_clicked_identification_pay_policyAgreement_bottomSheet_button_v1",
          {
            button: "confirm",
            previous_screen_name,
          },
        );
        setTryTransferOrNewIdentification(true);
        window.location.href = transferIdentificationScheme.replace(
          "PREVIOUS_SCREEN_NAME",
          previous_screen_name,
        );
        return;
      }

      if (e.data.type === "secondary") {
        logEvent(
          bridge,
          "client_clicked_identification_pay_policyAgreement_bottomSheet_button_v1",
          {
            button: "skip",
            previous_screen_name,
          },
        );
        moveToNewIdentification();
        return;
      }
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
    };
  }, [
    bridge,
    agreementUrl,
    moveToNewIdentification,
    newIdentificationScheme,
    transferIdentificationScheme,
    previous_screen_name,
  ]);

  const onNextClick = useCallback(async () => {
    // [Test]
    // onMoveAgreement();
    // setShouldOpen(true);
    // return;

    try {
      const res = await fetchVerify();

      if (res.status === 200) {
        // 이상한 상황, "30초 만에 인증하기" 를 눌렀는데 200이 나온다.
        onIdentified();
        return;
      }

      // https://daangn.slack.com/archives/CSSSW5LAZ/p1704883800053639?thread_ts=1704764883.326609&cid=CSSSW5LAZ
      if (res.status === 404) {
        const data: { type: string; metadata_proto_json: string } =
          await res.json();

        const {
          hasKarrotPayIdentification,
          has_karrot_pay_identification,
        }: {
          hasKarrotPayIdentification?: boolean;
          has_karrot_pay_identification?: boolean;
        } = JSON.parse(data.metadata_proto_json);

        // 페이 인증정보 없음 (1)
        // "metadata_proto_json": "{}"
        if (
          hasKarrotPayIdentification === undefined &&
          has_karrot_pay_identification === undefined
        ) {
          moveToNewIdentification();
          return;
        }

        // 페이 인증정보 없음 (2)
        // "metadata_proto_json": "{\"hasKarrotPayIdentification\":false}" (https://daangn.slack.com/archives/CSSSW5LAZ/p1722508461449199?thread_ts=1722505836.894909&cid=CSSSW5LAZ)
        // 페이 인증정보 없음 (3)
        // "metadata_proto_json": "{\"has_karrot_pay_identification\":false}" (https://daangn.slack.com/archives/C02NVJC937A/p1723175622611759)
        // 아래 두가지 경우는 없겠지만, 혹시 모르니까 체크
        // 페이 인증정보 없음 (4)
        // "metadata_proto_json": "{\"hasKarrotPayIdentification\":false,\"has_karrot_pay_identification\":true}"
        // 페이 인증정보 없음 (5)
        // "metadata_proto_json": "{\"hasKarrotPayIdentification\":true,\"has_karrot_pay_identification\":false}"
        if (
          hasKarrotPayIdentification === false ||
          has_karrot_pay_identification === false
        ) {
          moveToNewIdentification();
          return;
        }

        // 페이 인증정보 있음 (6)
        // "metadata_proto_json":"{\"hasKarrotPayIdentification\":true}"
        // 페이 인증정보 있음 (7)
        // "metadata_proto_json":"{\"has_karrot_pay_identification\":true}"
        // 페이 인증정보 있음 (8)
        // "metadata_proto_json": "{\"hasKarrotPayIdentification\":true,\"has_karrot_pay_identification\":true}"
        onMoveAgreement();
        return;
      }

      error(
        new Error(
          "onNextClick's fetchVerify response status is not 200 or 404",
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        error(e);
      } else {
        error(new Error("onNextClick's fetchVerify catch unknown error"));
      }
    }
  }, [
    error,
    fetchVerify,
    moveToNewIdentification,
    onIdentified,
    onMoveAgreement,
  ]);

  return {
    onNextClick,
  };
}
