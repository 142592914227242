import { ariaAttr, dataAttr } from "@zag-js/dom-query";
import type { NormalizeProps, PropTypes } from "@zag-js/types";

import { parts } from "./text-field.anatomy";
import { dom } from "./text-field.dom";
import type { MachineApi, Send, State } from "./text-field.types";

export function connect<
  T extends PropTypes & { textarea: Record<string, any> },
>(state: State, send: Send, normalize: NormalizeProps<T>): MachineApi<T> {
  const {
    elementType = "input",
    isDisabled = false,
    isInvalid = false,
    isReadOnly = false,
    isRequired = false,
    type = "text",
    "aria-label": ariaLabel,
    renderedElements: rendered,
  } = state.context;

  const isFocused = !isDisabled && state.context.focused;

  const inputOnlyProps =
    elementType === "input"
      ? {
          type,
          pattern: state.context.pattern,
        }
      : {};

  const ariaLabelledBy =
    state.context["aria-labelledby"] ?? dom.getLabelId(state.context);
  const ariaDescribedBy =
    [
      rendered.description ? dom.getDescriptionId(state.context) : false,
      rendered.errorMessage ? dom.getErrorMessageId(state.context) : false,
      state.context["aria-describedby"],
    ]
      .filter(Boolean)
      .join(" ") || undefined;

  const value = state.context.value;
  const graphemes = state.context.graphemes;

  return {
    value,
    graphemes,
    isDisabled,
    isInvalid,
    isFocused,
    rootProps: normalize.element({
      id: dom.getRootId(state.context),
      ...parts.root.attrs,
      "data-focus": dataAttr(isFocused),
      "data-disabled": dataAttr(isDisabled),
      "data-readonly": dataAttr(isReadOnly),
      "data-invalid": dataAttr(isInvalid),
    }),
    labelProps: normalize.label({
      id: dom.getLabelId(state.context),
      ...parts.label.attrs,
      htmlFor: dom.getInputId(state.context),
    }),
    inputProps: normalize.input({
      id: dom.getInputId(state.context),
      ...parts.input.attrs,
      ...inputOnlyProps,
      disabled: isDisabled,
      readOnly: isReadOnly,
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledBy,
      "aria-describedby": ariaDescribedBy,
      "aria-required": ariaAttr(isRequired),
      "aria-invalid": ariaAttr(isInvalid),
      "aria-errormessage": state.context["aria-errormessage"],
      "aria-activedescendant": state.context["aria-activedescendant"],
      "aria-autocomplete": state.context["aria-autocomplete"],
      "aria-haspopup": state.context["aria-haspopup"],
      defaultValue: state.context.value,
      autoFocus: state.context.autoFocus,
      onChange: (e) => {
        send({ type: "CHANGE", value: e.currentTarget.value });
      },
      onBlur(e) {
        state.context.onBlur?.(e);
        send({ type: "SET_FOCUSED", value: false, event: e });
      },
      onFocus(e) {
        state.context.onFocus?.(e);
        send({ type: "SET_FOCUSED", value: true, event: e });
      },
      autoComplete: state.context.autoComplete,
      "data-grapheme-count": graphemes.length,
      minLength: state.context.minLength,
      name: state.context.name,
      placeholder: state.context.placeholder,
      inputMode: state.context.inputMode,
    }) as T["input"] & T["textarea"],
    descriptionProps: normalize.element({
      id: dom.getDescriptionId(state.context),
      ...parts.description.attrs,
    }),
    errorMessageProps: normalize.element({
      id: dom.getErrorMessageId(state.context),
      ...parts.errorMessage.attrs,
    }),
  };
}
