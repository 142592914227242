import "@daangn/sprout-css/fab/index.css";

import { useButton } from "@daangn/sprout-hooks-button";
import { useFocusVisible } from "@daangn/sprout-hooks-interactions";
import { useMenu } from "@daangn/sprout-hooks-menu";
import { mergeProps } from "@daangn/sprout-react-utils";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import { classNames } from "@seed-design/design-token";
import type { Ref } from "react";
import * as React from "react";

import type { SeedMenuFabProps } from "../types";
import { MenuFabContext } from "./context";
import { MenuFabIcon } from "./MenuFabIcon";

const MenuFab = (props: SeedMenuFabProps, ref: Ref<HTMLButtonElement>) => {
  const { isExtended, label, children, ...otherProps } = props;
  const api = useMenu({
    ...otherProps,
  });
  const { triggerProps, positionerProps, contentProps, isOpen } = api;
  const { buttonProps } = useButton({});
  const { styleProps } = useStyleProps(otherProps);
  const { focusVisibleProps } = useFocusVisible();

  const shouldExtend = isExtended && !isOpen;

  const childrenArray = React.Children.toArray(children);
  if (childrenArray.length === 0 || childrenArray.length > 2) {
    throw new Error(
      "[Sprout] MenuFab should have [MenuFabItemGroup] or [MenuFabItemGroup, MenuFabPrimaryItem] as children.",
    );
  }

  return (
    <>
      <button
        ref={ref}
        {...styleProps}
        className={composeClassName("seed-menu-fab", styleProps.className)}
        {...mergeProps(buttonProps, triggerProps)}
        {...focusVisibleProps}
        data-extended={shouldExtend ? true : null}
      >
        <MenuFabIcon />
        {/* TODO: visually hidden label instead of removing from DOM. */}
        {shouldExtend && (
          <span className={classNames.$semantic.typography.label2Bold}>
            {label}
          </span>
        )}
      </button>
      {isOpen && (
        <div
          className={composeClassName("seed-menu-fab")}
          data-part="backdrop"
        />
      )}
      <div className={composeClassName("seed-menu-fab")} {...positionerProps}>
        <div {...contentProps}>
          <MenuFabContext.Provider value={{ api }}>
            {children}
          </MenuFabContext.Provider>
        </div>
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _MenuFab = React.forwardRef(MenuFab);
export { _MenuFab as MenuFab };
