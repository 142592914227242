import * as radioGroup from "@daangn/sprout-machines-radio-group";
import { useMachine } from "@zag-js/react";
import React from "react";

import type { RadioGroupProps } from "./types";

export function useRadioGroupState(
  props: RadioGroupProps,
): readonly [radioGroup.State, radioGroup.Send] {
  const {
    orientation = "vertical",
    name,
    isDisabled: disabled,
    isReadOnly: readOnly,
    onChange,
    value,
    defaultValue,
  } = props;

  const id = React.useId();
  const initialContext: radioGroup.Context = {
    id,
    orientation,
    name,
    disabled,
    readOnly,
    onChange,
    value: defaultValue,
  };

  const context = {
    ...initialContext,
    value,
  };

  const [state, send] = useMachine(radioGroup.machine(initialContext), {
    context,
  });

  return [state, send];
}
