import type { BaseStyleProps } from "@daangn/sprout-style-utils";
import { useStyleProps } from "@daangn/sprout-style-utils";
import type { Ref } from "react";
import * as React from "react";

import { useTabsContext } from "./context";
import type { SeedTabProps } from "./Tab";

export interface SeedTabListProps extends BaseStyleProps {
  children:
    | React.ReactElement<SeedTabProps>
    | React.ReactElement<SeedTabProps>[];
}

const TabList = (props: SeedTabListProps, ref: Ref<HTMLDivElement>) => {
  const { children, ...otherProps } = props;
  const { styleProps } = useStyleProps(otherProps);
  const { api } = useTabsContext();
  const { tabListProps, indicatorProps, getCurrentTabLeftOffset } = api;

  const containerRef = React.useRef<HTMLDivElement>(null);
  React.useImperativeHandle(ref, () => containerRef.current as HTMLDivElement);
  const currentTabLeftOffset = getCurrentTabLeftOffset();
  const memoizedCurrentTabLeftOffset = React.useMemo(
    () => currentTabLeftOffset,
    [currentTabLeftOffset],
  );

  React.useEffect(() => {
    containerRef.current?.scrollTo({
      // NOTE: 27px is half of tab's min-width
      left: memoizedCurrentTabLeftOffset - 27,
      behavior: "smooth",
    });
  }, [memoizedCurrentTabLeftOffset]);

  return (
    <div ref={containerRef} {...styleProps} {...tabListProps}>
      {children}
      <div {...indicatorProps} />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _TabList = React.forwardRef(TabList);
export { _TabList as TabList };
