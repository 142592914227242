import "@daangn/sprout-css/spinner/index.css";

import { useProgressBar } from "@daangn/sprout-hooks-progress";
import type { BaseStyleProps } from "@daangn/sprout-style-utils";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import type { Ref } from "react";
import React from "react";

export interface SeedSpinnerProps extends BaseStyleProps {
  /**
   * The size of the spinner.
   * @default medium
   */
  size?: "medium" | "small";

  /**
   * The color variant of the spinner.
   * @default gray
   */
  variant?: "gray" | "white" | "primary";

  /**
   * Defines a string value that labels the current element.
   */
  "aria-label"?: string;
  /**
   * Identifies the element (or elements) that labels the current element.
   */
  "aria-labelledby"?: string;
}

const Spinner = (props: SeedSpinnerProps, ref: Ref<HTMLDivElement>) => {
  const { size = "medium", variant = "gray", ...otherProps } = props;
  const { progressBarProps } = useProgressBar({
    ...otherProps,
    isIndeterminate: true,
  });
  const { styleProps } = useStyleProps({
    ...otherProps,
  });

  return (
    <div
      ref={ref}
      {...styleProps}
      {...progressBarProps}
      data-size={size}
      data-variant={variant}
      className={composeClassName("seed-spinner", styleProps.className)}
    >
      <svg
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-part="track"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 5.12821C11.7865 5.12821 5.12821 11.7865 5.12821 20C5.12821 28.2135 11.7865 34.8718 20 34.8718C28.2135 34.8718 34.8718 28.2135 34.8718 20C34.8718 11.7865 28.2135 5.12821 20 5.12821ZM0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
          data-part="track-path"
        />
      </svg>

      <svg
        viewBox="20 20 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-part="indicator"
      >
        <circle
          cx="40"
          cy="40"
          r="17.5"
          fill="none"
          data-part="indicator-path"
        />
      </svg>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _Spinner = React.forwardRef(Spinner);
export { _Spinner as Spinner };
