import { useContext } from "react";
import TransferIdentificationContext, {
  type TransferIdentificationContextValue,
} from "../contexts/transfer-identification-context";

export default function useTransferIdentification(): TransferIdentificationContextValue {
  const transferIdentification = useContext(TransferIdentificationContext);

  if (transferIdentification === null) {
    throw new Error(
      "useTransferIdentification must be used within a TransferIdentificationProvider",
    );
  }

  return transferIdentification;
}
