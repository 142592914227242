import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import { classNames } from "@seed-design/design-token";
import * as React from "react";

import { useAlertDialogProps } from "./context";
import type {
  SeedDialogDescriptionProps,
  SeedDialogHeaderProps,
  SeedDialogProps,
  SeedDialogTitleProps,
} from "./types";

const typography = classNames.$semantic.typography;

export const Dialog = React.forwardRef(
  (props: SeedDialogProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { api } = useAlertDialogProps();
    const { contentProps } = api;
    const { styleProps } = useStyleProps(props);

    return (
      <div ref={ref} {...styleProps} {...contentProps}>
        {props.children}
      </div>
    );
  },
);

export const DialogHeader = React.forwardRef(
  (props: SeedDialogHeaderProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { children } = props;
    const { styleProps } = useStyleProps(props);

    return (
      <div ref={ref} data-part="text-container" {...styleProps}>
        {children}
      </div>
    );
  },
);

export const DialogTitle = React.forwardRef(
  (
    props: SeedDialogTitleProps,
    ref: React.ForwardedRef<HTMLHeadingElement>,
  ) => {
    const { children } = props;
    const { api } = useAlertDialogProps();
    const { titleProps } = api;
    const { styleProps } = useStyleProps(props);

    return (
      <h2
        ref={ref}
        {...titleProps}
        {...styleProps}
        className={composeClassName(
          typography.title3Bold,
          styleProps.className,
        )}
      >
        {children}
      </h2>
    );
  },
);

export const DialogDescription = React.forwardRef(
  (
    props: SeedDialogDescriptionProps,
    ref: React.ForwardedRef<HTMLParagraphElement>,
  ) => {
    const { children } = props;
    const { api } = useAlertDialogProps();
    const { descriptionProps } = api;
    const { styleProps } = useStyleProps(props);

    return (
      <p
        ref={ref}
        {...descriptionProps}
        {...styleProps}
        className={composeClassName(
          typography.subtitle1Regular,
          styleProps.className,
        )}
      >
        {children}
      </p>
    );
  },
);
