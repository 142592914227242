import * as dialog from "@zag-js/dialog";
import { normalizeProps, useMachine } from "@zag-js/react";
import { useEffect, useId } from "react";

export interface DialogProps {
  /**
   * The dialog's role
   * @default "dialog"
   */
  role?: "dialog" | "alertdialog";

  /**
   * Whether to close the dialog when the outside is clicked
   * @default false
   */
  closeOnOutsideClick?: boolean;

  /**
   * Whether to close the dialog when the escape key is pressed
   * @default false
   */
  closeOnEsc?: boolean;

  /**
   * Whether to open or close the dialog (controlled)
   */
  isOpen?: boolean;

  /**
   * Whether to open or close the dialog on setup (uncontrolled)
   * @default false
   */
  defaultOpen?: boolean;

  /**
   * Whether to trap focus inside the dialog when it's opened
   */
  trapFocus?: boolean;
  /**
   * Whether to prevent scrolling behind the dialog when it's opened
   */
  preventScroll?: boolean;
  /**
   * Whether to prevent pointer interaction outside the element and hide all content below it
   */
  modal?: boolean;
  /**
   * Element to receive focus when the dialog is opened
   */
  initialFocusEl?: dialog.Context["initialFocusEl"];
  /**
   * Element to receive focus when the dialog is closed
   */
  finalFocusEl?: dialog.Context["finalFocusEl"];
  /**
   * Whether to restore focus to the element that had focus before the dialog was opened
   */
  restoreFocus?: boolean;

  /**
   * Callback to be invoked when the escape key is pressed
   */
  onEsc?: () => void;

  /**
   * Callback to be invoked when the `isOpen` changed
   */
  onOpenChange?: (isOpen: boolean) => void;

  /**
   * Callback to be invoked when the outside is clicked
   */
  onOutsideClick?: () => void;
}

export function useDialog(props: DialogProps): dialog.Api {
  const {
    role = "dialog",
    isOpen,
    defaultOpen,
    closeOnEsc,
    closeOnOutsideClick,
    preventScroll,
    trapFocus,
    finalFocusEl,
    initialFocusEl,
    modal,
    restoreFocus,
    onEsc,
    onOpenChange,
    onOutsideClick,
  } = props;

  const id = useId();

  const initialContext: dialog.Context = {
    id,
    role,
    open: isOpen ?? defaultOpen,
    closeOnEscapeKeyDown: closeOnEsc,
    closeOnInteractOutside: closeOnOutsideClick,
    preventScroll,
    trapFocus,
    finalFocusEl,
    initialFocusEl,
    modal,
    restoreFocus,
    onEscapeKeyDown: onEsc,
    onPointerDownOutside: onOutsideClick,
  };

  const context = {
    ...initialContext,
    open: isOpen,
  };

  const [state, send] = useMachine(dialog.machine(initialContext), { context });
  const api = dialog.connect(state, send, normalizeProps);

  useEffect(() => {
    onOpenChange?.(api.isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api.isOpen]);

  useEffect(() => {
    if (isOpen) {
      api.open();
    } else {
      api.close();
    }
  }, [isOpen]);

  return api;
}

export type DialogApi = ReturnType<typeof dialog.connect>;
