import * as snackbar from "@daangn/sprout-machines-snackbar";
import { useMachine } from "@zag-js/react";
import { useId } from "react";

import type { SnackbarProps } from "./types";

export function useSnackbarMachine(
  props: SnackbarProps,
): snackbar.StateMachine {
  const id = useId();

  return useMachine(
    snackbar.machine({
      id,
    }),
    {
      context: props,
    },
  );
}
