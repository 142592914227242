import { ariaAttr, dataAttr } from "@zag-js/dom-query";
import type { NormalizeProps, PropTypes } from "@zag-js/types";

import { parts } from "./button.anatomy";
import type { MachineApi, Send, State } from "./button.types";

export function connect<T extends PropTypes>(
  state: State,
  send: Send,
  normalize: NormalizeProps<T>,
): MachineApi<T> {
  const isPressed = state.matches("pressed:in");
  const isDisabled = state.context.disabled ?? false;
  const isHovered = state.context.hovered;
  const isFocused = state.context.focused;

  const ariaLabel = state.context["aria-label"];
  const ariaLabelledBy = state.context["aria-labelledby"];
  const ariaDescribedBy = state.context["aria-describedby"];
  const ariaDetails = state.context["aria-details"];

  const elementType = state.context.elementType || "button";

  const tabIndex = () => {
    if (elementType === "button") return undefined;
    if (isDisabled) return undefined;
    return 0;
  };
  const role = () => {
    if (elementType === "button") return undefined;
    return "button";
  };

  const buttonProps =
    elementType === "button"
      ? {
          type: state.context.type,
          disabled: isDisabled,
          value: state.context.value,
          name: state.context.name,
          form: state.context.form,
          formAction: state.context.formAction,
          formMethod: state.context.formMethod,
        }
      : {};
  const linkProps =
    elementType === "a"
      ? {
          download: state.context.download,
          href: state.context.href,
          target: state.context.target,
          rel: state.context.rel,
        }
      : {};
  const inputProps =
    elementType === "input"
      ? {
          type: state.context.type ?? "button",
          disabled: isDisabled,
          "aria-disabled": ariaAttr(isDisabled),
          value: state.context.value,
          name: state.context.name,
          form: state.context.form,
          formAction: state.context.formAction,
          formMethod: state.context.formMethod,
        }
      : {};

  return {
    isDisabled,
    buttonProps: normalize.element({
      ...parts.root.attrs,
      id: state.context.id,
      tabIndex: tabIndex(),
      role: role(),
      "data-disabled": dataAttr(isDisabled),
      "data-focus": dataAttr(isFocused),
      "data-hover": dataAttr(isHovered),
      "data-pressed": dataAttr(isPressed),
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledBy,
      "aria-describedby": ariaDescribedBy,
      "aria-details": ariaDetails,
      onPointerMove(event) {
        if (isDisabled) return;
        if (event.pointerType === "mouse") {
          send({ type: "POINTER_MOVE" });
        }
        send({ type: "SET_HOVERED", value: true });
      },
      onPointerLeave() {
        if (isDisabled) return;
        send({ type: "SET_HOVERED", value: false });
      },
      onPointerDown(event) {
        if (isDisabled) return;
        send({ type: "POINTER_DOWN", event });
      },
      onBlur() {
        send({ type: "SET_FOCUSED", value: false });
      },
      onFocus() {
        send({ type: "SET_FOCUSED", value: true });
      },
      onKeyDown(event) {
        // TODO: zag.js pressable 참고해 shouldPreventDefaultKeyboard 적용할 것
        if (event.key === " " || event.key === "Enter") {
          send({ type: "KEY_DOWN", event });
        }
      },
      onKeyUp(event) {
        if (event.key === " " || event.key === "Enter") {
          send({ type: "KEY_UP", event });
        }
      },
      ...buttonProps,
      ...inputProps,
      ...linkProps,
    }),
  };
}
