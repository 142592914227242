import "@daangn/sprout-css/slider/index.css";

import { useSafeLayoutEffect } from "@daangn/sprout-react-utils";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import {
  Slider as SliderRoot,
  SliderRange,
  SliderThumb,
  SliderTrack,
} from "@radix-ui/react-slider";
import { useSize } from "@radix-ui/react-use-size";
import type { Ref } from "react";
import React from "react";

import { SliderMarker } from "./SliderMarker";
import { controlStyle, rangeStyle, thumbStyle, trackStyle } from "./style";
import type { SeedSliderProps } from "./types";
import {
  type SharedCtx,
  getMarkerGroupProps,
  getTickProps,
  shouldRenderTick,
} from "./util";

const Slider = (props: SeedSliderProps, ref: Ref<HTMLDivElement>) => {
  const {
    markers,
    value,
    defaultValue = 0,
    minValue = 0,
    maxValue = 100,
    orientation = "horizontal",
    dir = "ltr",
    step = 1,
    onChange,
    onChangeEnd,
    isDisabled = false,
    ...otherProps
  } = props;
  const { styleProps } = useStyleProps(otherProps);
  const [thumb, setThumb] = React.useState<HTMLSpanElement | null>(null);
  const thumbRef = React.useRef<HTMLSpanElement | null>(null);
  const thumbSize = useSize(thumb);

  const sharedCtx: SharedCtx = {
    minValue,
    maxValue,
    orientation,
    dir,
    isDisabled,
    thumbSize,
  };

  const dataProps = {
    "data-orientation": orientation,
  };

  useSafeLayoutEffect(() => {
    if (thumbRef.current) {
      setThumb(thumbRef.current);
    }
  }, []);

  return (
    <SliderRoot
      ref={ref}
      data-part="root"
      value={value == null ? undefined : [value]}
      defaultValue={defaultValue == null ? undefined : [defaultValue]}
      min={minValue}
      max={maxValue}
      orientation={orientation}
      dir={dir}
      step={step}
      onValueChange={(v) => onChange?.(v[0])}
      onValueCommit={(v) => onChangeEnd?.(v[0])}
      disabled={isDisabled}
      {...styleProps}
      {...dataProps}
      className={composeClassName("seed-slider", styleProps.className)}
    >
      <div data-part="control" style={controlStyle} {...dataProps}>
        <SliderTrack data-part="track" style={trackStyle} {...dataProps}>
          <SliderRange data-part="range" style={rangeStyle} {...dataProps} />
          {markers &&
            markers
              .filter((marker) => shouldRenderTick({ ctx: sharedCtx, marker }))
              .map((marker) => (
                <span
                  key={marker.value}
                  {...getTickProps(sharedCtx, marker.value)}
                ></span>
              ))}
        </SliderTrack>
        <SliderThumb
          ref={thumbRef}
          data-part="thumb"
          style={thumbStyle}
          {...dataProps}
        />
      </div>
      {markers && (
        <div {...getMarkerGroupProps(sharedCtx)}>
          {markers.map((marker) => (
            <SliderMarker key={marker.value} ctx={sharedCtx} marker={marker} />
          ))}
        </div>
      )}
    </SliderRoot>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _Slider = React.forwardRef(Slider);
export { _Slider as Slider };
