import type { Plugin } from "plantae";
import { v4 as uuidv4 } from "uuid";

export default function requestIdPlugin(): Plugin {
  return {
    name: "plugin-request-id",
    hooks: {
      beforeRequest: async (req) => {
        if (req.headers.get("X-Request-Id") === null) {
          req.headers.set("X-Request-Id", uuidv4());
        }

        return req;
      },
    },
  };
}
