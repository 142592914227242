import type { HTMLAttributes } from "react";

export interface SeparatorProps {
  /**
   * @default 'horizontal'
   */
  orientation?: "horizontal" | "vertical";

  /** The HTML element type that will be used to render the separator. */
  elementType?: "div" | "hr";
}

export function useSeparator(props: SeparatorProps): {
  separatorProps: HTMLAttributes<HTMLElement>;
} {
  const { orientation, elementType } = props;

  // hr elements implicitly have role = separator and a horizontal orientation
  if (elementType !== "hr") {
    return {
      separatorProps: {
        role: "separator",
        "aria-orientation": orientation === "vertical" ? "vertical" : undefined,
      },
    };
  }
  return { separatorProps: {} };
}
