import * as button from "@daangn/sprout-machines-button";
import * as toggle from "@daangn/sprout-machines-toggle";
import { useEvent } from "@daangn/sprout-react-utils";
import { mergeProps, normalizeProps, useMachine } from "@zag-js/react";
import { useEffect, useId } from "react";

import type { ButtonElementType, ToggleButtonProps } from "./types";

export function useToggleButton<T extends ButtonElementType>(
  props: ToggleButtonProps<T>,
): toggle.Api & { buttonProps: button.Api["buttonProps"] } {
  const {
    isDisabled: disabled = false,
    isSelected: selected,
    defaultSelected = false,
    elementType = "button" as const,
    type = "button",
    onChange,
    onClick: onClickProp,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    "aria-describedby": ariaDescribedBy,
    "aria-details": ariaDetails,
  } = props;

  const id = useId();
  const rootId = `toggle-button:${id}`;

  const toggleContext = {
    defaultSelected,
    disabled,
    onChange: useEvent(onChange),
  };

  const [toggleState, toggleSend] = useMachine(
    toggle.machine({
      id,
      ids: {
        root: rootId,
      },
      ...toggleContext,
    }),
    {
      context: toggleContext,
    },
  );

  const toggleApi = toggle.connect(toggleState, toggleSend, normalizeProps);

  const buttonContext = {
    disabled,
    elementType,
    type,
    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy,
    ariaDetails,
  };

  const [buttonState, buttonSend] = useMachine(
    button.machine({
      id,
      ids: {
        root: rootId,
      },
      ...buttonContext,
    }),
    {
      context: buttonContext,
    },
  );

  const buttonApi = button.connect(buttonState, buttonSend, normalizeProps);

  const { isSelected } = toggleApi;
  useEffect(() => {
    if (selected !== undefined && selected !== isSelected) {
      toggleApi.setSelected(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, isSelected]);

  return {
    ...toggleApi,
    buttonProps: mergeProps(buttonApi.buttonProps, toggleApi.toggleProps, {
      onClick: (e) => {
        onClickProp?.(e);
      },
    }),
  };
}
