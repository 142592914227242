import type { SeedSliderMarker } from "./types";

export interface SharedCtx {
  minValue: number;
  maxValue: number;
  dir: "ltr" | "rtl";
  orientation: "horizontal" | "vertical";
  isDisabled: boolean;
  thumbSize: { width: number; height: number } | undefined;
}

// https://github.com/tmcw-up-for-adoption/simple-linear-scale/blob/master/index.js
function linearScale(
  input: readonly [number, number],
  output: readonly [number, number],
) {
  return (value: number) => {
    if (input[0] === input[1] || output[0] === output[1]) return output[0];
    const ratio = (output[1] - output[0]) / (input[1] - input[0]);
    return output[0] + ratio * (value - input[0]);
  };
}

function getThumbOrientationSize(
  ctx: Pick<SharedCtx, "thumbSize" | "orientation">,
) {
  if (!ctx.thumbSize) return 0;

  return ctx.orientation === "horizontal"
    ? ctx.thumbSize?.width
    : ctx.thumbSize?.height;
}

export function getValuePercent({
  ctx,
  value,
}: {
  ctx: Pick<SharedCtx, "minValue" | "maxValue" | "dir" | "orientation">;
  value: number;
}) {
  const { minValue, maxValue, dir, orientation } = ctx;
  const isRtl = dir === "rtl";
  const isVertical = orientation === "vertical";

  if (isRtl && !isVertical) {
    return ((maxValue - value) / (maxValue - minValue)) * 100;
  }

  return ((value - minValue) / (maxValue - minValue)) * 100;
}

/**
 * Offsets the thumb centre point while sliding to ensure it remains
 * within the bounds of the slider when reaching the edges
 */
function getThumbInBoundsOffset({
  ctx,
  value,
}: {
  ctx: Pick<
    SharedCtx,
    "dir" | "minValue" | "maxValue" | "thumbSize" | "orientation"
  >;
  value: number;
}) {
  const { minValue, maxValue, orientation } = ctx;
  if (value === minValue || value === maxValue) return 0;

  const thumbSize = getThumbOrientationSize(ctx);
  const isRtl = ctx.dir === "rtl";
  const isVertical = orientation === "vertical";

  if (isRtl && !isVertical) {
    const offset = linearScale(
      [maxValue, minValue],
      [-thumbSize / 2, thumbSize / 2],
    );

    return offset(value);
  }

  const offset = linearScale(
    [minValue, maxValue],
    [-thumbSize / 2, thumbSize / 2],
  );

  return offset(value);
}

export function shouldRenderTick({
  ctx,
  marker,
}: {
  ctx: Pick<SharedCtx, "minValue" | "maxValue" | "dir" | "orientation">;
  marker: SeedSliderMarker;
}) {
  const { value } = marker;
  const percent = getValuePercent({ ctx, value });
  return percent > 0 || percent < 100;
}

export function getTickProps(
  ctx: Pick<
    SharedCtx,
    "minValue" | "maxValue" | "dir" | "orientation" | "thumbSize"
  >,
  value: number,
) {
  const { orientation } = ctx;
  const percent = getValuePercent({ ctx, value });
  const position = orientation === "horizontal" ? "left" : "bottom";
  const thumbInBoundsOffset = getThumbInBoundsOffset({ ctx, value });

  return {
    "data-part": "tick",
    style: {
      [position]: `calc(${percent}% - ${thumbInBoundsOffset}px)`,
    },
  };
}

function getMarkerStyle(ctx: SharedCtx, value: number) {
  const percent = getValuePercent({ ctx, value });
  const isHorizontal = ctx.orientation === "horizontal";
  const thumbInBoundsOffset = getThumbInBoundsOffset({ ctx, value });

  return {
    position: "absolute",
    pointerEvents: "none",
    [isHorizontal
      ? "left"
      : "bottom"]: `calc(${percent}% - ${thumbInBoundsOffset}px)`,
  } as React.CSSProperties;
}

export function getMarkerGroupProps(ctx: SharedCtx) {
  return {
    "data-part": "marker-group",
    dir: ctx.dir,
    role: "presentation",
    "aria-hidden": true,
    "data-orientation": ctx.orientation,
    style: {
      userSelect: "none",
      pointerEvents: "none",
      position: "relative",
    } as React.CSSProperties,
  };
}

export function getMarkerProps(ctx: SharedCtx, value: number) {
  const style = getMarkerStyle(ctx, value);

  return {
    "data-part": "marker",
    dir: ctx.dir,
    role: "presentation",
    "data-orientation": ctx.orientation,
    "data-value": value,
    "data-disabled": ctx.isDisabled ? "" : undefined,
    style,
  };
}
