export default function fulfillVersion(
  version: string,
  minimumVersion: string,
) {
  const [major, minor, patch] = version.split(".").map(Number);
  const [minMajor, minMinor, minPatch] = minimumVersion.split(".").map(Number);

  if (major > minMajor) return true;
  if (major === minMajor && minor > minMinor) return true;
  if (major === minMajor && minor === minMinor && patch >= minPatch)
    return true;

  return false;
}
