import * as React from "react";

export const ErrorMessageIcon = () => (
  <svg
    aria-hidden
    data-part="error-message-icon"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00003 1.83325C4.31821 1.83325 1.3335 4.81796 1.3335 8.49979C1.3335 12.1816 4.31821 15.1663 8.00003 15.1663C11.6819 15.1663 14.6666 12.1816 14.6666 8.49979C14.6666 4.81796 11.6819 1.83325 8.00003 1.83325ZM8.5445 5.77885C8.5445 5.47829 8.30085 5.23464 8.00029 5.23464C7.69974 5.23464 7.45609 5.47829 7.45609 5.77885V8.84002C7.45609 9.14057 7.69974 9.38422 8.00029 9.38422C8.30085 9.38422 8.5445 9.14057 8.5445 8.84002V5.77885ZM8.00016 11.9012C8.37586 11.9012 8.68042 11.5966 8.68042 11.2209C8.68042 10.8452 8.37586 10.5407 8.00016 10.5407C7.62446 10.5407 7.3199 10.8452 7.3199 11.2209C7.3199 11.5966 7.62446 11.9012 8.00016 11.9012Z"
      fill="currentcolor"
    />
  </svg>
);
