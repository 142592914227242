import "@daangn/sprout-css/button/index.css";

//@ts-ignore - yarn workspace exports map is not working correctly
import { InternalSpinner } from "@daangn/sprout-components-spinner/internal";
import type { ButtonElementType } from "@daangn/sprout-hooks-button";
import { useToggleButton } from "@daangn/sprout-hooks-button";
import { useFocusVisible } from "@daangn/sprout-hooks-interactions";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import { classNames } from "@seed-design/design-token";
import type { ElementType, Ref } from "react";
import * as React from "react";
import { match } from "ts-pattern";

import { spinnerVariant } from "./spinner";
import type { SeedBoxToggleButtonProps } from "./types";

const getTypograpyClassName = (
  size: Required<SeedBoxToggleButtonProps>["size"],
) =>
  match(size)
    .with("large", () => classNames.$semantic.typography.label3Bold)
    .with("medium", () => classNames.$semantic.typography.label3Bold)
    .with("small", () => classNames.$semantic.typography.label3Bold)
    .with("xsmall", () => classNames.$semantic.typography.label4Bold)
    .exhaustive();

const getToggleVariant = (
  variant: Required<SeedBoxToggleButtonProps>["variant"],
  isSelected: boolean,
) =>
  match(variant)
    .with("primary", () => (isSelected ? "secondary" : "primary"))
    .with("primaryLow", () => (isSelected ? "secondary" : "primaryLow"))
    .with("secondary", () => (isSelected ? "primaryLow" : "secondary"))
    .exhaustive();

const getSpinnerSize = (size: Required<SeedBoxToggleButtonProps>["size"]) =>
  match(size)
    .with("large", () => 14)
    .with("medium", () => 14)
    .with("small", () => 14)
    .with("xsmall", () => 12)
    .exhaustive();

const getSpinnerVariant = (
  variant: Required<SeedBoxToggleButtonProps>["variant"],
  isSelected: boolean,
) =>
  match(variant)
    .with("primary", () =>
      isSelected ? spinnerVariant.black : spinnerVariant.white,
    )
    .with("primaryLow", () =>
      isSelected ? spinnerVariant.black : spinnerVariant.primary,
    )
    .with("secondary", () =>
      isSelected ? spinnerVariant.primary : spinnerVariant.black,
    )
    .exhaustive();

const BoxToggleButton = <T extends ButtonElementType>(
  props: SeedBoxToggleButtonProps<T>,
  ref: Ref<HTMLElement>,
) => {
  const {
    elementType: Element = "button" as ElementType,
    size = "medium",
    variant = "primary",
    prefix,
    isLoading,
    suffix,
    children,
    ...otherProps
  } = props;
  const { buttonProps, isSelected } = useToggleButton(props);
  const { focusVisibleProps } = useFocusVisible();
  const { styleProps } = useStyleProps(otherProps);

  return (
    <Element
      ref={ref}
      {...styleProps}
      {...focusVisibleProps}
      {...buttonProps}
      data-size={size}
      data-variant={getToggleVariant(variant, isSelected)}
      className={composeClassName(
        "seed-box-button",
        getTypograpyClassName(size),
        styleProps.className,
      )}
    >
      {prefix && <span data-part="prefix">{prefix}</span>}
      {isLoading ? (
        <InternalSpinner
          size={getSpinnerSize(size)}
          {...getSpinnerVariant(variant, isSelected)}
        />
      ) : (
        <span className={getTypograpyClassName(size)}>{children}</span>
      )}
      {suffix && <span data-part="suffix">{suffix}</span>}
    </Element>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _BoxToggleButton = React.forwardRef(BoxToggleButton);
export { _BoxToggleButton as BoxToggleButton };
