import { classNames } from "@seed-design/design-token";
import React from "react";

export const CalloutDescription = ({
  children,
}: React.PropsWithChildren<{}>) => {
  return (
    <span
      className={classNames.$semantic.typography.bodyM2Regular}
      data-part="description"
    >
      {children}
    </span>
  );
};
