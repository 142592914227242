import type { Bridge } from "types";

export const EVENT_NAMES = {
  clientShownIdentificationBottomSheet:
    "client_shown_identification_bottomSheet_v1",
  shownIdentificationPayPolicyAgreementBottomSheet:
    "client_shown_identification_pay_policyAgreement_bottomSheet_v1",
  clickedIdentificationPayPolicyAgreementBottomSheetButton:
    "client_clicked_identification_pay_policyAgreement_bottomSheet_button_v1",
} as const;
type EventNames = (typeof EVENT_NAMES)[keyof typeof EVENT_NAMES];

export const logEvent = (
  bridge: Bridge,
  name: EventNames,
  params: object = {},
) => {
  return Promise.all([
    bridge.logEvent({
      analytics: {
        target: "KARROT",
        name,
        params: JSON.stringify(params),
      },
    }),
  ]);
};
