import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const IconHeartRegular = (
  {
    size = 24,
    ...props
  }: SVGProps<SVGSVGElement> & {
    size?: number | string,
  },
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-seed-icon="true"
    data-seed-icon-version="0.6.1"
    width={size}
    height={size}
    ref={ref}
    {...props}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.79726 4.80739C5.11177 2.39754 8.90702 2.39754 11.2215 4.80739L11.2224 4.80827L11.9872 5.60702L12.7529 4.80739C15.0674 2.39754 18.8626 2.39754 21.1772 4.80739L21.179 4.80935C23.5242 7.26774 23.1318 10.953 21.2363 13.4384L21.2351 13.44C19.3267 15.9292 17.1219 17.9359 15.4019 19.3169C14.5402 20.0088 13.796 20.547 13.2653 20.9135C12.9998 21.0968 12.7875 21.2373 12.6402 21.3328C12.5666 21.3805 12.5092 21.417 12.4695 21.442L12.4235 21.4708L12.4109 21.4786L12.4072 21.4809L12.406 21.4816C12.406 21.4816 12.4053 21.4821 11.9872 20.8C11.5813 21.4894 11.5809 21.4892 11.5809 21.4892L11.5792 21.4882L11.5754 21.4859L11.5621 21.478L11.5142 21.4491C11.4729 21.424 11.4132 21.3875 11.3368 21.3396C11.184 21.244 10.964 21.1033 10.6902 20.9197C10.1426 20.5527 9.37805 20.0137 8.50199 19.3205C6.75443 17.9378 4.54128 15.9246 2.72667 13.423L2.72566 13.4216C0.930009 10.935 0.438148 7.26368 2.79726 4.80739ZM11.9872 20.8L11.5809 21.4892C11.8358 21.6393 12.1538 21.6362 12.406 21.4816L11.9872 20.8ZM11.9794 19.8524C12.0866 19.7811 12.2129 19.6958 12.3562 19.5968C12.8601 19.2489 13.5728 18.7336 14.4002 18.0693C16.058 16.7382 18.1594 14.8219 19.9646 12.4675C21.5249 10.421 21.6753 7.64872 20.0223 5.91478C18.3375 4.16154 15.5917 4.16174 13.9071 5.91539L12.565 7.31704C12.4141 7.47464 12.2054 7.56376 11.9872 7.56376C11.769 7.56376 11.5603 7.47464 11.4094 7.31704L10.0676 5.91571C8.38268 4.16167 5.63602 4.16151 3.95122 5.91571C2.31111 7.62338 2.50465 10.382 4.02235 12.4843C5.72139 14.8264 7.81446 16.7363 9.49477 18.0658C10.3327 18.7288 11.0626 19.2432 11.5809 19.5906C11.7329 19.6925 11.8666 19.7799 11.9794 19.8524Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(IconHeartRegular);
export default ForwardRef;
