/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export type RouterOpenPayload =
  | {
      /**
       * 리모트 웹 주소
       */
      remote: string;
      /**
       * 상단 바 표시 여부
       */
      navbar?: boolean;
      /**
       * 상단 바 내 타이틀
       */
      navbarTitle?: string;
      /**
       * 스크롤 가능 여부
       */
      scrollable?: boolean;
      /**
       * 백스와이프 가능 여부 (iOS Only)
       */
      backSwipable?: boolean;
      /**
       * Safe Area까지 꽉 채울지 여부 (iOS Only)
       */
      enableSafeAreaInsets?: boolean;
      /**
       * Safe Area까지 꽉 채울지 여부 (iOS Only)
       */
      IOS_ONLY_enableSafeAreaInsets?: boolean;
      /**
       * Keyboard Accessory View를 숨길지 여부 (iOS Only)
       */
      IOS_ONLY_hideKeyboardAccessoryView?: boolean;
      /**
       * 백스와이프 가능 여부 (iOS Only) (a.k.a backSwipable)
       */
      IOS_ONLY_enableRootSwipeBack?: boolean;
      /**
       * 웹뷰에서 백스와이프를 통한 히스토리 조작 기능을 켤지 여부 (iOS Only)
       */
      IOS_ONLY_allowsBackForwardNavigationGestures?: boolean;
      /**
       * 최상단과 최하단의 바운스 이펙트를 줄지 여부 (iOS Only)
       */
      IOS_ONLY_bounces?: boolean;
      /**
       * 다크모드여도 서드파티 웹뷰 페이지에서는 라이트모드로 보이도록 해요
       */
      forceDarkModeOff?: boolean;
      [k: string]: unknown;
    }
  | {
      /**
       * 로컬 웹뷰 주소 (없을 경우 자기 자신)
       */
      app: string;
      /**
       * 로컬 웹뷰 내 경로 (HashRouter)
       */
      path: string;
      /**
       * 상단 바 표시 여부
       */
      navbar?: boolean;
      /**
       * 상단 바 내 타이틀
       */
      navbarTitle?: string;
      /**
       * 스크롤 가능 여부
       */
      scrollable?: boolean;
      /**
       * 백스와이프 가능 여부 (iOS Only)
       */
      backSwipable?: boolean;
      /**
       * Safe Area까지 꽉 채울지 여부 (iOS Only)
       */
      enableSafeAreaInsets?: boolean;
      /**
       * Safe Area까지 꽉 채울지 여부 (iOS Only)
       */
      IOS_ONLY_enableSafeAreaInsets?: boolean;
      /**
       * Keyboard Accessory View를 숨길지 여부 (iOS Only)
       */
      IOS_ONLY_hideKeyboardAccessoryView?: boolean;
      /**
       * 백스와이프 가능 여부 (iOS Only) (a.k.a backSwipable)
       */
      IOS_ONLY_enableRootSwipeBack?: boolean;
      /**
       * 웹뷰에서 백스와이프를 통한 히스토리 조작 기능을 켤지 여부 (iOS Only)
       */
      IOS_ONLY_allowsBackForwardNavigationGestures?: boolean;
      /**
       * 최상단과 최하단의 바운스 이펙트를 줄지 여부 (iOS Only)
       */
      IOS_ONLY_bounces?: boolean;
      /**
       * 다크모드여도 서드파티 웹뷰 페이지에서는 라이트모드로 보이도록 해요
       */
      forceDarkModeOff?: boolean;
      [k: string]: unknown;
    };
export type Tab = "HOME" | "NEARBY";

export interface KarrotBridgeSchema {
  ReqAuthGetAccessTokenRequestBody: Empty;
  ReqAuthGetAccessTokenResponse: {
    /**
     * 액세스 토큰
     */
    accessToken: string;
  };
  ReqAuthRefreshAccessTokenRequestBody: {
    /**
     * 액세스 토큰
     */
    accessToken: string;
  };
  ReqAuthRefreshAccessTokenResponse: {
    /**
     * 액세스 토큰
     */
    accessToken: string;
  };
  ReqHapticSuccessRequestBody: {};
  ReqHapticSuccessResponse: Empty;
  ReqHapticErrorRequestBody: {};
  ReqHapticErrorResponse: Empty;
  ReqHapticSelectRequestBody: Empty;
  ReqHapticSelectResponse: Empty;
  ReqHapticLightFeedbackRequestBody: Empty;
  ReqHapticLightFeedbackResponse: Empty;
  ReqAnalyticsLogEventRequestBody: {
    analytics: {
      /**
       * 어떤 시스템으로 이벤트를 보낼지
       */
      target: "FIREBASE" | "KARROT" | "APPSFLYER" | "BRAZE";
      /**
       * 이벤트 이름
       */
      name: string;
      /**
       * (JSON) 파라미터
       */
      params: string;
    };
  };
  ReqAnalyticsLogEventResponse: Empty;
  ReqGeolocationCurrentPositionRequestBody: {
    /**
     * 위치 권한 거절 상태 일때 OS 설정화면으로 안내하는 팝업 노출 여부
     */
    systemSettingPopupEnabled?: boolean;
  };
  ReqGeolocationCurrentPositionResponse: {
    geolocation: {
      currentPosition?: {
        position: {
          latitude: number;
          longitude: number;
        };
        timestamp?: number;
      };
      geolocationAccessResponse?: {
        granted: boolean;
      };
    };
  };
  ReqImageConfigureRequestBody: {
    image: {
      maxImageCount: number;
      /**
       * 이미지 압축 사용 여부
       */
      disableCompress?: boolean;
      editImageConfig?: {
        canImageBeEdited: boolean;
        imageCropControlTargets?: (
          | "free"
          | "square"
          | "rectangle4_3"
          | "rectangle3_4"
        )[];
      };
    };
  };
  ReqImageConfigureResponse: Empty;
  ReqImagePickRequestBody: Empty;
  ReqImagePickResponse: {
    image: {
      /**
       * 선택된 이미지 목록
       */
      images: {
        uri: string;
      }[];
    };
  };
  ReqInfoAppRequestBody: Empty;
  ReqInfoAppResponse: {
    info: {
      /**
       * 앱 정보
       */
      app: {
        deviceIdentity: string;
        userAgent: string;
        locale: string;
        appHost: string;
        country: "KR" | "GB" | "CA" | "JP";
        appVersionCode: string;
        appVersion: string;
      };
    };
  };
  ReqInfoRegionRequestBody: Empty;
  ReqInfoRegionResponse: {
    info: {
      region: Region;
    };
  };
  ReqInfoUserRequestBody: Empty;
  ReqInfoUserResponse: {
    info: {
      /**
       * 유저 정보
       */
      user: {
        id: number;
        hashId: string;
        authToken: string;
        phone: string;
        email?: string;
        nickname: string;
        profileImage: string;
        temperature?: number;
        bio?: string;
        userExtend?: {
          region1?: Region;
          region2?: Region;
        };
        displayRegionCheckinsCount?: number;
      };
    };
  };
  ReqInfoAnalyticsSessionRequestBody: Empty;
  ReqInfoAnalyticsSessionResponse: {
    info: {
      /**
       * 앱 분석 세션 정보
       */
      karrotAnalyticsSession: {
        id: string;
      };
    };
  };
  ReqPluginOpenRequestBody: PluginOpenPayload;
  ReqPluginOpenResponse: Empty;
  ReqPluginCloseRequestBody: Empty;
  ReqPluginCloseResponse: Empty;
  ReqPluginStyleRequestBody: {
    plugin: {
      /**
       * 높이 (px) (deprecated)
       */
      height?: number;
      /**
       * 높이 (px) (0px 초과)
       */
      viewHeight?: number;
      /**
       * 높이 (0.1 ~ 1.0)
       */
      viewHeightRatio?: number;
      /**
       * 바깥 부분을 터치해 닫는 동작을 막습니다
       */
      disableClose: boolean;
    };
  };
  ReqPluginStyleResponse: Empty;
  ReqRegionChangeRequestBody: {
    region: {
      /**
       * 변경할 지역 ID
       */
      regionId: number;
    };
  };
  ReqRegionChangeResponse: Empty;
  ReqShareOpenRequestBody: {
    share: {
      url: string;
      text: string;
    };
  };
  ReqShareOpenResponse: Empty;
  ReqToastOpenRequestBody: {
    toast: {
      /**
       * 메시지 (토스트 / 스낵바 공통)
       */
      body: string;
      /**
       * 우측 액션 버튼 (스낵바 전용, 토스트에서는 액션 버튼을 사용할 수 없어요.)
       */
      button?: {
        /**
         * 액션 버튼의 레이블
         */
        body: string;
        action?:
          | {
              link: {
                /**
                 * 액션 버튼을 눌렀을 때 이동할 주소(스킴)
                 */
                path: string;
                [k: string]: unknown;
              };
            }
          | {
              /**
               * 스트림 이벤트를 받아 처리할 수 있다. !아직 네이티브에서 구현안됨!
               */
              stream: {
                /**
                 * 액션 버튼을 눌렀을 때 Stream에 발생시킬 이벤트 이름
                 */
                clickEventName: string;
                [k: string]: unknown;
              };
            };
      };
      /**
       * UI 타입 (Android 전용, iOS에서는 항상 스낵바를 사용해요.)
       */
      type?: "TOAST" | "SNACKBAR";
    };
  };
  ReqToastOpenResponse: Empty;
  ReqRouterPushRequestBody: {
    router: RouterOpenPayload;
  };
  ReqRouterPushResponse: Empty;
  ReqRouterOpenRequestBody: {
    router: RouterOpenPayload;
  };
  ReqRouterOpenResponse: Empty;
  ReqRouterReplaceRequestBody: {
    router: RouterOpenPayload;
  };
  ReqRouterReplaceResponse: Empty;
  ReqRouterPrefetchRequestBody: {
    router: {
      /**
       * 로컬 웹뷰 주소
       */
      app: string;
    };
  };
  ReqRouterPrefetchResponse: Empty;
  ReqRouterCloseRequestBody: Empty;
  ReqRouterCloseResponse: Empty;
  ReqRouterCloseAllRequestBody: {
    ANDROID_ONLY_nextTab: Tab;
  };
  ReqRouterCloseAllResponse: Empty;
  ReqRouterStyleRequestBody: {
    router: {
      /**
       * 상단 바 표시 여부
       */
      navbar?: boolean;
      /**
       * 상단 바 내 타이틀
       */
      navbarTitle?: string;
      /**
       * 스크롤 가능 여부
       */
      scrollable?: boolean;
      /**
       * 백스와이프 가능 여부 (iOS Only)
       */
      backSwipable?: boolean;
      /**
       * Safe Area까지 꽉 채울지 여부 (iOS Only)
       */
      enableSafeAreaInsets?: boolean;
      /**
       * Safe Area까지 꽉 채울지 여부 (iOS Only)
       */
      IOS_ONLY_enableSafeAreaInsets?: boolean;
      /**
       * Keyboard Accessory View를 숨길지 여부 (iOS Only)
       */
      IOS_ONLY_hideKeyboardAccessoryView?: boolean;
      /**
       * 백스와이프 가능 여부 (iOS Only) (a.k.a backSwipable)
       */
      IOS_ONLY_enableRootSwipeBack?: boolean;
      /**
       * 웹뷰에서 백스와이프를 통한 히스토리 조작 기능을 켤지 여부 (iOS Only)
       */
      IOS_ONLY_allowsBackForwardNavigationGestures?: boolean;
      /**
       * 최상단과 최하단의 바운스 이펙트를 줄지 여부 (iOS Only)
       */
      IOS_ONLY_bounces?: boolean;
      /**
       * 다크모드여도 서드파티 웹뷰 페이지에서는 라이트모드로 보이도록 해요
       */
      forceDarkModeOff?: boolean;
    };
  };
  ReqRouterStyleResponse: Empty;
  ReqStorageSetRequestBody: {
    storage: {
      /**
       * 저장할 데이터의 키 값
       */
      key: string;
      /**
       * 데이터 (JSON)
       */
      value: string;
    };
  };
  ReqStorageSetResponse: Empty;
  ReqStorageGetRequestBody: {
    storage: {
      /**
       * 조회할 데이터의 키 값
       */
      key: string;
    };
  };
  ReqStorageGetResponse: {
    storage: {
      /**
       * 데이터 (JSON)
       */
      value: string;
    };
  };
  ReqStorageGetAllRequestBody: Empty;
  ReqStorageGetAllResponse: {
    storage: {
      values: {
        key: string;
        value: string;
      }[];
    };
  };
  ReqStorageDeleteRequestBody: {
    storage: {
      /**
       * 조회할 데이터의 키 값
       */
      key: string;
    };
  };
  ReqStorageDeleteResponse: Empty;
  ReqStreamEmitRequestBody: {
    stream: {
      eventName: string;
      /**
       * (주의) 받는 쪽에서 JSON.parse()가 실행되므로 반드시 JSON.stringify()한 결과를 data 필드에 넣어서 보내주세요.
       */
      data?: string;
    };
  };
  ReqStreamEmitResponse: Empty;
  ReqCommunityRefreshFeedRequestBody: Empty;
  ReqCommunityRefreshFeedResponse: Empty;
  ReqNeighborCompleteAdsRequestBody: Empty;
  ReqNeighborCompleteAdsResponse: Empty;
  ReqBackButtonPressedConfigureRequestBody: {
    /**
     * 백버튼 활성화/비활성화 여부
     */
    ANDROID_ONLY_backButtonEnabled: boolean;
  };
  ReqBackButtonPressedConfigureResponse: Empty;
  ReqExperimentVariablesRequestBody: Empty;
  ReqExperimentVariablesResponse: {
    /**
     * 실험 변수 목록을 받아요.
     */
    experimentSubjects?: {
      /**
       * 실험의 subject type이에요
       */
      subjectType?: string;
      variable: {
        /**
         * 실험 변수의 키에요
         */
        key: string;
        /**
         * 해당 실험에 적용할 값이에요
         */
        value: string;
        /**
         * 실험의 value 타입을 결정해요. 실험플랫폼에서는 string, number, boolean을 plain text로 전달하기 때문에 이 값을 참고하여 value를 사용해요.
         */
        valueType:
          | "EXPERIMENT_VARIABLE_TYPE_UNSPECIFIED"
          | "EXPERIMENT_VARIABLE_TYPE_BOOLEAN"
          | "EXPERIMENT_VARIABLE_TYPE_STRING"
          | "EXPERIMENT_VARIABLE_TYPE_INTEGER";
        /**
         * 실험에 대한 메타데이터에요.
         */
        metadata?: {
          /**
           * 실험의 키값이에요
           */
          experimentKey: string;
          /**
           * 유저에게 할당된 실험 그룹 이름이에요. 'unassigned', 'control', 'treatmentN' 세가지 형태로 값을 받아요. treatmentN은 treatment1, treatment2, ... 형태를 의미해요.
           */
          assignedGroupName: string;
          /**
           * 실험 디버깅 등을 위해 강제로 특정 실험군에 배정했는지 여부를 의미해요
           */
          isAllowlisted: boolean;
        };
      };
    }[];
  };
  ReqExperimentTriggerEventRequestBody: {
    /**
     * 실험의 subject type이에요
     */
    subject_type: string;
    variable: {
      /**
       * 실험 변수의 키값이에요
       */
      key: string;
      /**
       * 해당 실험에 적용할 값이에요
       */
      value: string;
      /**
       * 실험의 value 타입을 결정해요. 실험플랫폼에서는 string, number, boolean을 plain text로 전달하기 때문에 이 값을 참고하여 value를 사용해요.
       */
      variable_type:
        | "EXPERIMENT_VARIABLE_TYPE_UNSPECIFIED"
        | "EXPERIMENT_VARIABLE_TYPE_BOOLEAN"
        | "EXPERIMENT_VARIABLE_TYPE_STRING"
        | "EXPERIMENT_VARIABLE_TYPE_INTEGER";
      /**
       * 실험에 대한 메타데이터에요.
       */
      metadata?: {
        /**
         * 실험의 키값이에요
         */
        experiment_key: string;
        /**
         * 유저에게 할당된 실험 그룹 이름이에요. 'unassigned', 'control', 'treatmentN' 세가지 형태로 값을 받아요. treatmentN은 treatment1, treatment2, ... 형태를 의미해요.
         */
        assigned_group_name: string;
        /**
         * 실험 디버깅 등을 위해 강제로 특정 실험군에 배정했는지 여부를 의미해요
         */
        is_allowlisted: boolean;
      };
    };
  };
  ReqExperimentTriggerEventResponse: Empty;
  ReqHttpCommonRequestHeadersRequestBody: Empty;
  ReqHttpCommonRequestHeadersResponse: {
    /**
     * 헤더 목록
     */
    headers: {
      name: string;
      value: string;
    }[];
  };
  ReqInputFocusRequestBody: {
    /**
     * <input> 요소의 id
     */
    inputId: string;
  };
  ReqInputFocusResponse: Empty;
  ReqStreamSubscribeRequestBody: Empty;
  ReqStreamSubscribeResponse: {
    stream: {
      eventName: string;
      /**
       * JSON.stringify()된 데이터 값
       */
      data?: string;
    };
  };
  ReqKeyboardNotificationSubscribeRequestBody: {
    DO_NOT_USE_OR_YOU_WILL_BE_FIRED?: string;
  };
  ReqKeyboardNotificationSubscribeResponse: {
    notificationName: string;
    keyboardHeight: number;
    animationDuration: number;
  };
  ReqBackButtonPressedSubscribeRequestBody: Empty;
  ReqBackButtonPressedSubscribeResponse: Empty;
}
export interface Empty {}
/**
 * 체크인 지역 정보
 */
export interface Region {
  id: number;
  name: string;
  fullname: string;
  name1: string;
  name2: string;
  name3: string;
  name1Id: number;
  name2Id: number;
  name3Id: number;
  parentId: number;
  /**
   * 위도/경도
   */
  centerCoordinates: {
    latitude: number;
    longitude: number;
  };
}
export interface PluginOpenPayload {
  plugin:
    | {
        /**
         * 리모트 웹 주소
         */
        remote: string;
        /**
         * 높이 (px) (deprecated)
         */
        height?: number;
        /**
         * 높이 (px) (0px 초과)
         */
        viewHeight?: number;
        /**
         * 높이 (0.1 ~ 1.0)
         */
        viewHeightRatio?: number;
        /**
         * 바깥 부분을 터치해 닫는 동작을 막습니다
         */
        disableClose?: boolean;
        /**
         * 해당 플러그인이 닫을 때 Stream에 발생시킬 이벤트 이름
         */
        closeEventName?: string;
        [k: string]: unknown;
      }
    | {
        /**
         * 로컬 웹뷰 주소
         */
        app: string;
        /**
         * 로컬 웹뷰 내 경로 (HashRouter)
         */
        path: string;
        /**
         * 높이 (px) (deprecated)
         */
        height?: number;
        /**
         * 높이 (px) (0px 초과)
         */
        viewHeight?: number;
        /**
         * 높이 (0.1 ~ 1.0)
         */
        viewHeightRatio?: number;
        /**
         * 바깥 부분을 터치해 닫는 동작을 막습니다
         */
        disableClose?: boolean;
        /**
         * 해당 플러그인이 닫을 때 Stream에 발생시킬 이벤트 이름
         */
        closeEventName?: string;
        [k: string]: unknown;
      };
}

export interface MetaBridgeDriver {
  onQueried: (queryName: string, requestBody: any) => Promise<any>;
  onSubscribed: (
    subscriptionName: string,
    requestBody: any,
    listener: (error: Error | null, response: any | null) => void
  ) => () => void;
}

export type BridgeInstance<T> = {
  driver: T;
  /**
   * Access Token 정보 요청
   */
  getAccessToken: (
    req: KarrotBridgeSchema["ReqAuthGetAccessTokenRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqAuthGetAccessTokenResponse"]>;
  /**
   * Access Token 갱신 요청
   */
  refreshAccessToken: (
    req: KarrotBridgeSchema["ReqAuthRefreshAccessTokenRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqAuthRefreshAccessTokenResponse"]>;
  /**
   * 긍정적인 햅틱 (ex: 회원가입 성공)
   */
  setHapticSuccess: (
    req: KarrotBridgeSchema["ReqHapticSuccessRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqHapticSuccessResponse"]>;
  /**
   * 부정적인 햅틱 (ex: 문자 인증 실패)
   */
  setHapticError: (
    req: KarrotBridgeSchema["ReqHapticErrorRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqHapticErrorResponse"]>;
  /**
   * 선택 했을때 햅틱 (Long Press) (ex: 텍스트에 있는 특정 문장을 드래그하기 위한 롱프레스)
   */
  setHapticSelect: (
    req: KarrotBridgeSchema["ReqHapticSelectRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqHapticSelectResponse"]>;
  /**
   * 터치 했을 때 햅틱 (Press)
   */
  setHapticLightFeedback: (
    req: KarrotBridgeSchema["ReqHapticLightFeedbackRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqHapticLightFeedbackResponse"]>;
  /**
   * 분석 시스템에 이벤트를 로깅해요 (Firebase / Karrot / APPSFLYER / BRAZE)
   */
  logEvent: (
    req: KarrotBridgeSchema["ReqAnalyticsLogEventRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqAnalyticsLogEventResponse"]>;
  /**
   * 유저의 현재 위치를 요청해요
   */
  getCurrentPosition: (
    req: KarrotBridgeSchema["ReqGeolocationCurrentPositionRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqGeolocationCurrentPositionResponse"]>;
  /**
   * 현재 웹뷰의 이미지 피커를 설정해요
   */
  configureImage: (
    req: KarrotBridgeSchema["ReqImageConfigureRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqImageConfigureResponse"]>;
  /**
   * 이미지 피커를 열어요 (iOS Only)
   */
  pickImages: (
    req: KarrotBridgeSchema["ReqImagePickRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqImagePickResponse"]>;
  /**
   * 앱 정보를 요청해요
   */
  getAppInfo: (
    req: KarrotBridgeSchema["ReqInfoAppRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqInfoAppResponse"]>;
  /**
   * 현재 앱에 체크인 된 지역 정보를 요청해요
   */
  getRegionInfo: (
    req: KarrotBridgeSchema["ReqInfoRegionRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqInfoRegionResponse"]>;
  /**
   * 로그인 한 유저 정보를 요청해요
   */
  getUserInfo: (
    req: KarrotBridgeSchema["ReqInfoUserRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqInfoUserResponse"]>;
  /**
   * 사용자 앱 내 분석용(KarrotAnalytics) 세션 정보를 가져와요
   */
  getKarrotAnalyticsSessionInfo: (
    req: KarrotBridgeSchema["ReqInfoAnalyticsSessionRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqInfoAnalyticsSessionResponse"]>;
  /**
   * 플러그인(하프뷰)을 열어요
   */
  openPlugin: (
    req: KarrotBridgeSchema["ReqPluginOpenRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqPluginOpenResponse"]>;
  /**
   * 플러그인(하프뷰)을 닫아요
   */
  closePlugin: (
    req: KarrotBridgeSchema["ReqPluginCloseRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqPluginCloseResponse"]>;
  /**
   * 현재 열려있는 플러그인(하프뷰)의 스타일을 변경해요
   */
  styleCurrentPlugin: (
    req: KarrotBridgeSchema["ReqPluginStyleRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqPluginStyleResponse"]>;
  /**
   * 지역 설정을 변경해요
   */
  changeRegion: (
    req: KarrotBridgeSchema["ReqRegionChangeRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqRegionChangeResponse"]>;
  /**
   * 네이티브 공유 모달을 열어요
   */
  share: (
    req: KarrotBridgeSchema["ReqShareOpenRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqShareOpenResponse"]>;
  /**
   * 토스트(스낵바)를 열어요
   */
  openToast: (
    req: KarrotBridgeSchema["ReqToastOpenRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqToastOpenResponse"]>;
  /**
   * 새 웹뷰를 열어요
   */
  pushRouter: (
    req: KarrotBridgeSchema["ReqRouterPushRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqRouterPushResponse"]>;
  /**
   * 새 웹뷰를 Present로 열어요. (iOS에서만 특별하게 작동해요. Android에서는 REQ.ROUTER.PUSH와 동일하게 작동해요.)
   */
  pushRouterWithPresent: (
    req: KarrotBridgeSchema["ReqRouterOpenRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqRouterOpenResponse"]>;
  /**
   * 현재 웹뷰를 교체해요
   */
  replaceRouter: (
    req: KarrotBridgeSchema["ReqRouterReplaceRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqRouterReplaceResponse"]>;
  /**
   * 백그라운드에서 해당 웹뷰를 미리 다운로드 받아놓아요
   */
  prefetchRouter: (
    req: KarrotBridgeSchema["ReqRouterPrefetchRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqRouterPrefetchResponse"]>;
  /**
   * 웹뷰를 닫아요
   */
  closeRouter: (
    req: KarrotBridgeSchema["ReqRouterCloseRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqRouterCloseResponse"]>;
  /**
   * 현재 띄워져있는 모든 웹뷰를 닫아요
   */
  dangerouslyCloseAllRouters: (
    req: KarrotBridgeSchema["ReqRouterCloseAllRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqRouterCloseAllResponse"]>;
  /**
   * 현재 웹뷰의 스타일을 변경해요
   */
  styleCurrentRouter: (
    req: KarrotBridgeSchema["ReqRouterStyleRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqRouterStyleResponse"]>;
  /**
   * 앱 저장소에 데이터를 저장해요
   */
  setItemInStorage: (
    req: KarrotBridgeSchema["ReqStorageSetRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqStorageSetResponse"]>;
  /**
   * 앱 저장소에 저장된 데이터를 불러와요
   */
  getItemInStorage: (
    req: KarrotBridgeSchema["ReqStorageGetRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqStorageGetResponse"]>;
  /**
   * 앱 저장소에 저장된 모든 데이터를 불러와요
   */
  getAllItemsInStorage: (
    req: KarrotBridgeSchema["ReqStorageGetAllRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqStorageGetAllResponse"]>;
  /**
   * 앱 저장소에 저장된 데이터를 삭제해요
   */
  deleteItemInStorage: (
    req: KarrotBridgeSchema["ReqStorageDeleteRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqStorageDeleteResponse"]>;
  /**
   * Stream에 이벤트를 발행합니다
   */
  emitToStream: (
    req: KarrotBridgeSchema["ReqStreamEmitRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqStreamEmitResponse"]>;
  /**
   * 동네생활 피드를 새로고침합니다
   */
  refreshCommunityFeed: (
    req: KarrotBridgeSchema["ReqCommunityRefreshFeedRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqCommunityRefreshFeedResponse"]>;
  /**
   * 이웃 광고가 완료 되는 경우 판매내역에서 업데이트 처리를 위한 핑을 날립니다.
   */
  notifyCompletedAds: (
    req: KarrotBridgeSchema["ReqNeighborCompleteAdsRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqNeighborCompleteAdsResponse"]>;
  /**
   * Android에서 하드웨어/상단바 백버튼을 활성화/비활성화 해요
   */
  configureAndroidBackButtonPressed: (
    req: KarrotBridgeSchema["ReqBackButtonPressedConfigureRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqBackButtonPressedConfigureResponse"]>;
  /**
   * 실험플랫폼에서 SUBJECT_TYPE_USER 실험의 실험변수를 받아요
   */
  getExperimentVariables: (
    req: KarrotBridgeSchema["ReqExperimentVariablesRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqExperimentVariablesResponse"]>;
  /**
   * 실험플랫폼에 이벤트를 보내요. 실험 플랫폼 API와 인터페이스를 통힐하기 위해 부득이하게 snake_case를 사용해요.
   */
  triggerExperimentEvent: (
    req: KarrotBridgeSchema["ReqExperimentTriggerEventRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqExperimentTriggerEventResponse"]>;
  /**
   * API 요청에 필요한 공통 헤더를 받아요
   *
   * Minimum Support App Version
   * - iOS 23.46.0
   * - Android 23.46.1
   */
  getCommonRequestHeaders: (
    req: KarrotBridgeSchema["ReqHttpCommonRequestHeadersRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqHttpCommonRequestHeadersResponse"]>;
  /**
   * <input> 요소에 focus()를 호출하여 키보드를 띄워요. (Android의 경우 모든 OS 버전에서 동작하며, iOS의 경우 iOS 16.4 이상 버전부터 동작해요.)
   *
   * Minimum Support App Version
   * - iOS 24.12.0
   * - Android 24.12.0
   */
  focusInput: (
    req: KarrotBridgeSchema["ReqInputFocusRequestBody"]
  ) => Promise<KarrotBridgeSchema["ReqInputFocusResponse"]>;
  /**
   * (주의) 이 API를 호출하시면 다른 스트림이 작동하지 않습니다. 반드시 karrotbridge.driver.compat.subscribeStream()을 활용해주세요.
   */
  dangerouslySubscribeStream: (
    req: KarrotBridgeSchema["ReqStreamSubscribeRequestBody"],
    listener: (
      error: Error | null,
      response: KarrotBridgeSchema["ReqStreamSubscribeResponse"] | null
    ) => void
  ) => () => void;
  /**
   * iOS에서 디바이스 키보드 노티피케이션 정보를 전달해요
   */
  subscribeKeyboardNotification: (
    req: KarrotBridgeSchema["ReqKeyboardNotificationSubscribeRequestBody"],
    listener: (
      error: Error | null,
      response:
        | KarrotBridgeSchema["ReqKeyboardNotificationSubscribeResponse"]
        | null
    ) => void
  ) => () => void;
  /**
   * Android에서 하드웨어/상단바 백버튼 이벤트를 구독해요
   */
  subscribeAndroidBackButtonPressed: (
    req: KarrotBridgeSchema["ReqBackButtonPressedSubscribeRequestBody"],
    listener: (
      error: Error | null,
      response:
        | KarrotBridgeSchema["ReqBackButtonPressedSubscribeResponse"]
        | null
    ) => void
  ) => () => void;
};

export function makeKarrotBridge<T extends MetaBridgeDriver>({
  driver,
}: {
  driver: T;
}): BridgeInstance<T> {
  return {
    driver,
    getAccessToken(req) {
      return driver.onQueried("REQ.AUTH.GET_ACCESS_TOKEN", req);
    },
    refreshAccessToken(req) {
      return driver.onQueried("REQ.AUTH.REFRESH_ACCESS_TOKEN", req);
    },
    setHapticSuccess(req) {
      return driver.onQueried("REQ.HAPTIC.SUCCESS", req);
    },
    setHapticError(req) {
      return driver.onQueried("REQ.HAPTIC.ERROR", req);
    },
    setHapticSelect(req) {
      return driver.onQueried("REQ.HAPTIC.SELECT", req);
    },
    setHapticLightFeedback(req) {
      return driver.onQueried("REQ.HAPTIC.LIGHT_FEEDBACK", req);
    },
    logEvent(req) {
      return driver.onQueried("REQ.ANALYTICS.LOG_EVENT", req);
    },
    getCurrentPosition(req) {
      return driver.onQueried("REQ.GEOLOCATION.CURRENT_POSITION", req);
    },
    configureImage(req) {
      return driver.onQueried("REQ.IMAGE.CONFIGURE", req);
    },
    pickImages(req) {
      return driver.onQueried("REQ.IMAGE.PICK", req);
    },
    getAppInfo(req) {
      return driver.onQueried("REQ.INFO.APP", req);
    },
    getRegionInfo(req) {
      return driver.onQueried("REQ.INFO.REGION", req);
    },
    getUserInfo(req) {
      return driver.onQueried("REQ.INFO.USER", req);
    },
    getKarrotAnalyticsSessionInfo(req) {
      return driver.onQueried("REQ.INFO.ANALYTICS_SESSION", req);
    },
    openPlugin(req) {
      return driver.onQueried("REQ.PLUGIN.OPEN", req);
    },
    closePlugin(req) {
      return driver.onQueried("REQ.PLUGIN.CLOSE", req);
    },
    styleCurrentPlugin(req) {
      return driver.onQueried("REQ.PLUGIN.STYLE", req);
    },
    changeRegion(req) {
      return driver.onQueried("REQ.REGION.CHANGE", req);
    },
    share(req) {
      return driver.onQueried("REQ.SHARE.OPEN", req);
    },
    openToast(req) {
      return driver.onQueried("REQ.TOAST.OPEN", req);
    },
    pushRouter(req) {
      return driver.onQueried("REQ.ROUTER.PUSH", req);
    },
    pushRouterWithPresent(req) {
      return driver.onQueried("REQ.ROUTER.OPEN", req);
    },
    replaceRouter(req) {
      return driver.onQueried("REQ.ROUTER.REPLACE", req);
    },
    prefetchRouter(req) {
      return driver.onQueried("REQ.ROUTER.PREFETCH", req);
    },
    closeRouter(req) {
      return driver.onQueried("REQ.ROUTER.CLOSE", req);
    },
    dangerouslyCloseAllRouters(req) {
      return driver.onQueried("REQ.ROUTER.CLOSE_ALL", req);
    },
    styleCurrentRouter(req) {
      return driver.onQueried("REQ.ROUTER.STYLE", req);
    },
    setItemInStorage(req) {
      return driver.onQueried("REQ.STORAGE.SET", req);
    },
    getItemInStorage(req) {
      return driver.onQueried("REQ.STORAGE.GET", req);
    },
    getAllItemsInStorage(req) {
      return driver.onQueried("REQ.STORAGE.GET_ALL", req);
    },
    deleteItemInStorage(req) {
      return driver.onQueried("REQ.STORAGE.DELETE", req);
    },
    emitToStream(req) {
      return driver.onQueried("REQ.STREAM.EMIT", req);
    },
    refreshCommunityFeed(req) {
      return driver.onQueried("REQ.COMMUNITY.REFRESH_FEED", req);
    },
    notifyCompletedAds(req) {
      return driver.onQueried("REQ.NEIGHBOR.COMPLETE_ADS", req);
    },
    configureAndroidBackButtonPressed(req) {
      return driver.onQueried("REQ.BACK_BUTTON_PRESSED.CONFIGURE", req);
    },
    getExperimentVariables(req) {
      return driver.onQueried("REQ.EXPERIMENT.VARIABLES", req);
    },
    triggerExperimentEvent(req) {
      return driver.onQueried("REQ.EXPERIMENT.TRIGGER_EVENT", req);
    },
    getCommonRequestHeaders(req) {
      return driver.onQueried("REQ.HTTP.COMMON_REQUEST_HEADERS", req);
    },
    focusInput(req) {
      return driver.onQueried("REQ.INPUT.FOCUS", req);
    },
    dangerouslySubscribeStream(req, listener) {
      return driver.onSubscribed("REQ.STREAM.SUBSCRIBE", req, listener);
    },
    subscribeKeyboardNotification(req, listener) {
      return driver.onSubscribed(
        "REQ.KEYBOARD_NOTIFICATION.SUBSCRIBE",
        req,
        listener
      );
    },
    subscribeAndroidBackButtonPressed(req, listener) {
      return driver.onSubscribed(
        "REQ.BACK_BUTTON_PRESSED.SUBSCRIBE",
        req,
        listener
      );
    },
  };
}
