import {
  BridgeInvokeError,
  MissingValueError,
} from "@daangn/plantae-error-types";
import pRetry from "p-retry";

import { getStore } from "./store";

export default function resolveToken() {
  const { context } = getStore()!;

  return (
    context.refresh ??
    pRetry(
      async () =>
        context.bridge
          .getAccessToken({})
          .catch((e) => {
            console.warn(
              "Retrying getAccessToken, visibility:",
              document.visibilityState
            );
            const err = new BridgeInvokeError(e.message);
            err.stack = e.stack;
            throw err;
          })
          .then((res) => {
            if (!res?.accessToken) {
              console.warn(
                "Failed to get access token, visibility:",
                document.visibilityState
              );
              throw new MissingValueError(
                `Access token does not exist in message: ${JSON.stringify(res)}`
              );
            }

            return res.accessToken;
          }),
      {
        retries: 3,
        minTimeout: 16,
      }
    )
  );
}
