/* eslint-disable @typescript-eslint/no-shadow */
import { createMachine } from "@zag-js/core";
import { compact } from "@zag-js/utils";

import type {
  MachineContext,
  MachineState,
  UserDefinedContext,
} from "./snackbar.types";

export function machine(userContext: UserDefinedContext) {
  const ctx = compact(userContext);
  return createMachine<MachineContext, MachineState>(
    {
      id: "checkbox",
      initial: "inactive",

      context: {
        queue: [],
        currentSnackbar: null,
        remaining: 0,
        ...ctx,
      },

      computed: {
        queueLength: (ctx) => ctx.queue.length,
      },

      states: {
        inactive: {
          entry: "activateIfNeeded",
          on: {
            PUSH: {
              target: "active",
              actions: ["push", "pop"],
            },
            ACTIVATE: {
              guard: "isActivatable",
              target: "active",
              actions: ["pop"],
            },
          },
        },
        active: {
          tags: ["visible"],
          after: {
            VISIBLE_DURATION: {
              target: "dismissing",
            },
          },
          on: {
            PAUSE: "persist",
            DISMISS: {
              target: "dismissing",
              actions: ["invokeOnClose"],
            },
            PUSH: {
              actions: ["push"],
            },
          },
        },
        persist: {
          tags: ["visible"],
          on: {
            RESUME: "active",
            DISMISS: {
              target: "dismissing",
              actions: ["invokeOnClose"],
            },
            PUSH: {
              actions: ["push"],
            },
          },
        },
        dismissing: {
          after: {
            REMOVE_DELAY: {
              target: "inactive",
              actions: ["invokeOnClose", "removeCurrentSnackbar"],
            },
          },
          on: {
            PUSH: {
              actions: ["push"],
            },
          },
        },
      },
    },
    {
      guards: {
        isActivatable: (ctx) => ctx.queueLength >= 1,
      },

      actions: {
        push(ctx, evt) {
          ctx.queue.push(evt.options);
        },
        pop(ctx) {
          const [snackbar] = ctx.queue;
          ctx.queue.shift();
          ctx.currentSnackbar = snackbar ?? null;
          ctx.remaining = snackbar?.timeout ?? 0;
        },
        activateIfNeeded(ctx, _evt, { send }) {
          if (ctx.queueLength >= 1) {
            send({ type: "ACTIVATE" });
          }
        },
        invokeOnClose(ctx) {
          ctx.currentSnackbar?.onClose?.();
        },
        removeCurrentSnackbar(ctx) {
          ctx.currentSnackbar = null;
        },
      },

      delays: {
        VISIBLE_DURATION: (ctx) => ctx.remaining,

        REMOVE_DELAY: (ctx) => ctx.currentSnackbar?.removeDelay ?? 0,
      },
    },
  );
}
