/**
 * 브릿지 호출 도중 에러가 발생한 경우
 */
export class BridgeInvokeError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "BridgeInvokeError";
  }
}

/**
 * 브릿지 응답에 필요로 하는 필드가 없는 경우
 */
export class MissingValueError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "MissingValueError";
  }
}
