/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
import { createMachine, guards } from "@zag-js/core";
import { addDomEvent } from "@zag-js/dom-event";
import { getScrollParents, indexOfId, nextTick, raf } from "@zag-js/dom-query";
import { getFocusables } from "@zag-js/tabbable";
import { compact } from "@zag-js/utils";

import { dom } from "./tabs.dom";
import type {
  MachineContext,
  MachineState,
  UserDefinedContext,
} from "./tabs.types";

const { not, and } = guards;

export function machine(userContext: UserDefinedContext) {
  const ctx = compact(userContext);
  return createMachine<MachineContext, MachineState>(
    {
      initial: "idle",

      context: {
        dir: "ltr",
        orientation: "horizontal",
        activationMode: "automatic",
        touchStartX: 0,
        swipeDeltaRatioX: 0,
        isSwiping: false,
        isScrolling: false,
        swipeEndX: 0,
        swipeCurrentX: 0,
        lastScrollTime: 0,
        isLazy: false,
        lazyMode: "keepMounted",
        number: 1,
        isSwipeable: false,
        value: null,
        focusedValue: null,
        previousValues: [],
        indicatorRect: { left: "0px", top: "0px", width: "0px", height: "0px" },
        hasMeasuredRect: false,
        isIndicatorRendered: false,
        loop: true,
        translations: {},
        ...ctx,
      },

      computed: {
        isHorizontal: (ctx) => ctx.orientation === "horizontal",
        isVertical: (ctx) => ctx.orientation === "vertical",
        tabCounts: (ctx) => dom.getElements(ctx).length,
        panelOffsetWidth: (ctx) => dom.getPanelGroupEl(ctx)?.offsetWidth!,
        currentTabIndex: (ctx) => {
          return indexOfId(dom.getElements(ctx), dom.getTabId(ctx, ctx.value!));
        },
        swipeFinalDistanceRatioX: (ctx) => {
          return (ctx.touchStartX - ctx.swipeEndX) / ctx.panelOffsetWidth;
        },
        swipeCurrentDistanceRatioX: (ctx) => {
          return (ctx.touchStartX - ctx.swipeCurrentX) / ctx.panelOffsetWidth;
        },
        isSwipeLeftInFirstTab: (ctx) => {
          return ctx.currentTabIndex <= 0 && ctx.swipeCurrentDistanceRatioX < 0;
        },
        isSwipeRightInLastTab: (ctx) => {
          return (
            ctx.currentTabIndex >= ctx.tabCounts - 1 &&
            ctx.swipeCurrentDistanceRatioX > 0
          );
        },
      },

      created: ["setPrevSelectedTabs"],

      watch: {
        focusedValue: "invokeOnFocus",
        value: [
          "invokeOnChange",
          "setPrevSelectedTabs",
          "setIndicatorRect",
          "setPanelTabIndex",
        ],
        dir: ["clearMeasured", "setIndicatorRect"],
      },

      activities: ["trackScroll"],

      on: {
        SET_VALUE: {
          actions: "setValue",
        },
        CLEAR_VALUE: {
          actions: "clearValue",
        },
      },

      entry: ["checkRenderedElements", "setIndicatorRect", "setPanelTabIndex"],

      states: {
        idle: {
          on: {
            TAB_FOCUS: {
              guard: "selectOnFocus",
              target: "focused",
              actions: ["setFocusedValue", "setValue"],
            },
            TAB_CLICK: {
              target: "focused",
              actions: ["setFocusedValue", "setValue"],
            },
            TOUCH_START: {
              guard: and(not("isScrolling"), "isSwipeable"),
              target: "swiping",
              actions: "onTouchStart",
            },
          },
        },
        focused: {
          on: {
            TAB_CLICK: {
              target: "focused",
              actions: ["setFocusedValue", "setValue"],
            },
            ARROW_LEFT: {
              guard: "isHorizontal",
              actions: "focusPrevTab",
            },
            ARROW_RIGHT: {
              guard: "isHorizontal",
              actions: "focusNextTab",
            },
            ARROW_UP: {
              guard: "isVertical",
              actions: "focusPrevTab",
            },
            ARROW_DOWN: {
              guard: "isVertical",
              actions: "focusNextTab",
            },
            HOME: {
              actions: "focusFirstTab",
            },
            END: {
              actions: "focusLastTab",
            },
            ENTER: {
              guard: not("selectOnFocus"),
              actions: "setValue",
            },
            TAB_FOCUS: [
              {
                guard: "selectOnFocus",
                actions: ["setFocusedValue", "setValue"],
              },
              { actions: "setFocusedValue" },
            ],
            TAB_BLUR: {
              target: "idle",
              actions: "clearFocusedValue",
            },
            TOUCH_START: {
              target: "swiping",
              actions: "onTouchStart",
            },
          },
        },
        swiping: {
          on: {
            SWIPE_MOVE: {
              guard: and(not("isScrolling"), "isSwipeable"),
              actions: ["checkIsScrolling", "onSwipeMove"],
            },
            SWIPE_END: {
              target: "focused",
              actions: ["onSwipeEnd"],
            },
          },
          exit: ["resetSwipeDatas"],
        },
      },
    },
    {
      guards: {
        isVertical: (ctx) => ctx.isVertical,
        isHorizontal: (ctx) => ctx.isHorizontal,
        selectOnFocus: (ctx) => ctx.activationMode === "automatic",
        isScrolling: (ctx) => ctx.isScrolling,
        isSwipeable: (ctx) => ctx.isSwipeable ?? false,
      },
      activities: {
        trackScroll(ctx) {
          const trigger = dom.getPanelGroupEl(ctx);
          if (!trigger) return;

          function onScroll() {
            ctx.lastScrollTime = Date.now();
          }

          const cleanups = getScrollParents(trigger).map((el) => {
            const opts = { passive: true, capture: true } as const;
            return addDomEvent(el, "scroll", onScroll, opts);
          });

          return () => {
            cleanups.forEach((fn) => fn?.());
          };
        },
      },
      actions: {
        setFocusedValue(ctx, evt) {
          ctx.focusedValue = evt.value;
        },
        clearFocusedValue(ctx) {
          ctx.focusedValue = null;
        },
        setValue(ctx, evt) {
          ctx.value = evt.value;
        },
        clearValue(ctx) {
          ctx.value = null;
        },
        invokeOnDelete(ctx, evt) {
          ctx.onDelete?.({ value: evt.value });
        },
        focusFirstTab(ctx) {
          raf(() => dom.getFirstEl(ctx)?.focus());
        },
        focusLastTab(ctx) {
          raf(() => dom.getLastEl(ctx)?.focus());
        },
        focusNextTab(ctx) {
          if (!ctx.focusedValue) return;
          const next = dom.getNextEl(ctx, ctx.focusedValue);
          raf(() => next?.focus());
        },
        focusPrevTab(ctx) {
          if (!ctx.focusedValue) return;
          const prev = dom.getPrevEl(ctx, ctx.focusedValue);
          raf(() => prev?.focus());
        },
        setIndicatorRect(ctx) {
          nextTick(() => {
            if (!ctx.isIndicatorRendered || !ctx.value) return;
            ctx.indicatorRect = dom.getRectById(ctx, ctx.value);
            if (ctx.hasMeasuredRect) return;
            nextTick(() => {
              ctx.hasMeasuredRect = true;
            });
          });
        },
        checkRenderedElements(ctx) {
          ctx.isIndicatorRendered = !!dom.getIndicatorEl(ctx);
        },
        clearMeasured(ctx) {
          ctx.hasMeasuredRect = false;
        },
        invokeOnChange(ctx) {
          ctx.onChange?.({ value: ctx.value });
        },
        invokeOnFocus(ctx) {
          ctx.onFocus?.({ value: ctx.focusedValue });
        },
        setPrevSelectedTabs(ctx) {
          if (ctx.value != null) {
            ctx.previousValues = pushUnique(
              Array.from(ctx.previousValues),
              ctx.value,
            );
          }
        },
        // if tab panel contains focusable elements, remove the tabindex attribute
        setPanelTabIndex(ctx) {
          raf(() => {
            const panel = dom.getActivePanelEl(ctx);
            if (!panel) return;
            const focusables = getFocusables(panel);
            if (focusables.length > 0) {
              panel.removeAttribute("tabindex");
            } else {
              panel.setAttribute("tabindex", "0");
            }
          });
        },

        checkIsScrolling(ctx) {
          ctx.isScrolling = Date.now() - ctx.lastScrollTime < second(0.1);
        },

        // swipe
        onTouchStart(ctx, event) {
          ctx.touchStartX = event.x;
        },
        onSwipeMove(ctx, event) {
          ctx.swipeCurrentX = event.x;

          const SWIPE_START_THRESHOLD_RATIO = 0.025;
          if (
            Math.abs(ctx.swipeCurrentDistanceRatioX) >
              SWIPE_START_THRESHOLD_RATIO &&
            !ctx.isSwiping
          ) {
            ctx.isSwiping = true;
            ctx.onSwipeStart?.();
            // 스와이프할 때 스크롤이 발생 방지
            const activeTab = dom.getActivePanelEl(ctx);
            activeTab?.style.setProperty("overflow", "hidden");
          }

          // 첫 번째 탭에서 왼쪽으로 스와이프하거나 마지막 탭에서 오른쪽으로 스와이프할 때
          if (ctx.isSwipeLeftInFirstTab || ctx.isSwipeRightInLastTab) return;

          // 스와이프 거리가 100% 이상일 때
          if (ctx.swipeCurrentDistanceRatioX > 1) {
            ctx.swipeDeltaRatioX = 1;
            return;
          }
          if (ctx.swipeCurrentDistanceRatioX < -1) {
            ctx.swipeDeltaRatioX = -1;
            return;
          }

          ctx.swipeDeltaRatioX = ctx.swipeCurrentDistanceRatioX;
        },
        onSwipeEnd(ctx, event) {
          if (!ctx.isSwipeable) return;

          ctx.swipeEndX = event.x;

          // 스와이프가 아닌 스크롤일 때
          if (ctx.isScrolling) {
            ctx.isScrolling = false;
            return;
          }

          if (ctx.isSwipeLeftInFirstTab) return;
          if (ctx.isSwipeRightInLastTab) return;
          if (!isSwipeEnough(ctx)) return;

          // 이동
          if (ctx.swipeFinalDistanceRatioX > 0) {
            changeCurrentTab(ctx, "next");
          } else {
            changeCurrentTab(ctx, "prev");
          }
        },
        resetSwipeDatas(ctx) {
          if (!ctx.isSwiping) return;
          const activeTab = dom.getActivePanelEl(ctx);
          activeTab?.style.setProperty("overflow", "auto");
          ctx.onSwipeEnd?.();
          ctx.isSwiping = false;
          ctx.swipeDeltaRatioX = 0;
        },
      },
    },
  );
}

function second(n: number) {
  return n * 1000;
}

function isSwipeEnough(ctx: MachineContext) {
  const SWIPE_THRESHOLD_RATIO = 0.3;

  // NOTE(ko): 오른쪽에서 왼쪽으로 스와이프하면 양수, 왼쪽에서 오른쪽으로 스와이프하면 음수
  // NOTE(en): Swipe right to left for positive, left to right for negative
  const swipeRatioX = ctx.swipeFinalDistanceRatioX;
  if (swipeRatioX === 0) return false;

  const isSwipeRightToLeft = swipeRatioX > 0;
  const isSwipeLeftToRight = swipeRatioX < 0;
  const isSwipeNotEnoughRightToLeft = swipeRatioX < SWIPE_THRESHOLD_RATIO;
  const isSwipeNotEnoughLeftToRight = swipeRatioX > -SWIPE_THRESHOLD_RATIO;

  if (
    (isSwipeRightToLeft && isSwipeNotEnoughRightToLeft) ||
    (isSwipeLeftToRight && isSwipeNotEnoughLeftToRight)
  ) {
    return false;
  }

  return true;
}

function changeCurrentTab(ctx: MachineContext, evt: "next" | "prev") {
  const currentValue = ctx.value;
  const currentTabIndex = ctx.currentTabIndex;

  if (!currentValue) return;

  const next = dom.getNextEl(ctx, currentValue);
  const prev = dom.getPrevEl(ctx, currentValue);

  if (!next || !prev) return;

  const newTabIndex =
    evt === "next" ? currentTabIndex + 1 : currentTabIndex - 1;
  const newTabValue =
    evt === "next"
      ? next?.getAttribute("data-value")
      : prev?.getAttribute("data-value");

  if (newTabIndex < 0 || newTabIndex >= ctx.tabCounts) {
    return;
  }

  ctx.value = newTabValue;
  ctx.focusedValue = newTabValue;
}

// NOTE(ko): 함수를 사용하여 값 배열을 푸시하고 이전 값 인스턴스를 제거합니다.
// NOTE(en): function to push value array and remove previous instances of value
function pushUnique(arr: any[], value: any) {
  const newArr = arr.slice();
  const index = newArr.indexOf(value);
  if (index > -1) {
    newArr.splice(index, 1);
  }
  newArr.push(value);
  return newArr;
}
