import 'src/styles/f.css.ts.vanilla.css?source=#H4sIAAAAAAAAE62azXKbMBRG934KlvFCmSB+0jqbvkkHsGJoJKAg4jgdv3snTpwGTGVx77c2OpOcOfPZMNz+DPNDFdf3d8GfVRBsq77V2WETPGr18rA6rm7Pn4enzzNd7WpRWWX6TVCo2qpudJU8XSX2Kn+qrBh61YleaVXYTVA3tRpdG5+ufWxqK/rqVW2C56y7EaJXait6ZbLaVoWwh7bZdVlbHkTebA9ChyJv9FZ8Hls/nCF7Ve1KS8C8HzyBdFUrURJAXw6+g5S1b/98mxVVvVvIGp1dj6QlRGmd2g066wDeRiSmujMLZO8T5xCYkgRKTHUSVZ0EVievV3dPlIaqTgKrk9jqpFd13ygCDWbrDGrrDHDrjMfWfSdKA1U3JTHVIaszfluXkQRits6gts4At854bF1OlIaqDrh1Brt1xm/rioUCi6y1VVOzx26GQ5Q3JjHVTWAOcVuqOH568yiuPlB8lzyHREWTyB69GQ5PH2b2JjCHuEeqOFh9mOW7gKEM+tS3WyixDBnazoeJosqQq+aN4JBRLpUhOTIkT4Zky5BOGdVSGRFHRsSTEbFlRE4Zv5bKiDkyYp6MmC0jdsp4WihDZ7niPwa7oBD1fOUwPY1QDmGaJoz/PTUH4mkDfUdNaQ55hiKP/evogsLRhvllNEI5hNU0YaDaML+JJiiMOZ/aGoq8CFJbBKotwtUWXa+tpQkD1RbhaougtUVetf2myIshtcWg2mJcbfH12jqaMFBtMa62GFpb7FVbT5GXQGpLQLUluNqS67VZmjBQbQmutgRaW+JV20CRl0JqS0G1pbja0uu1PdOEgWpLcbWl0NpSr9r2C+X1Q24rqxX7tnQORJQ3QTHlTWkOeS9kefz4/sNiKwQlOAN0iDwQRbJvV+dATIWYm9YpzSHvlSwPVyHm7vWSBrPoU2F4t9AkZglRMwjcQN8BDEOaMX54wO3DDt+C1QslxR5781CDB1w736kLI5oxUG+YlcNO3JJ9W/oi7onNfkh3QeF4wzykG6Fcxpa+hfsBBvWGeUo3QWHUOXr70Q9t23S2D27O74fbZihKUWRaN8PHG+Lrk9nJ++T/3iifOfH2xx5Xx7/CvDudyS4AAA==';
import 'src/components/entry-point.css.ts.vanilla.css?source=Ll8xZ2EwdTYyMCB7CiAgaGVpZ2h0OiA0MDdweDsKfQouXzFnYTB1NjIxIHsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIGRpc3BsYXk6IGZsZXg7CiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjsKICBoZWlnaHQ6IDQwN3B4OwogIGxlZnQ6IDA7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHRvcDogMDsKICB0cmFuc2l0aW9uOiAuNXMgZWFzZS1vdXQ7CiAgd2lkdGg6IDEwMCU7CiAgei1pbmRleDogMjsKfQouXzFnYTB1NjIyIHsKICBvcGFjaXR5OiAxOwogIHZpc2liaWxpdHk6IHZpc2libGU7Cn0KLl8xZ2EwdTYyMyB7CiAgb3BhY2l0eTogMDsKICB2aXNpYmlsaXR5OiBoaWRkZW47Cn0KLl8xZ2EwdTYyNCB7CiAgaGVpZ2h0OiAxMjlweDsKICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjsKICBtYXJnaW4tdG9wOiAyOHB4OwogIHdpZHRoOiAzNDBweDsKfQouXzFnYTB1NjI1IHsKICBwYWRkaW5nOiAyNy45NjRweCA5OS45ODFweCA3LjI0N3B4IDk5LjVweDsKICBib3gtc2l6aW5nOiBjb250ZW50LWJveDsKfQouXzFnYTB1NjI2IHsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIGFsaWduLXNlbGY6IHN0cmV0Y2g7CiAgZ2FwOiAxMnB4OwogIHBhZGRpbmc6IDI0cHggMjBweDsKfQouXzFnYTB1NjI5IHsKICBhbGlnbi1zZWxmOiBzdHJldGNoOwogIHBhZGRpbmc6IDEycHggMTZweCA4cHggMTZweDsKfQouXzFnYTB1NjJhIHsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIGFsaWduLXNlbGY6IHN0cmV0Y2g7CiAgcGFkZGluZzogNnB4IDBweDsKfQ==';
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var boxButtonArea = '_1ga0u629 _1byi4n70';
export var entryPoint = _7a468({defaultClassName:'_1ga0u621',variantClassNames:{visible:{true:'_1ga0u622',false:'_1ga0u623'}},defaultVariants:{},compoundVariants:[]});
export var icon = '_1ga0u625';
export var iconArea = '_1ga0u624 _1byi4n70 _1byi4n71';
export var serviceArea = '_1ga0u626 _1byi4n70 _1byi4n71';
export var serviceAreaDescription = '_1byi4n7x';
export var serviceAreaTitle = '_1byi4n712';
export var textButtonArea = '_1ga0u62a _1byi4n70 _1byi4n71';
export var wrapper = '_1ga0u620';