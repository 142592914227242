import type { EventKeyMap } from "@zag-js/dom-event";
import { getEventKey } from "@zag-js/dom-event";
import { dataAttr, isSafari, itemById } from "@zag-js/dom-query";
import type { NormalizeProps, PropTypes } from "@zag-js/types";

import { parts } from "./tabs.anatomy";
import { dom } from "./tabs.dom";
import type { MachineApi, Send, State } from "./tabs.types";
import { lazyDisclosure } from "./tabs.utils";

export function connect<T extends PropTypes>(
  state: State,
  send: Send,
  normalize: NormalizeProps<T>,
): MachineApi<T> {
  const translations = state.context.translations;
  const isFocused = state.matches("focused");

  return {
    value: state.context.value,
    focusedValue: state.context.focusedValue,
    previousValues: Array.from(state.context.previousValues),

    currentTabIndex: state.context.currentTabIndex,

    getCurrentTabLeftOffset: () => {
      const tabEl = dom.getTabEl(state.context, state.context.value!);
      if (!tabEl) return 0;
      return tabEl.offsetLeft;
    },

    shouldPanelRender: (value: string): boolean => {
      const currentPanel = state.context.value;
      const targetPanel = value;
      const selected = currentPanel === targetPanel;

      const shouldRender = lazyDisclosure({
        wasSelected: state.context.previousValues.includes(targetPanel),
        isSelected: selected,
        enabled: state.context.isLazy,
        mode: state.context.lazyMode,
      });

      return shouldRender;
    },

    setValue(value: string) {
      send({ type: "SET_VALUE", value });
    },
    clearValue() {
      send({ type: "CLEAR_VALUE" });
    },

    rootProps: normalize.element({
      ...parts.root.attrs,
      id: dom.getRootId(state.context),
      "data-orientation": state.context.orientation,
      "data-focus": dataAttr(isFocused),
      dir: state.context.dir,
    }),

    tabListProps: normalize.element({
      ...parts.tabList.attrs,
      id: dom.getTabListId(state.context),
      role: "tablist",
      "data-focus": dataAttr(isFocused),
      "aria-orientation": "horizontal",
      "data-orientation": state.context.orientation,
      "aria-label": translations.tabListLabel,
      onKeyDown(event) {
        const keyMap: EventKeyMap = {
          ArrowDown() {
            send("ARROW_DOWN");
          },
          ArrowUp() {
            send("ARROW_UP");
          },
          ArrowLeft() {
            send("ARROW_LEFT");
          },
          ArrowRight() {
            send("ARROW_RIGHT");
          },
          Home() {
            send("HOME");
          },
          End() {
            send("END");
          },
          Enter() {
            send({ type: "ENTER", value: state.context.focusedValue });
          },
        };

        let key = getEventKey(event, state.context);
        const exec = keyMap[key];

        if (exec) {
          event.preventDefault();
          exec(event);
        }
      },
    }),

    getTabProps(props: { value: string; isDisabled?: boolean }) {
      const { value, isDisabled } = props;
      const selected = state.context.value === value;

      return normalize.button({
        ...parts.tab.attrs,
        role: "tab",
        type: "button",
        disabled: isDisabled,
        "data-orientation": state.context.orientation,
        "data-disabled": dataAttr(isDisabled),
        "aria-disabled": isDisabled,
        "data-value": value,
        "aria-selected": selected,
        "data-selected": dataAttr(selected),
        "aria-controls": dom.getPanelId(state.context, value),
        "data-ownedby": dom.getTabListId(state.context),
        id: dom.getTabId(state.context, value),
        tabIndex: selected ? 0 : -1,
        onFocus() {
          send({ type: "TAB_FOCUS", value });
        },
        onBlur(event) {
          const target = event.relatedTarget as HTMLElement | null;
          if (target?.getAttribute("role") !== "tab") {
            send({ type: "TAB_BLUR" });
          }
        },
        onClick(event) {
          if (isDisabled) return;
          if (isSafari()) {
            event.currentTarget.focus();
          }
          send({ type: "TAB_CLICK", value });
        },
      });
    },

    panelGroupProps: normalize.element({
      ...parts.panelGroup.attrs,
      id: dom.getPanelGroupId(state.context),
      "data-orientation": state.context.orientation,
      "data-swipe": dataAttr(
        !state.context.isSwipeable || state.context.isSwiping,
      ),

      style: {
        userSelect: "none",
        touchAction: "pan-y",
        overflow: "hidden",
      },

      onTouchStart(event) {
        send({ type: "TOUCH_START", x: event.touches[0].clientX });
      },
      onTouchMove(event) {
        send({ type: "SWIPE_MOVE", x: event.touches[0].clientX });
      },
      onTouchEnd(event) {
        send({ type: "SWIPE_END", x: event.changedTouches[0].clientX });
      },
    }),

    panelGroupCameraProps: normalize.element({
      ...parts.panelGroupCamera.attrs,
      id: dom.getPanelGroupCameraId(state.context),
      "data-orientation": state.context.orientation,
      "data-swipe": dataAttr(
        !state.context.isSwipeable || state.context.isSwiping,
      ),

      style: {
        transform: `translateX(-${
          (state.context.currentTabIndex + state.context.swipeDeltaRatioX) * 100
        }%)`,
        willChange: "transform",
      },
    }),

    getPanelProps(props: { value: string }) {
      const { value } = props;
      const selected = state.context.value === value;

      const isDisabled = !!itemById(
        dom.getDisabledElements(state.context),
        dom.getTabId(state.context, value!),
      );

      return normalize.element({
        ...parts.panel.attrs,
        id: dom.getPanelId(state.context, value),
        tabIndex: selected ? 0 : -1,
        "aria-labelledby": dom.getTabId(state.context, value),
        role: "tabpanel",
        "data-ownedby": dom.getTabListId(state.context),
        "data-swipeable": dataAttr(state.context.isSwipeable),
        "aria-hidden": isDisabled ? undefined : !selected,
        hidden: isDisabled,
      });
    },

    indicatorProps: normalize.element({
      id: dom.getIndicatorId(state.context),
      ...parts.indicator.attrs,
      "data-orientation": state.context.orientation,
      style: {
        "--transition-duration": "200ms",
        "--transition-property": "left, right, top, bottom, width, height",
        position: "absolute",
        willChange: "var(--transition-property)",
        transitionProperty: "var(--transition-property)",
        transitionDuration: state.context.hasMeasuredRect
          ? "var(--transition-duration)"
          : "0ms",
        transitionTimingFunction: "var(--transition-timing-function)",
        ...state.context.indicatorRect,
      },
    }),
  };
}
