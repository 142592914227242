import type { KarrotBridge } from "@daangn/karrotbridge";
import { parseAppInfoSafe } from "@daangn/webview-ua-parser";
import pRetry from "p-retry";
import type { Plugin } from "plantae";

import {
  BridgeInvokeError,
  MissingValueError,
} from "@daangn/plantae-error-types";

const MINIMUM_VERSION_CODE: Record<string, number> = {
  Android: 234601,
  iOS: 234600,
};

export function commonHeadersPlugin({
  bridge,
}: {
  bridge: KarrotBridge;
}): Plugin {
  return {
    name: "plugin-common-headers",
    hooks: {
      beforeRequest: async (req) => {
        const appInfo = parseAppInfoSafe(window?.navigator?.userAgent ?? "");

        if (!appInfo) {
          return req;
        }

        const { osType, versionCode } = appInfo;

        if (Number(versionCode) < MINIMUM_VERSION_CODE[osType]) {
          return req;
        }

        const { headers: commonHeaders } = await pRetry(
          async () =>
            bridge
              .getCommonRequestHeaders({})
              .catch((e) => {
                console.warn(
                  "Retrying getCommonRequestHeaders, visibility: ",
                  document.visibilityState
                );
                const err = new BridgeInvokeError(e.message);
                err.stack = e.stack;
                throw err;
              })
              .then((res) => {
                if (!res.headers) {
                  throw new MissingValueError(
                    `Common headers does not exist in message: ${JSON.stringify(
                      res
                    )}`
                  );
                }

                return res;
              }),
          {}
        );

        commonHeaders.forEach(({ name, value }) => {
          if (req.headers.get(name) === null) {
            req.headers.set(name, value);
          }
        });

        return req;
      },
    },
  };
}
