import "@daangn/sprout-css/text-field/index.css";

import { useTextField } from "@daangn/sprout-hooks-text-field";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import { classNames } from "@seed-design/design-token";
import type { Ref } from "react";
import React from "react";

import { ErrorMessageIcon } from "./Icon";
import type { SeedTextFieldProps } from "./types";

const label4Regular = classNames.$semantic.typography.label4Regular;
const label3Bold = classNames.$semantic.typography.label3Bold;
const label3Regular = classNames.$semantic.typography.label3Regular;
const label2Regular = classNames.$semantic.typography.label2Regular;
const label1Regular = classNames.$semantic.typography.label1Regular;

const typographyClassNameMap: Record<
  | "label"
  | "indicator"
  | "prefix"
  | "input"
  | "suffix"
  | "description"
  | "errorMessage"
  | "count-container",
  Record<Required<SeedTextFieldProps>["size"], string>
> = {
  label: {
    small: label3Bold,
    medium: label3Bold,
    large: label3Bold,
  },
  indicator: {
    small: label3Regular,
    medium: label3Regular,
    large: label3Regular,
  },
  prefix: {
    small: label3Regular,
    medium: label3Regular,
    large: label3Regular,
  },
  input: {
    small: label3Regular,
    medium: label2Regular,
    large: label1Regular,
  },
  suffix: {
    small: label3Regular,
    medium: label3Regular,
    large: label3Regular,
  },
  errorMessage: {
    small: label4Regular,
    medium: label3Regular,
    large: label3Regular,
  },
  description: {
    small: label4Regular,
    medium: label3Regular,
    large: label3Regular,
  },
  "count-container": {
    small: label4Regular,
    medium: label4Regular,
    large: label4Regular,
  },
};

const TextField = (props: SeedTextFieldProps, ref: Ref<HTMLInputElement>) => {
  const {
    size = "medium",
    variant = "outlined",
    label,
    description,
    errorMessage,
    prefix,
    suffix,
    isRequired,
    maxLength,
    requiredIndicator,
    optionalIndicator,
    hideCharacterCount,
    ...otherProps
  } = props;
  const {
    graphemes,
    isInvalid,
    rootProps,
    labelProps,
    inputProps,
    descriptionProps,
    errorMessageProps,
  } = useTextField({ ...props, elementType: "input" });
  const { styleProps } = useStyleProps(otherProps);

  const showErrorMessage = isInvalid && !!errorMessage;
  const indicator = isRequired ? requiredIndicator : optionalIndicator;
  const showHint = !!description || (errorMessage && isInvalid);
  const renderCharacterCount = !hideCharacterCount && maxLength;
  const renderFoot = showHint || renderCharacterCount;
  const renderHead = label || indicator;

  return (
    <div
      {...styleProps}
      {...rootProps}
      data-size={size}
      data-variant={variant}
      className={composeClassName("seed-text-field", styleProps.className)}
    >
      {renderHead && (
        <div data-part="head">
          {label && (
            <label
              {...labelProps}
              className={typographyClassNameMap.label[size]}
            >
              {label}
            </label>
          )}
          {indicator && (
            <span
              data-part="indicator"
              className={typographyClassNameMap.indicator[size]}
            >
              {indicator}
            </span>
          )}
        </div>
      )}
      <div data-part="field">
        {prefix && (
          <div data-part="prefix">
            {typeof prefix === "string" ? (
              <span className={typographyClassNameMap.prefix[size]}>
                {prefix}
              </span>
            ) : (
              prefix
            )}
          </div>
        )}
        <input
          className={typographyClassNameMap.input[size]}
          ref={ref}
          {...inputProps}
        />
        {suffix && (
          <div data-part="suffix">
            {typeof suffix === "string" ? (
              <span className={typographyClassNameMap.suffix[size]}>
                {suffix}
              </span>
            ) : (
              suffix
            )}
          </div>
        )}
      </div>
      {renderFoot && (
        <div data-part="foot">
          {showErrorMessage ? (
            <span
              {...errorMessageProps}
              className={typographyClassNameMap.errorMessage[size]}
            >
              {errorMessage && <ErrorMessageIcon />} {errorMessage}
            </span>
          ) : (
            <span
              {...descriptionProps}
              className={typographyClassNameMap.description[size]}
            >
              {description}
            </span>
          )}
          {renderCharacterCount && (
            <div
              data-part="count-container"
              className={typographyClassNameMap["count-container"][size]}
            >
              <span data-part="character-count">{graphemes.length}</span>
              <span data-part="max-count">/{maxLength}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _TextField = React.forwardRef(TextField);
export { _TextField as TextField };
