import * as radioGroup from "@daangn/sprout-machines-radio-group";
import { normalizeProps } from "@zag-js/react";

import type { RadioProps } from "./types";

export function useRadio(
  props: RadioProps,
  [state, send]: readonly [radioGroup.State, radioGroup.Send],
): radioGroup.RadioApi {
  const api = radioGroup.connectRadio(
    {
      value: props.value,
      disabled: props.isDisabled,
    },
    state,
    send,
    normalizeProps,
  );

  return api;
}
