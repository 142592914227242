import type { SnackbarProps } from "@daangn/sprout-hooks-snackbar";
import { useSnackbar, useSnackbarMachine } from "@daangn/sprout-hooks-snackbar";
import * as React from "react";

import { SnackbarContext } from "./context";
import Snackbar from "./Snackbar";
import SnackbarRegion from "./SnackbarRegion";
import type { SeedSnackbarOptions, SeedSnackbarPayload } from "./types";
import { SafeAreaTracker, useSafeOffset } from "./useSafeOffset";

const SNACKBAR_REGION_HEIGHT = 58; // TODO: should be calculated from CSS

export const SnackbarProvider: React.FC<
  React.PropsWithChildren<SnackbarProps>
> = (props) => {
  const { children, offset, ...otherProps } = props;
  const { safeOffset, tracker, safeAreaTrackerRef } = useSafeOffset(
    SNACKBAR_REGION_HEIGHT,
  );
  const machine = useSnackbarMachine({
    ...otherProps,
    offset: offset === "auto" ? `${safeOffset}px` : offset,
  });
  const api = useSnackbar<SeedSnackbarPayload>(machine);

  const adapter = {
    isVisible: api.isVisible,
    create: (options: SeedSnackbarOptions) => {
      tracker.forceUpdate();
      return api.create({
        timeout: options.timeout ?? 5000,
        removeDelay: options.removeDelay ?? 0,
        onClose: options.onClose,
        payload: {
          message: options.message,
          type: options.type,
          actionLabel: options.actionLabel,
          onAction: options.onAction,
          shouldCloseOnAction: options.shouldCloseOnAction,
          onClick: options.onClick,
        },
      });
    },
    dismiss: api.dismiss,
  };

  return (
    <SnackbarContext.Provider value={{ adapter, tracker }}>
      {children}
      <SnackbarRegion api={api}>
        {api.currentSnackbar && <Snackbar api={api} />}
      </SnackbarRegion>
      <SafeAreaTracker ref={safeAreaTrackerRef} />
    </SnackbarContext.Provider>
  );
};

export const useSnackbarAdapter = () => {
  const context = React.useContext(SnackbarContext);
  if (!context) {
    throw new Error(
      `useSnackbarAdapter cannot be called outside the SnackbarProvider`,
    );
  }
  return context.adapter;
};
