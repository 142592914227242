import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const IconAndroidShareThin = (
  {
    size = 24,
    ...props
  }: SVGProps<SVGSVGElement> & {
    size?: number | string,
  },
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-seed-icon="true"
    data-seed-icon-version="0.6.1"
    width={size}
    height={size}
    ref={ref}
    {...props}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3999 5.00002C15.3999 3.0118 17.0117 1.40002 18.9999 1.40002C20.9881 1.40002 22.5999 3.0118 22.5999 5.00002C22.5999 6.98825 20.9881 8.60002 18.9999 8.60002C17.9661 8.60002 17.0341 8.16429 16.3776 7.46647C16.3445 7.49321 16.308 7.51682 16.2684 7.53663L8.54946 11.3961C8.58263 11.5924 8.5999 11.7942 8.5999 12C8.5999 12.2058 8.58264 12.4075 8.54948 12.6039L16.2684 16.4633C16.308 16.4831 16.3445 16.5068 16.3776 16.5335C17.0342 15.8357 17.9662 15.4 18.9999 15.4C20.9881 15.4 22.5999 17.0118 22.5999 19C22.5999 20.9882 20.9881 22.6 18.9999 22.6C17.0117 22.6 15.3999 20.9882 15.3999 19C15.3999 18.4755 15.5121 17.9771 15.7138 17.5277L8.14925 13.7454C7.53494 14.8515 6.35484 15.6 4.9999 15.6C3.01168 15.6 1.3999 13.9882 1.3999 12C1.3999 10.0118 3.01168 8.40002 4.9999 8.40002C6.3548 8.40002 7.53488 9.14852 8.14921 10.2546L15.7137 6.4723C15.5121 6.02286 15.3999 5.52454 15.3999 5.00002ZM18.9999 2.60002C17.6744 2.60002 16.5999 3.67454 16.5999 5.00002C16.5999 6.32551 17.6744 7.40002 18.9999 7.40002C20.3254 7.40002 21.3999 6.32551 21.3999 5.00002C21.3999 3.67454 20.3254 2.60002 18.9999 2.60002ZM18.9999 16.6C17.6744 16.6 16.5999 17.6745 16.5999 19C16.5999 20.3255 17.6744 21.4 18.9999 21.4C20.3254 21.4 21.3999 20.3255 21.3999 19C21.3999 17.6745 20.3254 16.6 18.9999 16.6ZM2.5999 12C2.5999 10.6745 3.67442 9.60002 4.9999 9.60002C6.32539 9.60002 7.3999 10.6745 7.3999 12C7.3999 13.3255 6.32539 14.4 4.9999 14.4C3.67442 14.4 2.5999 13.3255 2.5999 12Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(IconAndroidShareThin);
export default ForwardRef;
