import React from "react";

import type { SeedAvatarProps } from "./types";

export const AvatarGroupContext = React.createContext<{ size: string } | null>(
  null,
);

export const useAvatarGroupProps = (props: SeedAvatarProps) => {
  const context = React.useContext(AvatarGroupContext);
  if (context == null) {
    return props;
  }
  return {
    size: context.size,
    ...props,
  };
};
