import React from "react";

const FilterSuffixIcon: React.FC = () => (
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.79392 4.18576C1.9306 4.04908 2.15221 4.04908 2.28889 4.18576L6.99974 8.89661L11.7106 4.18576C11.8473 4.04908 12.0689 4.04908 12.2056 4.18576C12.3422 4.32245 12.3422 4.54406 12.2056 4.68074L7.24723 9.63907C7.11054 9.77576 6.88894 9.77576 6.75225 9.63907L1.79392 4.68074C1.65724 4.54406 1.65724 4.32245 1.79392 4.18576Z"
      fill="currentColor"
    />
  </svg>
);

export default FilterSuffixIcon;
