import type { ReactElement, Ref } from "react";

import { BoxButton } from "@daangn/sprout-components-button";
import { forwardRef } from "react";
import * as React from "react";

import * as style from "./style.css";

export interface ErrorProps {
  /**
   * 에러 페이지에 들어갈 버튼의 텍스트를 넣어주세요.
   */
  buttonText?: ReactElement | string;

  /**
   * 에러 페이지의 본문에 들어갈 내용을 적어주세요.
   */
  description: ReactElement | string;

  /**
   * 버튼을 클릭했을 때 실행할 함수를 넣어주세요.
   */
  onClickButton?: (event: React.MouseEvent<Element, MouseEvent>) => void;

  /**
   * 에러 페이지의 제목에 들어갈 내용을 적어주세요.
   */
  title?: ReactElement | string;
}

/**
 * @description 에러 페이지를 렌더링합니다.
 * @link repo: https://github.com/daangn/daangn-error-component
 * @link storybook: https://daangn-error-component.pages.dev/
 */
const Error = (props: ErrorProps, ref: Ref<HTMLDivElement>): ReactElement => {
  const { buttonText, description, onClickButton, title } = props;

  // const matches = matchMedia("(min-width: 768px)").matches;

  return (
    <article className={style.root} ref={ref}>
      <div className={style.content}>
        {title && <h1 className={style.title}>{title}</h1>}
        <p className={title ? style.descWithTitle : style.descWithoutTitle}>
          {description}
        </p>
        {buttonText && (
          <BoxButton
            minWidth={120}
            onClick={onClickButton}
            size="medium"
            variant="secondary"
          >
            {buttonText}
          </BoxButton>
        )}
      </div>
    </article>
  );
};

const _Error = forwardRef(Error);
export { _Error as Error };
