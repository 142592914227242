import { useStyleProps } from "@daangn/sprout-style-utils";
import type { Ref } from "react";
import * as React from "react";

import type { SeedMenuFabItemGroupProps } from "../types";
import { useMenuFabContext } from "./context";

const MenuFabItemGroup = (
  props: SeedMenuFabItemGroupProps,
  ref: Ref<HTMLDivElement>,
) => {
  const { api } = useMenuFabContext();
  const { children, ...otherProps } = props;
  const { styleProps } = useStyleProps(otherProps);
  const id = React.useId();

  return (
    <div ref={ref} {...styleProps} {...api.getItemGroupProps({ id })}>
      {children}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _MenuFabItemGroup = React.forwardRef(MenuFabItemGroup);
export { _MenuFabItemGroup as MenuFabItemGroup };
