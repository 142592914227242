import { useRef, useState } from "react";
import TransferIdentificationContext from "../contexts/transfer-identification-context";

type TransferIdentificationProviderProps = React.PropsWithChildren<{
  previous_screen_name: string;
}>;

const TransferIdentificationProvider: React.FC<
  TransferIdentificationProviderProps
> = ({ children, previous_screen_name }) => {
  const [shouldOpen, setShouldOpen] = useState(false);
  const onDialogActionRef =
    useRef<(actionType: "store" | "next" | "confirm") => void>();

  return (
    <TransferIdentificationContext.Provider
      value={{
        shouldOpen,
        setShouldOpen,
        previous_screen_name,
        onDialogActionRef,
      }}
    >
      {children}
    </TransferIdentificationContext.Provider>
  );
};

export default TransferIdentificationProvider;
