import { createContext } from "react";

export interface TransferIdentificationContextValue {
  shouldOpen: boolean;
  setShouldOpen: React.Dispatch<React.SetStateAction<boolean>>;
  previous_screen_name: string;
  onDialogActionRef: React.MutableRefObject<
    ((actionType: "store" | "next" | "confirm") => void) | undefined
  >;
}

const TransferIdentificationContext =
  createContext<TransferIdentificationContextValue | null>(null);

export default TransferIdentificationContext;
