import { createAnatomy } from "@zag-js/anatomy";

export const anatomy = createAnatomy("snackbar").parts(
  "region",
  "root",
  "title",
  "description",
  "closeTrigger",
);
export const parts = anatomy.build();
