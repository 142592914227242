import { ariaAttr, dataAttr, MAX_Z_INDEX } from "@zag-js/dom-query";
import type { NormalizeProps, PropTypes } from "@zag-js/types";

import { parts } from "./snackbar.anatomy";
import { dom } from "./snackbar.dom";
import type { MachineApi, SnackbarOptions } from "./snackbar.types";
import type { Send, State } from "./snackbar.types";

export function connect<T extends PropTypes>(
  state: State,
  send: Send,
  normalize: NormalizeProps<T>,
): MachineApi<T> {
  const isVisible = state.hasTag("visible");
  const isDismissing = state.matches("dismissing");
  const pauseOnInteraction = state.context.pauseOnInteraction;

  const currentSnackbar = state.context.currentSnackbar;
  const offset = state.context.offset ?? "0px";

  const ariaLabel = state.context["aria-label"] || "Snackbar notifications"; // TODO: i18n

  return {
    isVisible,
    currentSnackbar,

    create(options: SnackbarOptions) {
      send({ type: "PUSH", options });
    },
    dismiss() {
      send({ type: "DISMISS" });
    },

    regionProps: normalize.element({
      ...parts.region.attrs,
      id: dom.getRegionId(state.context),
      tabIndex: -1,
      "aria-label": ariaLabel,
      "aria-live": "polite",
      role: "region",
      style: {
        pointerEvents: currentSnackbar ? undefined : "none",
        position: "fixed",
        zIndex: MAX_Z_INDEX,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        left: `calc(env(safe-area-inset-left, 0px))`,
        right: `calc(env(safe-area-inset-right, 0px))`,
        bottom: `calc(env(safe-area-inset-bottom, 0px) + ${offset})`,
      },
    }),

    rootProps: normalize.element({
      ...parts.root.attrs,
      id: dom.getRootId(state.context),
      "data-open": dataAttr(isVisible),
      role: "status",
      "aria-atomic": "true",
      "aria-labelledby": dom.getTitleId(state.context),
      "aria-describedby": dom.getDescriptionId(state.context),
      "aria-hidden": ariaAttr(isDismissing), // Hide toasts that are animating out so VoiceOver doesn't announce them.
      tabIndex: 0,
      style: {
        position: "relative",
        pointerEvents: "auto",
        "--remove-delay": `${currentSnackbar?.removeDelay ?? 0}ms`,
        "--duration": `${state.context.remaining}ms`,
      },
      onFocus() {
        if (pauseOnInteraction) {
          send("PAUSE");
        }
      },
      onBlur() {
        if (pauseOnInteraction) {
          send("RESUME");
        }
      },
      onPointerEnter() {
        if (pauseOnInteraction) {
          send("PAUSE");
        }
      },
      onPointerLeave() {
        if (pauseOnInteraction) {
          send("RESUME");
        }
      },
    }),

    titleProps: normalize.element({
      ...parts.title.attrs,
      id: dom.getTitleId(state.context),
    }),

    descriptionProps: normalize.element({
      ...parts.description.attrs,
      id: dom.getDescriptionId(state.context),
    }),

    closeTriggerProps: normalize.button({
      ...parts.closeTrigger.attrs,
      id: dom.getCloseTriggerId(state.context),
      type: "button",
      "aria-label": "Dismiss notification", // TODO: i18n
      onClick() {
        send("DISMISS");
      },
    }),
  };
}
