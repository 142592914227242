import type { SnackbarOptions } from "@daangn/sprout-machines-snackbar";
import * as snackbar from "@daangn/sprout-machines-snackbar";
import { normalizeProps } from "@zag-js/react";

export function useSnackbar<Payload>([state, send]: snackbar.StateMachine) {
  const api = snackbar.connect(state, send, normalizeProps);

  return api as SnackbarApi<Payload>;
}

export type SnackbarApi<Payload> = snackbar.Api & {
  currentSnackbar: SnackbarOptions<Payload> | null;
  create: (options: SnackbarOptions<Payload>) => void;
};
