import * as textfield from "@daangn/sprout-machines-text-field";
import { useEvent } from "@daangn/sprout-react-utils";
import { normalizeProps, useMachine } from "@zag-js/react";
import React from "react";

import type { TextFieldProps } from "./types";

export function useTextField(props: TextFieldProps): textfield.Api {
  const id = React.useId();
  const {
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    "aria-describedby": ariaDescribedBy,
    "aria-details": ariaDetails,
    "aria-errormessage": ariaErrorMessage,
    elementType,
    autoComplete,
    isDisabled,
    isInvalid,
    isReadOnly,
    isRequired,
    placeholder,
    maxLength,
    minLength,
    name,
    pattern,
    defaultValue,
    allowExceedLength,
    onChange,
    onBlur,
    onFocus,
    value,
    type,
    inputMode,
    autoFocus,
    ids,
  } = props;

  const onChangeEvent = useEvent(onChange, { sync: true });
  const onBlurEvent = useEvent(onBlur);
  const onFocusEvent = useEvent(onFocus);

  const context = {
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    "aria-describedby": ariaDescribedBy,
    "aria-details": ariaDetails,
    "aria-errormessage": ariaErrorMessage,
    elementType,
    autoComplete,
    isDisabled,
    isInvalid,
    isReadOnly,
    isRequired,
    placeholder,
    maxLength,
    minLength,
    name,
    pattern,
    defaultValue,
    onChange: onChangeEvent,
    onBlur: onBlurEvent,
    onFocus: onFocusEvent,
    value,
    type,
    id,
    ids,
    inputMode,
    autoFocus,
    allowExceedLength,
  };

  const [state, send] = useMachine(
    textfield.machine({
      ...context,
      value: defaultValue,
    }),
    {
      context,
    },
  );

  const api = textfield.connect(state, send, normalizeProps);

  return api;
}
