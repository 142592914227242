export type Environment = "Cupertino" | "Android" | "Web";

declare global {
  interface Window {
    AndroidFunction?: {
      messageHandler: (message: string) => void;
    };
    webkit?: {
      messageHandlers: {
        messageHandler: {
          postMessage(message: string): void;
        };
      };
    };
  }
}

export function getCurrentEnvironment(): Environment {
  if (typeof window === "undefined") {
    throw new Error(
      `KarrotBridge는 Node.js 환경을 지원하지 않아요.` +
        ` 혹시 SSR (Universal Rendering) 환경이시라면,` +
        ` KarrotBridge 객체 생성이 Node.js 환경에서 이루어지고있는지 확인해주세요.`
    );
  }

  if (window.AndroidFunction) {
    return "Android";
  }
  if (window.webkit?.messageHandlers?.messageHandler?.postMessage) {
    return "Cupertino";
  }

  return "Web";
}
