import type { KarrotBridge } from "@daangn/karrotbridge";

export type Context = {
  bridge: KarrotBridge;
  refresh?: Promise<string>;
};
export interface Store {
  context: Context;
}

export const getStore = () => {
  return window.__WEBVIEW_NETWORK__;
};

export const initStore = ({ bridge }: { bridge: KarrotBridge }): Store => {
  return (window.__WEBVIEW_NETWORK__ ??= {
    context: {
      bridge,
    },
  });
};
