import "@daangn/sprout-css/divider/index.css";

import type { SeparatorProps } from "@daangn/sprout-hooks-separator";
import { useSeparator } from "@daangn/sprout-hooks-separator";
import type { BaseStyleProps } from "@daangn/sprout-style-utils";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import type { Ref } from "react";
import React from "react";

export interface SeedDividerProps
  extends BaseStyleProps,
    Pick<SeparatorProps, "orientation"> {}

const Divider = (props: SeedDividerProps, ref: Ref<HTMLElement>) => {
  const { orientation = "horizontal", ...otherProps } = props;
  const Element = orientation === "horizontal" ? "hr" : "div";
  const { styleProps } = useStyleProps(otherProps);
  const { separatorProps } = useSeparator({
    ...props,
    elementType: Element,
  });

  return (
    <Element
      // @ts-ignore https://github.com/Microsoft/TypeScript/issues/28892
      ref={ref}
      {...styleProps}
      {...separatorProps}
      className={composeClassName(
        "seed-divider",
        `seed-divider-${orientation ?? "horizontal"}`,
        styleProps.className,
      )}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _Divider = React.forwardRef(Divider);
export { _Divider as Divider };
