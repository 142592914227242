import { BoxButton, TextButton } from "@daangn/sprout-components-button";
import * as React from "react";

import type { SeedAlertDialogSecondaryActionIntent } from "./types";

export const AlertDialogSecondaryAction = ({
  intent,
  onClick,
  children,
}: React.PropsWithChildren<{
  intent: SeedAlertDialogSecondaryActionIntent;
  onClick?: (e: React.MouseEvent) => void;
}>) => {
  switch (intent) {
    case "alternative":
      return (
        <BoxButton onClick={onClick} variant="primaryLow" size="medium">
          {children}
        </BoxButton>
      );

    case "neutral":
      return (
        <BoxButton onClick={onClick} variant="secondary" size="medium">
          {children}
        </BoxButton>
      );

    case "nonpreferred":
      return (
        <TextButton onClick={onClick} variant="secondary" size="small" bold>
          {children}
        </TextButton>
      );

    default:
      return <></>;
  }
};
