import type { BaseStyleProps } from "@daangn/sprout-style-utils";
import { useStyleProps } from "@daangn/sprout-style-utils";
import { classNames } from "@seed-design/design-token";
import type { Ref } from "react";
import * as React from "react";

import { useTabsContext } from "./context";

export interface SeedTabProps extends BaseStyleProps {
  value: string;

  children: React.ReactNode | React.ReactNode[];

  isDisabled?: boolean;

  /**
   * Tab에 dot 표시 여부입니다.
   * @default false
   */
  dot?: boolean;
}

const Tab = (props: SeedTabProps, ref: Ref<HTMLButtonElement>) => {
  const { children, dot, value, isDisabled, ...otherProps } = props;
  const { styleProps } = useStyleProps(otherProps);
  const { api } = useTabsContext();
  const { getTabProps } = api;
  const tabProps = getTabProps({ value, isDisabled });

  return (
    <button
      ref={ref}
      data-dot={dot ? "" : undefined}
      {...styleProps}
      className={classNames.$semantic.typography.label3Bold}
      {...tabProps}
    >
      {children}
    </button>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _Tab = React.forwardRef(Tab);
export { _Tab as Tab };
