import { useStyleProps } from "@daangn/sprout-style-utils";
import { classNames } from "@seed-design/design-token";
import type { Ref } from "react";
import * as React from "react";

import type { SeedMenuFabItemProps } from "../types";
import { useMenuFabContext } from "./context";

const MenuFabItem = (props: SeedMenuFabItemProps, ref: Ref<HTMLDivElement>) => {
  const { api } = useMenuFabContext();
  const { children, icon, id, ...otherProps } = props;
  const { styleProps } = useStyleProps(otherProps);

  return (
    <div ref={ref} {...styleProps} {...api.getItemProps({ id })}>
      {icon ? <div data-part="item-icon">{icon}</div> : null}
      {typeof children === "string" ? (
        <span className={classNames.$semantic.typography.label2Regular}>
          {children}
        </span>
      ) : (
        children
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _MenuFabItem = React.forwardRef(MenuFabItem);
export { _MenuFabItem as MenuFabItem };
