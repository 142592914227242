interface IconIdentificationProps {
  className?: string;
}

const IconIdentification: React.FC<IconIdentificationProps> = ({
  className,
}) => (
  <svg
    className={className}
    width="141"
    height="95"
    viewBox="0 0 141 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>icon-identification</title>
    <path
      d="M116.365 0.963989H8.17196C3.65871 0.963989 0 4.62272 0 9.13596V74.5803C0 79.0935 3.65871 82.7522 8.17196 82.7522H116.365C120.878 82.7522 124.536 79.0935 124.536 74.5803V9.13596C124.536 4.62272 120.878 0.963989 116.365 0.963989Z"
      fill="#BEE6FF"
    />
    <path
      d="M8.17196 0.963989H59.7789V82.7522H8.17196C3.65678 82.7522 0 79.0955 0 74.5803V9.13596C0 4.62078 3.65678 0.963989 8.17196 0.963989Z"
      fill="#4B9EFF"
    />
    <path
      clipRule="evenodd"
      d="M122.39 50.8026C121.377 50.3047 120.175 50.3047 119.145 50.8026L103.024 58.6999C101.788 59.3008 101.016 60.5368 101.016 61.8931V66.3568C101.016 73.6532 102.595 79.4044 105.754 84.0055C108.913 88.6065 113.514 91.9028 119.317 94.4436C120.244 94.8557 121.308 94.8557 122.235 94.4436C128.072 91.8341 132.673 88.2288 135.798 83.5248C138.923 78.8036 140.519 73.0866 140.519 66.3568V61.8931C140.519 60.5368 139.747 59.3008 138.51 58.6999L122.39 50.8026Z"
      fill="#1A9F74"
      fillRule="evenodd"
    />
    <path
      d="M73.291 23.3339H109.721"
      stroke="#4B9EFF"
      strokeMiterlimit="10"
      strokeWidth="2.60953"
    />
    <path
      d="M73.291 37.5146H109.721"
      stroke="#4B9EFF"
      strokeMiterlimit="10"
      strokeWidth="2.60953"
    />
    <path
      d="M73.291 51.7812H91.3002"
      stroke="#4B9EFF"
      strokeMiterlimit="10"
      strokeWidth="2.60953"
    />
    <path
      d="M112.467 70.5973L119.523 77.6533L130.493 66.683"
      stroke="white"
      strokeMiterlimit="10"
      strokeWidth="3.63961"
    />
    <path
      d="M51.3488 41.1371C51.3488 29.2756 41.7332 19.6599 29.8717 19.6599C18.0102 19.6599 8.39453 29.2756 8.39453 41.1371C8.39453 52.9986 18.0102 62.6142 29.8717 62.6142C41.7332 62.6142 51.3488 52.9986 51.3488 41.1371Z"
      fill="#EDFBFF"
    />
    <path
      d="M40.8431 47.4892C39.0576 44.3646 35.8644 42.1671 32.3964 41.4289C34.6626 40.4332 36.2592 38.1841 36.2592 35.5574C36.2592 32.0208 33.3922 29.1538 29.8727 29.1538C26.3533 29.1538 23.4862 32.0208 23.4862 35.5574C23.4862 38.2013 25.0657 40.4503 27.349 41.4289C23.8811 42.1843 20.6707 44.3646 18.9024 47.4892C18.0783 48.9656 18.5419 50.7339 19.984 51.6438C22.5077 53.2576 26.3018 53.9443 29.8727 53.9443C33.4437 53.9443 37.2378 53.2404 39.7615 51.6438C41.2036 50.7339 41.6843 48.9828 40.8431 47.4892Z"
      fill="#4B9EFF"
    />
  </svg>
);

export default IconIdentification;
