import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const IconCarRearSensorThin = (
  {
    size = 24,
    ...props
  }: SVGProps<SVGSVGElement> & {
    size?: number | string,
  },
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-seed-icon="true"
    data-seed-icon-version="0.6.1"
    width={size}
    height={size}
    ref={ref}
    {...props}
  >
    <g>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.2889 8.7373V9.81823C20.2889 13.191 17.5547 15.9252 14.1819 15.9252H9.81822C6.44541 15.9252 3.7112 13.191 3.7112 9.81823V8.74624C3.71085 8.7339 3.71085 8.72152 3.7112 8.7091V2.18186C3.7112 1.82151 4.00333 1.52939 4.36368 1.52939C4.72403 1.52939 5.01615 1.82151 5.01615 2.18186V7.60464C5.74606 7.05309 6.2129 6.32546 6.51184 5.64579C6.71501 5.18386 6.83501 4.75556 6.90383 4.44364C6.93812 4.28828 6.95934 4.16343 6.97176 4.07983C6.97774 4.03959 6.98166 4.00905 6.984 3.98939V2.18177C6.984 1.82142 7.27613 1.5293 7.63648 1.5293C7.99683 1.5293 8.28896 1.82142 8.28896 2.18177V4.0248C8.28896 4.0446 8.28805 4.06439 8.28625 4.08411L8.28614 4.08531L8.286 4.08682L8.28562 4.09081L8.28443 4.10255L8.28017 4.14062C8.27641 4.17231 8.27074 4.21644 8.26254 4.27161C8.24616 4.38186 8.21963 4.53673 8.17813 4.72481C8.0954 5.0998 7.95171 5.61334 7.70636 6.17117C7.37331 6.92839 6.84246 7.78866 6.00655 8.48471C7.90675 8.92392 9.94146 9.16583 12.0559 9.16583C14.1574 9.16583 16.1322 8.91571 17.9922 8.48359C17.157 7.78774 16.6266 6.92798 16.2937 6.17117C16.0484 5.61334 15.9047 5.0998 15.8219 4.72481C15.7804 4.53673 15.7539 4.38186 15.7375 4.27161C15.7293 4.21644 15.7236 4.17231 15.7199 4.14062L15.7156 4.10255L15.7144 4.09081L15.7141 4.08682L15.7138 4.08411C15.712 4.06439 15.7111 4.0446 15.7111 4.0248V2.18177C15.7111 1.82142 16.0032 1.5293 16.3636 1.5293C16.7239 1.5293 17.0161 1.82142 17.0161 2.18177V3.98939C17.0184 4.00905 17.0223 4.03959 17.0283 4.07983C17.0407 4.16343 17.0619 4.28828 17.0962 4.44364C17.1651 4.75556 17.2851 5.18386 17.4882 5.64579C17.7872 6.32547 18.254 7.05311 18.9839 7.60466V2.18186C18.9839 1.82151 19.2761 1.52939 19.6364 1.52939C19.9968 1.52939 20.2889 1.82151 20.2889 2.18186V8.71769L20.289 8.72675L20.2889 8.7373ZM12.0559 10.4708C14.528 10.4708 16.8326 10.1409 18.9839 9.58357V9.81823C18.9839 12.4703 16.834 14.6203 14.1819 14.6203H9.81822C7.16611 14.6203 5.01615 12.4703 5.01615 9.81823V9.5853C7.21044 10.1549 9.58338 10.4708 12.0559 10.4708Z"
          fill="currentColor"
        />
        <path
          d="M6.02733 19.9922C6.22398 20.2784 6.61536 20.351 6.90151 20.1543C8.35134 19.158 10.1066 18.5749 12 18.5749C13.8934 18.5749 15.6487 19.158 17.0985 20.1543C17.3846 20.351 17.776 20.2784 17.9727 19.9922C18.1693 19.7061 18.0967 19.3147 17.8106 19.1181C16.1579 17.9823 14.1556 17.3176 12 17.3176C9.84436 17.3176 7.84209 17.9823 6.1894 19.1181C5.90325 19.3147 5.83069 19.7061 6.02733 19.9922Z"
          fill="currentColor"
        />
        <path
          d="M9.10487 22.3206C8.82729 22.5292 8.43319 22.4732 8.22463 22.1957C8.01608 21.9181 8.07204 21.524 8.34962 21.3154C9.36634 20.5515 10.6312 20.0986 12 20.0986C13.3688 20.0986 14.6336 20.5515 15.6503 21.3154C15.9279 21.524 15.9839 21.9181 15.7753 22.1957C15.5668 22.4732 15.1727 22.5292 14.8951 22.3206C14.0886 21.7148 13.0871 21.356 12 21.356C10.9128 21.356 9.9113 21.7148 9.10487 22.3206Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
const ForwardRef = forwardRef(IconCarRearSensorThin);
export default ForwardRef;
