import {
  BridgeInvokeError,
  MissingValueError,
} from "@daangn/plantae-error-types";
import pRetry from "p-retry";

import { getStore } from "./store";

export default function refreshToken({ oldToken }: { oldToken: string }) {
  const { context } = getStore()!;

  return (context.refresh ??= pRetry(
    async () =>
      context.bridge
        .refreshAccessToken({
          accessToken: oldToken,
        })
        .catch((e) => {
          console.warn(
            "Retrying refreshAccessToken, visibility:",
            document.visibilityState
          );
          const err = new BridgeInvokeError(e.message);
          err.stack = e.stack;
          throw err;
        })
        .then((res) => {
          if (!res?.accessToken) {
            console.warn(
              "Failed to refresh access token, visibility:",
              document.visibilityState
            );
            throw new MissingValueError(
              `Access token does not exist in message: ${JSON.stringify(res)}`
            );
          }

          delete context.refresh;

          return res.accessToken;
        }),
    {
      retries: 3,
      minTimeout: 16,
    }
  ));
}
