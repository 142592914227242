import "@daangn/sprout-css/callout/index.css";

import type { ButtonElementType } from "@daangn/sprout-hooks-button";
import { useButton } from "@daangn/sprout-hooks-button";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import type { ElementType, Ref } from "react";
import React from "react";

import type { SeedActionableCalloutProps } from "./types";

const ChevronIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.31877 2.92247L12.4913 9.08747C12.7163 9.31247 12.7163 9.67997 12.4913 9.90497L6.31877 16.0775C6.09377 16.3025 5.72627 16.3025 5.50127 16.0775C5.27627 15.8525 5.27627 15.485 5.50127 15.26L11.2613 9.49997L5.50127 3.73997C5.27627 3.51497 5.27627 3.14747 5.50127 2.92247C5.72627 2.69747 6.09377 2.69747 6.31877 2.92247Z"
      fill="currentcolor"
    />
  </svg>
);

const ActionableCallout = <T extends ButtonElementType>(
  props: SeedActionableCalloutProps<T>,
  ref: Ref<HTMLDivElement>,
) => {
  const {
    elementType: Element = "button" as ElementType,
    variant = "normal",
    children,
    ...otherProps
  } = props;
  const { buttonProps } = useButton(props);
  const { styleProps } = useStyleProps(otherProps);

  return (
    <Element
      ref={ref}
      {...styleProps}
      {...buttonProps}
      data-variant={variant}
      data-part="root"
      className={composeClassName(
        "seed-callout",
        "seed-actionable-callout",
        styleProps.className,
      )}
    >
      <div data-part="content">{children}</div>
      <div data-part="action-indicator">{ChevronIcon}</div>
    </Element>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _ActionableCallout = React.forwardRef(ActionableCallout);
export { _ActionableCallout as ActionableCallout };
