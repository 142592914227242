import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const IconFilter02Thin = (
  {
    size = 24,
    ...props
  }: SVGProps<SVGSVGElement> & {
    size?: number | string,
  },
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-seed-icon="true"
    data-seed-icon-version="0.6.1"
    width={size}
    height={size}
    ref={ref}
    {...props}
  >
    <g>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5946 7.9998C12.3132 9.50815 10.9899 10.6499 9.3999 10.6499C7.80993 10.6499 6.48663 9.50815 6.20516 7.9998H2.79995C2.46858 7.9998 2.19995 7.73118 2.19995 7.3998C2.19995 7.06843 2.46858 6.7998 2.79995 6.7998H6.20519C6.48674 5.29156 7.81 4.1499 9.3999 4.1499C10.9898 4.1499 12.3131 5.29156 12.5946 6.7998H21.2C21.5313 6.7998 21.7999 7.06843 21.7999 7.3998C21.7999 7.73118 21.5313 7.9998 21.2 7.9998H12.5946ZM11.4246 7.95996C11.1795 8.84794 10.366 9.50005 9.40005 9.50005C8.43415 9.50005 7.62055 8.84794 7.37552 7.95996C7.3723 7.9483 7.36918 7.93661 7.36616 7.92487C7.323 7.75712 7.30005 7.58127 7.30005 7.40005C7.30005 7.20609 7.32634 7.01827 7.37557 6.83996C7.62067 5.95207 8.43421 5.30005 9.40005 5.30005C10.3659 5.30005 11.1794 5.95207 11.4245 6.83996C11.4278 6.85167 11.4309 6.86343 11.4339 6.87523C11.4771 7.04297 11.5 7.21883 11.5 7.40005C11.5 7.59394 11.4738 7.78171 11.4246 7.95996Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1947 17.2C18.9133 18.7083 17.59 19.8501 16 19.8501C14.41 19.8501 13.0867 18.7083 12.8053 17.2H2.79995C2.46858 17.2 2.19995 16.9314 2.19995 16.6C2.19995 16.2686 2.46858 16 2.79995 16H12.8053C13.0868 14.4918 14.4101 13.3501 16 13.3501C17.5899 13.3501 18.9132 14.4918 19.1947 16H21.2C21.5313 16 21.7999 16.2686 21.7999 16.6C21.7999 16.9314 21.5313 17.2 21.2 17.2H19.1947ZM18.0244 17.16C17.7794 18.0479 16.9658 18.7 15.9999 18.7C15.034 18.7 14.2204 18.0479 13.9754 17.16C13.9722 17.1483 13.969 17.1366 13.966 17.1249C13.9229 16.9571 13.8999 16.7813 13.8999 16.6C13.8999 16.4061 13.9262 16.2183 13.9754 16.04C14.2205 15.1521 15.0341 14.5 15.9999 14.5C16.9657 14.5 17.7793 15.1521 18.0244 16.04C18.0276 16.0517 18.0308 16.0634 18.0338 16.0752C18.0769 16.243 18.0999 16.4188 18.0999 16.6C18.0999 16.7939 18.0736 16.9817 18.0244 17.16Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
const ForwardRef = forwardRef(IconFilter02Thin);
export default ForwardRef;
