import {
  createScope,
  itemById,
  nextById,
  prevById,
  queryAll,
} from "@zag-js/dom-query";
import { first, last } from "@zag-js/utils";

import type { MachineContext as Ctx } from "./tabs.types";

export const dom = createScope({
  getRootId: (ctx: Ctx) => ctx.ids?.root ?? `tabs:${ctx.id}`,
  getTabListId: (ctx: Ctx) => ctx.ids?.tabList ?? `tabs:${ctx.id}:list`,
  getPanelId: (ctx: Ctx, id: string) =>
    ctx.ids?.panel ?? `tabs:${ctx.id}:panel-${id}`,
  getPanelGroupId: (ctx: Ctx) =>
    ctx.ids?.panelGroup ?? `tabs:${ctx.id}:panel-group`,
  getPanelGroupCameraId: (ctx: Ctx) =>
    ctx.ids?.panelGroup ?? `tabs:${ctx.id}:panel-group-camera`,
  getTabId: (ctx: Ctx, id: string) =>
    ctx.ids?.tab ?? `tabs:${ctx.id}:tab-${id}`,
  getIndicatorId: (ctx: Ctx) => `tabs:${ctx.id}:indicator`,

  getTablistEl: (ctx: Ctx) => dom.getById(ctx, dom.getTabListId(ctx)),
  getContentEl: (ctx: Ctx, id: string) =>
    dom.getById(ctx, dom.getPanelId(ctx, id)),
  getTabEl: (ctx: Ctx, id: string) => dom.getById(ctx, dom.getTabId(ctx, id)),
  getIndicatorEl: (ctx: Ctx) => dom.getById(ctx, dom.getIndicatorId(ctx)),
  getPanelGroupEl: (ctx: Ctx) => dom.getById(ctx, dom.getPanelGroupId(ctx)),

  getElements: (ctx: Ctx) => {
    const ownerId = CSS.escape(dom.getTabListId(ctx));
    const selector = `[role=tab][data-ownedby='${ownerId}']:not([disabled])`;
    return queryAll(dom.getTablistEl(ctx), selector);
  },

  getDisabledElements: (ctx: Ctx) => {
    const ownerId = CSS.escape(dom.getTabListId(ctx));
    const selector = `[role=tab][data-ownedby='${ownerId}'][aria-disabled=true]`;
    return queryAll(dom.getTablistEl(ctx), selector);
  },

  getFirstEl: (ctx: Ctx) => first(dom.getElements(ctx)),
  getLastEl: (ctx: Ctx) => last(dom.getElements(ctx)),
  getNextEl: (ctx: Ctx, id: string) =>
    nextById(dom.getElements(ctx), dom.getTabId(ctx, id), ctx.loop),
  getPrevEl: (ctx: Ctx, id: string) =>
    prevById(dom.getElements(ctx), dom.getTabId(ctx, id), ctx.loop),
  getActivePanelEl: (ctx: Ctx) => {
    if (!ctx.value) return;
    const id = dom.getPanelId(ctx, ctx.value);
    return dom.getById(ctx, id);
  },
  getNextPanelEl: (ctx: Ctx, id: string) =>
    nextById(dom.getElements(ctx), dom.getPanelId(ctx, id), ctx.loop),
  getPrevPanelEl: (ctx: Ctx, id: string) =>
    prevById(dom.getElements(ctx), dom.getPanelId(ctx, id), ctx.loop),

  getRectById: (ctx: Ctx, id: string) => {
    const empty = {
      offsetLeft: 0,
      offsetTop: 0,
      offsetWidth: 0,
      offsetHeight: 0,
    };

    const tab = itemById(dom.getElements(ctx), dom.getTabId(ctx, id)) ?? empty;

    return {
      left: `${tab.offsetLeft}px`,
      width: `${tab.offsetWidth}px`,
    };
  },
});
