import * as radioGroup from "@daangn/sprout-machines-radio-group";
import { normalizeProps } from "@zag-js/react";

export function useRadioGroup([state, send]: readonly [
  radioGroup.State,
  radioGroup.Send,
]): radioGroup.RadioGroupApi {
  const api = radioGroup.connectGroup(state, send, normalizeProps);

  return api;
}
