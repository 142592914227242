import type { ComponentPropsWithoutRef } from "react";

const propRe = /^(data-.*)$/;

export function filterDataProps(
  props: ComponentPropsWithoutRef<any>,
): ComponentPropsWithoutRef<any> {
  const filteredProps = {};

  for (const prop in props) {
    if (
      Object.prototype.hasOwnProperty.call(props, prop) &&
      propRe.test(prop)
    ) {
      filteredProps[prop] = props[prop];
    }
  }

  return filteredProps;
}
