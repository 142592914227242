import { createScope } from "@zag-js/dom-query";

import type { MachineContext as Ctx } from "./snackbar.types";

export const dom = createScope({
  getRegionId: (ctx: Ctx) => `snackbar-region:${ctx.id}`,
  getRootId: (ctx: Ctx) => `snackbar:${ctx.id}`,
  getTitleId: (ctx: Ctx) => `snackbar:${ctx.id}:title`,
  getDescriptionId: (ctx: Ctx) => `snackbar:${ctx.id}:description`,
  getCloseTriggerId: (ctx: Ctx) => `snackbar:${ctx.id}:close`,
});
