import "@daangn/sprout-css/checkbox/index.css";

import { useCheckbox } from "@daangn/sprout-hooks-checkbox";
import { useFocusVisible } from "@daangn/sprout-hooks-interactions";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import { classNames } from "@seed-design/design-token";
import { IconCheckFill, IconSubtractionFill } from "@seed-design/icon";
import type { Ref } from "react";
import React from "react";

import type { SeedCheckboxProps } from "./types";

const getTypographyClassName = ({
  size,
  bold,
}: {
  size: Required<SeedCheckboxProps>["size"];
  bold: Required<SeedCheckboxProps>["bold"];
}) => {
  switch (size) {
    case "small":
      return bold
        ? classNames.$semantic.typography.caption1Bold
        : classNames.$semantic.typography.caption1Regular;
    case "medium":
      return bold
        ? classNames.$semantic.typography.label3Bold
        : classNames.$semantic.typography.label3Regular;
    case "large":
      return bold
        ? classNames.$semantic.typography.label2Bold
        : classNames.$semantic.typography.label2Regular;
  }
};

const Checkbox = (props: SeedCheckboxProps, ref: Ref<HTMLLabelElement>) => {
  const {
    size = "medium",
    bold = false,
    shape = "square",
    children,
    ...otherProps
  } = props;
  const {
    rootProps,
    controlProps,
    labelProps,
    hiddenInputProps,
    isIndeterminate,
    isSelected,
  } = useCheckbox(props);
  const { focusVisibleProps } = useFocusVisible();
  const { styleProps } = useStyleProps(otherProps);

  return (
    <label
      ref={ref}
      {...styleProps}
      {...focusVisibleProps}
      {...rootProps}
      data-size={size}
      data-bold={bold}
      data-shape={shape}
      data-standalone={children == null ? true : undefined}
      className={composeClassName(
        "seed-checkbox",
        styleProps.className,
        getTypographyClassName({ size, bold }),
      )}
    >
      <div {...controlProps}>
        {isIndeterminate ? (
          <IconSubtractionFill data-part="icon" />
        ) : (
          (shape === "ghost" || isSelected) && (
            <IconCheckFill data-part="icon" />
          )
        )}
      </div>
      {children && <span {...labelProps}>{children}</span>}
      <input {...hiddenInputProps} />
    </label>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _Checkbox = React.forwardRef(Checkbox);
export { _Checkbox as Checkbox };
