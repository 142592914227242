import "@daangn/sprout-css/avatar/index.css";

import { useAvatar } from "@daangn/sprout-hooks-avatar";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import { Slot } from "@radix-ui/react-slot";
import type { Ref } from "react";
import * as React from "react";

import { useAvatarGroupProps } from "./context";
import type { SeedAvatarProps } from "./types";

const Avatar = (props: SeedAvatarProps, ref: Ref<HTMLSpanElement>) => {
  const {
    children,
    size = "large",
    src,
    alt,
    fallback,
    ...otherProps
  } = useAvatarGroupProps(props);
  const { api } = useAvatar(otherProps);
  const { styleProps } = useStyleProps(otherProps);

  const FallbackElement = typeof fallback === "string" ? "span" : Slot;

  return (
    <span
      ref={ref}
      data-size={size}
      {...styleProps}
      {...api.rootProps}
      className={composeClassName("seed-avatar")}
    >
      <img
        src={src}
        alt={alt}
        {...api.imageProps}
        className={composeClassName("seed-avatar-image")}
      />
      {api.isFallback && (
        <FallbackElement {...api.fallbackProps}>{fallback}</FallbackElement>
      )}
      {children}
    </span>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _Avatar = React.forwardRef(Avatar);
export { _Avatar as Avatar };
