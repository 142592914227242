import { dataAttr } from "@zag-js/dom-query";
import type { NormalizeProps, PropTypes } from "@zag-js/types";
import { visuallyHiddenStyle } from "@zag-js/visually-hidden";

import { parts } from "./avatar.anatomy";
import { dom } from "./avatar.dom";
import type { MachineApi, Send, State } from "./avatar.types";

export function connect<T extends PropTypes>(
  state: State,
  send: Send,
  normalize: NormalizeProps<T>,
): MachineApi<T> {
  const isLoaded = state.value === "loaded";
  const isFallback = state.value === "fallback" || state.value === "error";

  const imageId = dom.getImageId(state.context);
  const fallbackId = dom.getFallbackId(state.context);

  return {
    isLoaded,
    isFallback,
    rootProps: normalize.element({
      ...parts.root.attrs,
      "data-loaded": dataAttr(isLoaded),
      "data-fallback": dataAttr(isFallback),
    }),
    imageProps: normalize.img({
      ...parts.image.attrs,
      id: imageId,
      onLoad() {
        send("IMAGE_LOAD");
      },
      onError() {
        send("IMAGE_ERROR");
      },
      style: isLoaded ? {} : visuallyHiddenStyle,
    }),
    fallbackProps: normalize.img({
      ...parts.fallback.attrs,
      id: fallbackId,
      role: "img",
    }),
  };
}
