import 'src/basicUIPlugin.css.ts.vanilla.css?source=#H4sIAAAAAAAAA+3X0UrDMBQG4Ps9RcAbBVPaah1k+CQikjan7dnWnJqczqHs3WXD6c1m7SrIMNCLXvRPf8jJB4mekuc7M0/nsXibCCGlZ10syiW9yHbZVWhlrj0WskOZ62JROeqskQUtySlxUZblrCdlsDmQdFWuL+Nr8fFESXbVtxA7bT0ykpWmc3r7okTs+2IFNW3HYE7N67aVuXYyJ2fAHesfT3v7H1rol0p5fAUlknb901wNWNWsxE2UOWiGpcZ2btCe+H8syH6OXpqkSXo7PDq2PsOaTyzxFR297ycdxWPpsW1oBW77gRI1GgN2yCR4XYLUDrRE64ElU6tE3D/JOTFTI30NwPtj4LTBziuR7GZqM4n2tiXBtjO3LY6y4bql0fRveRtYIPgWfBvim3JE/GA06+9oqaGB+6JrwTFaegwUBgoDhYHCf0uhtsYRmgDhuUMY7rsBwYDgoftutrOtJbQMTsIKLHslLFmYTTbvJq/pt/ITAAA=';
import 'src/styles/f.css.ts.vanilla.css?source=LmRodGh4cTAgewogIHdpZHRoOiAxMDAlOwp9Ci5kaHRoeHExIHsKICBoZWlnaHQ6IDEwMCU7Cn0KLmRodGh4cTIgewogIHRvcDogMDsKfQouZGh0aHhxMyB7CiAgbGVmdDogMDsKfQouZGh0aHhxNCB7CiAgcG9zaXRpb246IGFic29sdXRlOwp9Ci5kaHRoeHE1IHsKICBwb3NpdGlvbjogcmVsYXRpdmU7Cn0KLmRodGh4cTcgewogIGRpc3BsYXk6IGZsZXg7Cn0KLmRodGh4cTggewogIGZsZXg6IDE7Cn0KLmRodGh4cTkgewogIGFsaWduLWl0ZW1zOiBjZW50ZXI7Cn0KLmRodGh4cWEgewogIGFsaWduLWl0ZW1zOiBmbGV4LWVuZDsKfQouZGh0aHhxYiB7CiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7Cn0KLmRodGh4cWMgewogIG92ZXJmbG93OiBoaWRkZW47Cn0KLmRodGh4cWQgewogIHdoaXRlLXNwYWNlOiBub3dyYXA7Cn0KLmRodGh4cWUgewogIHRleHQtYWxpZ246IGNlbnRlcjsKfQouZGh0aHhxZiB7CiAgY3Vyc29yOiBwb2ludGVyOwogIC13ZWJraXQtdGFwLWhpZ2hsaWdodC1jb2xvcjogdHJhbnNwYXJlbnQ7Cn0KLmRodGh4cWcgewogIGJveC1zaXppbmc6IGJvcmRlci1ib3g7Cn0KLmRodGh4cWggewogIGJveC1zaXppbmc6IGNvbnRlbnQtYm94Owp9Ci5kaHRoeHFpIHsKICBhcHBlYXJhbmNlOiBub25lOwogIGJvcmRlcjogMDsKICBwYWRkaW5nOiAwOwogIGJhY2tncm91bmQ6IG5vbmU7Cn0=';
import 'src/components/AppScreen.css.ts.vanilla.css?source=#H4sIAAAAAAAAA9WUwY6bMBCG73mKuVQCFUcm2d22zqVvUhnbISbGpsYkm1b77hUGkkASSKK9lBPSeD7/M/N75r9irtYvy5c3+DsDSCjbptZUmiNmlLEEdtQGCJWOsu1amT0qVJVKjRJaSoYqibjM0TArXM0+ZvOO/O1J8jj1u6c6S3UpnTSaNP9rY/OW3J3EYQSmoEy6w0Wkh2QnZI1piYo6seRBjPGXCHAEgyTpk1o+AbyaAfxBUnPxTgbXxWEd3EulENtQnQrS5bXE3288W2QYBvCNkOnGEYixFfmq10sCSmpBLUot5VJoFzw1rwhsmtAANwXWNfoiZ9B+R0E/zrRFl2F6Fu6ivfbE17HJOJZNYXFvJFkT3AnrO3AgUDJrlKpbh/Yi2UqHjtEmJHVKwJmKbUbmt7ic39EsfQGEdPc0+IRar4nLslD0QEAbLXpDj2Eg/z4bHnvlu3WBOhtXFk2cPZtddjo6IgX3n8OFebMrL+M2ypt7rCz8QFn4nrLOTXlvkQ1o+ym7J4KCci51ipwpJrchLQqUUIuaZYBOlyNeWVr/eHeeIac3bJ8Z3jKkmnBB68mbRsjH8l+vr1Xtc/aSuw2BeL54bVffjae57Geb5sanenDat4wq5h8coEcxXs7PsioKY10JQW8szOjSUe2Ckq4FolZQJHUpXB0NQy99YLXBXL2wBxXBV8jp+91ZudTomrxoQr1v4MdI8ULv/s+6bwrvSv4HJarFPUsJAAA=';
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var allTransitions = '_1dlf4348';
export var appScreen = _7a468({defaultClassName:'dhthxq4 dhthxq0 dhthxq1 dhthxq2 dhthxq3 dhthxqc',variantClassNames:{transitionState:{'enter-active':'_1dlf4349','enter-done':'_1dlf434a','exit-active':'_1dlf434b','exit-done':'_1dlf434c'}},defaultVariants:{},compoundVariants:[]});
export var background = '_1dlf4347';
export var dim = '_1dlf434i dhthxq4 dhthxq0 dhthxq1 dhthxq2 dhthxq3 _1dlf4346 _1dlf4348';
export var edge = _7a468({defaultClassName:'_1dlf434n dhthxq4 dhthxq2 dhthxq3 dhthxq1',variantClassNames:{hasAppBar:{true:'_1dlf434o'}},defaultVariants:{},compoundVariants:[]});
export var enterActive = '_1dlf4349';
export var enterDone = '_1dlf434a';
export var exitActive = '_1dlf434b';
export var exitDone = '_1dlf434c';
export var paper = _7a468({defaultClassName:'_1dlf434j dhthxq4 dhthxq0 dhthxq1 dhthxq2 dhthxq3 _1dlf4347 _1dlf4348',variantClassNames:{hasAppBar:{true:'_1dlf434k dhthxqg'},modalPresentationStyle:{fullScreen:'_1dlf434l'},activityEnterStyle:{slideInLeft:'_1dlf434m'}},defaultVariants:{},compoundVariants:[]});
export var vars = {transitionDuration:'var(--_1dlf4340)',zIndexes:{dim:'var(--_1dlf4341)',paper:'var(--_1dlf4342)',edge:'var(--_1dlf4343)',appBar:'var(--_1dlf4344)'},appBar:{center:{mainWidth:'var(--_1dlf4345)'}}};