import { mergeProps } from "@daangn/sprout-react-utils";
import type { BaseStyleProps } from "@daangn/sprout-style-utils";
import { useStyleProps } from "@daangn/sprout-style-utils";
import type { Ref } from "react";
import * as React from "react";

import { useTabsContext } from "./context";
import type { SeedTabPanelProps } from "./TabPanel";

export interface SeedTabPanelGroupProps extends BaseStyleProps {
  children:
    | React.ReactElement<SeedTabPanelProps>
    | React.ReactElement<SeedTabPanelProps>[];
}

const TabPanelGroup = (
  props: SeedTabPanelGroupProps,
  ref: Ref<HTMLDivElement>,
) => {
  const { children, ...otherProps } = props;
  const { styleProps } = useStyleProps(otherProps);

  const { api } = useTabsContext();
  const { panelGroupProps, panelGroupCameraProps } = api;

  return (
    <div ref={ref} {...mergeProps(styleProps, panelGroupProps)}>
      <div {...panelGroupCameraProps}>{children}</div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _TabPanelGroup = React.forwardRef(TabPanelGroup);
export { _TabPanelGroup as TabPanelGroup };
