interface LazyOptions {
  enabled?: boolean;
  isSelected?: boolean;
  wasSelected?: boolean;
  mode?: "unmount" | "keepMounted";
}

export function lazyDisclosure(options: LazyOptions) {
  const { enabled, isSelected, wasSelected, mode = "unmount" } = options;

  // if not lazy, always render the disclosure's content
  if (!enabled) return true;

  // if the disclosure is selected, render the disclosure's content
  if (isSelected) return true;

  // if the disclosure was selected but not active, keep its content active
  if (mode === "keepMounted" && wasSelected) return true;

  return false;
}
