/* eslint-disable @typescript-eslint/no-shadow */
import { createMachine } from "@zag-js/core";
import { compact } from "@zag-js/utils";

import type {
  MachineContext,
  MachineState,
  UserDefinedContext,
} from "./toggle.types";

export function machine(userContext: UserDefinedContext) {
  const ctx = compact(userContext);
  return createMachine<MachineContext, MachineState>(
    {
      id: "toggle",
      initial: ctx.defaultSelected ? "selected" : "unselected",

      context: {
        disabled: false,
        ...ctx,
      },

      computed: {
        isInteractive: (ctx) => !ctx.disabled,
      },

      on: {
        SET_STATE: [
          {
            guard: "shouldCheck",
            target: "selected",
          },
          {
            target: "unselected",
          },
        ],
        SET_HOVERED: {
          actions: "setHovered",
        },
      },

      states: {
        selected: {
          entry: ["invokeOnChange"],
          on: {
            TOGGLE: {
              target: "unselected",
              guard: "isInteractive",
            },
          },
        },
        unselected: {
          entry: ["invokeOnChange"],
          on: {
            TOGGLE: {
              target: "selected",
              guard: "isInteractive",
            },
          },
        },
      },
    },
    {
      guards: {
        shouldCheck: (_, evt) => evt.selected,
        isInteractive: (ctx) => ctx.isInteractive,
        isToggleEvent: (_ctx, evt) => evt.type === "TOGGLE",
      },

      actions: {
        invokeOnChange(ctx, evt, { state }) {
          if (evt.type !== "TOGGLE") return;
          const isSelected = state.matches("selected");
          ctx.onChange?.(isSelected);
        },
      },
    },
  );
}
