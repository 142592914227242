import { classNames } from "@seed-design/design-token";
import React from "react";

import type { SeedSliderMarker } from "./types";
import type { SharedCtx } from "./util";
import { getMarkerProps } from "./util";

export const SliderMarker = (props: {
  ctx: SharedCtx;
  marker: SeedSliderMarker;
}) => {
  const { ctx, marker } = props;
  const { align = "center", value, label } = marker;

  return (
    <div
      className={classNames.$semantic.typography.caption1Regular}
      data-align={align}
      {...getMarkerProps(ctx, value)}
    >
      {label}
    </div>
  );
};
