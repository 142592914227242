import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const IconCarLdwsThin = (
  {
    size = 24,
    ...props
  }: SVGProps<SVGSVGElement> & {
    size?: number | string,
  },
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-seed-icon="true"
    data-seed-icon-version="0.6.1"
    width={size}
    height={size}
    ref={ref}
    {...props}
  >
    <g>
      <g>
        <path
          d="M16.1443 3.74913C16.4837 3.62793 16.857 3.80478 16.9782 4.14414L22.4328 19.4169C22.554 19.7562 22.3771 20.1296 22.0378 20.2508C21.6984 20.372 21.3251 20.1951 21.2039 19.8558L15.7493 4.58304C15.6281 4.24368 15.805 3.87033 16.1443 3.74913Z"
          fill="currentColor"
        />
        <path
          d="M7.85598 3.74914C8.19534 3.87036 8.37216 4.24373 8.25094 4.58308L2.7964 19.8528C2.67518 20.1922 2.30181 20.369 1.96246 20.2478C1.62311 20.1265 1.44628 19.7532 1.5675 19.4138L7.02205 4.1441C7.14327 3.80475 7.51663 3.62792 7.85598 3.74914Z"
          fill="currentColor"
        />
        <path
          d="M12.6524 5.45432C12.6524 5.09397 12.3603 4.80185 12 4.80185C11.6396 4.80185 11.3475 5.09397 11.3475 5.45432V6.54523C11.3475 6.90558 11.6396 7.19771 12 7.19771C12.3603 7.19771 12.6524 6.90558 12.6524 6.54523V5.45432Z"
          fill="currentColor"
        />
        <path
          d="M12 9.16548C12.3603 9.16548 12.6524 9.45761 12.6524 9.81796V11.9998C12.6524 12.3601 12.3603 12.6523 12 12.6523C11.6396 12.6523 11.3475 12.3601 11.3475 11.9998V9.81796C11.3475 9.45761 11.6396 9.16548 12 9.16548Z"
          fill="currentColor"
        />
        <path
          d="M12.6524 15.2725C12.6524 14.9122 12.3603 14.62 12 14.62C11.6396 14.62 11.3475 14.9122 11.3475 15.2725V19.6361C11.3475 19.9965 11.6396 20.2886 12 20.2886C12.3603 20.2886 12.6524 19.9965 12.6524 19.6361V15.2725Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
const ForwardRef = forwardRef(IconCarLdwsThin);
export default ForwardRef;
