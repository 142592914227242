import type { EventPoint, Rect } from "./button.types";

export function getPointClientRect(point: EventPoint): Rect {
  let offsetX = point.width ? point.width / 2 : point.radiusX || 0;
  let offsetY = point.height ? point.height / 2 : point.radiusY || 0;

  return {
    top: point.clientY - offsetY,
    right: point.clientX + offsetX,
    bottom: point.clientY + offsetY,
    left: point.clientX - offsetX,
  };
}

export function areRectanglesOverlapping(a: Rect, b: Rect) {
  // check if they cannot overlap on x axis
  if (a.left > b.right || b.left > a.right) {
    return false;
  }
  // check if they cannot overlap on y axis
  if (a.top > b.bottom || b.top > a.bottom) {
    return false;
  }
  return true;
}

export function isOverTarget(point: EventPoint, target: Element | null) {
  if (!target) return;
  let rect = target.getBoundingClientRect();
  let pointRect = getPointClientRect(point);
  return areRectanglesOverlapping(rect, pointRect);
}
