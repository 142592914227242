import { protocols } from "./createScheme";
import fulfillVersion from "./fulfillVersion";
import { featureVersions } from "./supportsFeature";

// Crash on:
// Android AND
// 23.16.0 <= appVersion < 23.18.0 AND
// Other non-karrot app schemes
export default function isCrashPossible(
  feature: keyof typeof featureVersions,
  {
    platform,
    appVersion,
    url,
  }: {
    platform: string;
    appVersion: string;
    url: string;
  },
) {
  return (
    feature === "externalRouting" &&
    platform === "Android" &&
    !fulfillVersion(appVersion, "23.18.0") &&
    !(
      url.startsWith("http://") ||
      url.startsWith("https://") ||
      Object.values(protocols).some((protocol) =>
        url.startsWith(`${protocol}://`),
      )
    )
  );
}
