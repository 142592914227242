import "@daangn/sprout-css/button/index.css";

//@ts-ignore - yarn workspace exports map is not working correctly
import { InternalSpinner } from "@daangn/sprout-components-spinner/internal";
import type { ButtonElementType } from "@daangn/sprout-hooks-button";
import { useToggleButton } from "@daangn/sprout-hooks-button";
import { useFocusVisible } from "@daangn/sprout-hooks-interactions";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import { classNames } from "@seed-design/design-token";
import type { ElementType, Ref } from "react";
import * as React from "react";
import { match } from "ts-pattern";

import { spinnerVariant } from "./spinner";
import type { SeedCapsuleToggleButtonProps } from "./types";

const typographyClassNameMap: Record<
  Required<SeedCapsuleToggleButtonProps>["size"],
  string
> = {
  small: classNames.$semantic.typography.label3Bold,
  xsmall: classNames.$semantic.typography.label4Bold,
};

const getSpinnerSize = (size: Required<SeedCapsuleToggleButtonProps>["size"]) =>
  match(size)
    .with("small", () => 16)
    .with("xsmall", () => 12)
    .exhaustive();

const getSpinnerVariant = (isSelected: boolean) =>
  isSelected ? spinnerVariant.primary : spinnerVariant.black;

const getVariant = (isSelected: boolean) =>
  isSelected ? "primaryLow" : "secondary";

const CapsuleToggleButton = <T extends ButtonElementType>(
  props: SeedCapsuleToggleButtonProps<T>,
  ref: Ref<HTMLElement>,
) => {
  const {
    elementType: Element = "button" as ElementType,
    size = "small",
    children,
    isLoading,
    ...otherProps
  } = props;
  const { buttonProps, isSelected } = useToggleButton(props);
  const { focusVisibleProps } = useFocusVisible();
  const { styleProps } = useStyleProps(otherProps);

  return (
    <Element
      ref={ref}
      {...styleProps}
      {...focusVisibleProps}
      {...buttonProps}
      data-size={size}
      data-variant={getVariant(isSelected)}
      className={composeClassName(
        "seed-capsule-button",
        typographyClassNameMap[size],
        styleProps.className,
      )}
    >
      {isLoading ? (
        <InternalSpinner
          size={getSpinnerSize(size)}
          {...getSpinnerVariant(isSelected)}
        />
      ) : typeof children === "string" ? (
        <span>{children}</span>
      ) : (
        children
      )}
    </Element>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _CapsuleToggleButton = React.forwardRef(CapsuleToggleButton);
export { _CapsuleToggleButton as CapsuleToggleButton };
