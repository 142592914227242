/**
 * Auth Token이 만료된 경우
 */
export class AuthTokenExpiredError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "AuthTokenExpiredError";
  }
}

/**
 * Auth Token 인증 도중, 서버에서 에러가 발생한 경우
 */
export class InternalServerError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "InternalServerError";
  }
}

/**
 * 갱신한 Access Token으로 요청을 보냈으나 실패한 경우
 */
export class RetryError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "RetryError";
  }
}

/**
 * 요청에 Authorization 헤더가 존재하지 않으나, 서버에서 401을 반환한 경우
 */
export class UnauthorizedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UnauthorizedError";
  }
}
