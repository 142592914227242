import "@daangn/sprout-components-dialog/index.css";
import { AlertDialog, DialogContainer } from "@daangn/sprout-components-dialog";
import { openExternalBrowser } from "@daangn/webview-link-router";
import { getAppInfo, getOsTypeLowerCase } from "utils";
import useTransferIdentification from "../hooks/use-transfer-identification";

const isAvailableToMoveStore = (): boolean => {
  const appInfo = getAppInfo();
  if (!appInfo) return false;

  const osTypeLowerCase = getOsTypeLowerCase();

  return (
    (osTypeLowerCase === "ios" &&
      Number.parseInt(appInfo.versionCode, 10) >= 230900) ||
    (osTypeLowerCase === "android" &&
      Number.parseInt(appInfo.versionCode, 10) >= 231800)
  );
};

const Dialog: React.FC = () => {
  const { shouldOpen, setShouldOpen, onDialogActionRef } =
    useTransferIdentification();
  const osTypeLowerCase = getOsTypeLowerCase();

  return (
    <DialogContainer
      onOutsideClick={() => {
        setShouldOpen(false);
      }}
    >
      {shouldOpen &&
        (isAvailableToMoveStore() ? (
          <AlertDialog
            description="스토어에 앱을 업데이트 해주세요"
            onPrimaryAction={() => {
              if (osTypeLowerCase === "ios") {
                openExternalBrowser({
                  url: "https://apps.apple.com/app/id1018769995",
                  stage: "production",
                });
              }

              if (osTypeLowerCase === "android") {
                window.location.href =
                  "https://play.google.com/store/apps/details?id=com.towneers.www";
                openExternalBrowser({
                  url: "https://play.google.com/store/apps/details?id=com.towneers.www",
                  stage: "production",
                });
              }

              setShouldOpen(false);

              if (onDialogActionRef.current) {
                onDialogActionRef.current("store");
              }
            }}
            onSecondaryAction={() => {
              setShouldOpen(false);

              if (onDialogActionRef.current) {
                onDialogActionRef.current("next");
              }
            }}
            primaryActionLabel="스토어 가기"
            secondaryActionIntent="neutral"
            secondaryActionLabel="다음에 하기"
            title="새로운 버전이 필요해요"
            maxWidth="80%"
            UNSAFE_style={{ whiteSpace: "pre-line" }}
          />
        ) : (
          <AlertDialog
            description="스토어에서 앱을 업데이트 해주세요"
            onPrimaryAction={() => {
              setShouldOpen(false);

              if (onDialogActionRef.current) {
                onDialogActionRef.current("confirm");
              }
            }}
            primaryActionLabel="확인"
            title="새로운 버전이 필요해요"
            maxWidth="80%"
            UNSAFE_style={{ whiteSpace: "pre-line" }}
          />
        ))}
    </DialogContainer>
  );
};

export default Dialog;
