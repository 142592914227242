export interface ProgressBarProps {
  /**
   * The current value (controlled).
   * @default 0
   */
  value?: number;
  /**
   * The smallest value allowed for the input.
   * @default 0
   */
  minValue?: number;
  /**
   * The largest value allowed for the input.
   * @default 100
   */
  maxValue?: number;
  /**
   * Whether presentation is indeterminate when progress isn't known.
   */
  isIndeterminate?: boolean;

  /**
   * Defines a string value that labels the current element.
   */
  "aria-label"?: string;
  /**
   * Identifies the element (or elements) that labels the current element.
   */
  "aria-labelledby"?: string;
}

export function useProgressBar(props: ProgressBarProps) {
  let { value = 0, minValue = 0, maxValue = 100, isIndeterminate } = props;

  return {
    progressBarProps: {
      role: "progressbar",
      "aria-valuenow": isIndeterminate ? undefined : value,
      "aria-valuemin": minValue,
      "aria-valuemax": maxValue,
      "aria-label": props["aria-label"],
      "aria-labelledby": props["aria-labelledby"],
    },
  };
}
