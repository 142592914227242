import { dataAttr } from "@zag-js/dom-query";
import type { NormalizeProps, PropTypes } from "@zag-js/types";
import { visuallyHiddenStyle } from "@zag-js/visually-hidden";

import { parts } from "./checkbox.anatomy";
import { dom } from "./checkbox.dom";
import type { MachineApi, Send, State } from "./checkbox.types";

export function connect<T extends PropTypes>(
  state: State,
  send: Send,
  normalize: NormalizeProps<T>,
): MachineApi<T> {
  const isDisabled = state.context.disabled;
  const isFocused = !isDisabled && state.context.focused;
  const isSelected = state.context.selected;
  const isIndeterminate = state.context.indeterminate;

  const ariaLabel = state.context["aria-label"];
  const ariaLabelledBy =
    state.context["aria-labelledby"] ?? dom.getLabelId(state.context);
  const ariaDescribedBy = state.context["aria-describedby"];
  const ariaDetails = state.context["aria-details"];
  const ariaErrorMessage = state.context["aria-errormessage"];

  const dataAttrs = {
    "data-pressed": dataAttr(state.context.pressed),
    "data-focus": dataAttr(isFocused),
    "data-hover": dataAttr(state.context.hovered),
    "data-disabled": dataAttr(isDisabled),
    "data-selected": dataAttr(isSelected),
    "data-indeterminate": dataAttr(isIndeterminate),
    "data-invalid": dataAttr(state.context.invalid),
  };

  return {
    isSelected,
    isDisabled,
    isIndeterminate,
    isFocused,

    setSelected(selected: boolean) {
      send({ type: "SELECTED.SET", selected });
    },

    toggleSelected() {
      send({ type: "SELECTED.TOGGLE", selected: isSelected });
    },

    rootProps: normalize.label({
      ...parts.root.attrs,
      ...dataAttrs,
      id: dom.getRootId(state.context),
      htmlFor: dom.getHiddenInputId(state.context),
      onPointerMove() {
        if (isDisabled) return;
        send({ type: "CONTEXT.SET", context: { hovered: true } });
      },
      onPointerLeave() {
        if (isDisabled) return;
        send({ type: "CONTEXT.SET", context: { hovered: false } });
      },
      onPointerDown(event) {
        if (isDisabled) return;
        // On pointerdown, the input blurs and returns focus to the `body`,
        // we need to prevent this.
        if (isFocused && event.pointerType === "mouse") {
          event.preventDefault();
        }
        send({ type: "CONTEXT.SET", context: { pressed: true } });
      },
      onPointerUp() {
        if (isDisabled) return;
        send({ type: "CONTEXT.SET", context: { pressed: false } });
      },
      onClick(event) {
        if (event.target === dom.getHiddenInputEl(state.context)) {
          event.stopPropagation();
        }
      },
    }),

    labelProps: normalize.element({
      ...parts.label.attrs,
      ...dataAttrs,
      id: dom.getLabelId(state.context),
    }),

    controlProps: normalize.element({
      ...parts.control.attrs,
      ...dataAttrs,
      id: dom.getControlId(state.context),
      "aria-hidden": true,
    }),

    hiddenInputProps: normalize.input({
      id: dom.getHiddenInputId(state.context),
      type: "checkbox",
      required: state.context.required,
      defaultChecked: isSelected,
      disabled: isDisabled,
      "data-disabled": dataAttr(isDisabled),
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledBy,
      "aria-describedby": ariaDescribedBy,
      "aria-details": ariaDetails,
      "aria-invalid": state.context.invalid,
      "aria-errormessage": ariaErrorMessage,
      name: state.context.name,
      form: state.context.form,
      value: state.context.value,
      style: visuallyHiddenStyle,
      onChange(event) {
        const selected = event.currentTarget.checked;
        send({ type: "SELECTED.SET", selected });
      },
      onBlur() {
        send({ type: "CONTEXT.SET", context: { focused: false } });
      },
      onFocus() {
        send({ type: "CONTEXT.SET", context: { focused: true } });
      },
      onKeyDown(event) {
        if (event.key === " ") {
          send({ type: "CONTEXT.SET", context: { pressed: true } });
        }
      },
      onKeyUp(event) {
        if (event.key === " ") {
          send({ type: "CONTEXT.SET", context: { pressed: false } });
        }
      },
    }),
  };
}
