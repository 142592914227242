import { useEffect, useRef } from "react";
import useTransferIdentification from "./use-transfer-identification";

export default function useDialogOpened(onDialogOpen: () => void) {
  const { shouldOpen } = useTransferIdentification();
  const isFuncExcuted = useRef(false);

  useEffect(() => {
    if (!shouldOpen) {
      isFuncExcuted.current = false;
      return;
    }
    if (isFuncExcuted.current) return;
    isFuncExcuted.current = true;
    onDialogOpen();
  }, [onDialogOpen, shouldOpen]);
}
