import "@daangn/sprout-css/snackbar/index.css";

import type { SnackbarApi } from "@daangn/sprout-hooks-snackbar";
import { composeClassName } from "@daangn/sprout-style-utils";
import * as React from "react";

import type { SeedSnackbarPayload } from "./types";

const SnackbarRegion: React.FC<
  React.PropsWithChildren<{ api: SnackbarApi<SeedSnackbarPayload> }>
> = (props) => {
  const { children, api } = props;
  const { regionProps } = api;

  return (
    <div className={composeClassName("seed-snackbar-region")} {...regionProps}>
      {children}
    </div>
  );
};

export default SnackbarRegion;
