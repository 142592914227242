import "@daangn/sprout-css/fab/index.css";

import type { ButtonElementType } from "@daangn/sprout-hooks-button";
import { useButton } from "@daangn/sprout-hooks-button";
import { useFocusVisible } from "@daangn/sprout-hooks-interactions";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import { classNames } from "@seed-design/design-token";
import type { ElementType, Ref } from "react";
import * as React from "react";

import type { SeedExtendedFabProps } from "../types";

const typographyClassNameMap: Record<
  Required<SeedExtendedFabProps>["size"],
  string
> = {
  medium: classNames.$semantic.typography.label2Bold,
  small: classNames.$semantic.typography.label3Regular,
};

const ExtendedFab = <T extends ButtonElementType>(
  props: SeedExtendedFabProps<T>,
  ref: Ref<HTMLElement>,
) => {
  const {
    elementType: Element = "button" as ElementType,
    children,
    size = "small",
    variant = "overPaper",
    icon,
    ...otherProps
  } = props;
  const { buttonProps } = useButton(props);
  const { focusVisibleProps } = useFocusVisible();
  const { styleProps } = useStyleProps(otherProps);

  return (
    <Element
      ref={ref}
      {...styleProps}
      {...focusVisibleProps}
      {...buttonProps}
      className={composeClassName("seed-extended-fab", styleProps.className)}
      data-size={size}
      data-variant={variant}
    >
      <div data-part="icon">{icon}</div>
      <div className={typographyClassNameMap[size]} data-part="label">
        {children}
      </div>
    </Element>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _ExtendedFab = React.forwardRef(ExtendedFab);
export { _ExtendedFab as ExtendedFab };
