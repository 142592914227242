import { useEvent, wrapZagChange } from "@daangn/sprout-react-utils";
import * as menu from "@zag-js/menu";
import { normalizeProps, useMachine } from "@zag-js/react";
import type * as React from "react";
import { useId } from "react";

export interface MenuProps {
  /**
   * The document's text/writing direction.
   */
  dir?: "ltr" | "rtl";
  /**
   * The values of radios and checkboxes in the menu.
   */
  value?: Record<string, string | string[]>;
  /**
   * Callback to be called when the menu values change (for radios and checkboxes).
   */
  onValueChange?: (details: { name: string; value: string | string[] }) => void;
  /**
   * The `id` of the active menu item.
   */
  highlightedId?: string | null;
  /**
   * Function called when a menu item is selected.
   */
  onAction?: (value: string) => void;
  /**
   * The positioning point for the menu. Can be set by the context menu trigger or the button trigger.
   */
  anchorPoint?: menu.Context["anchorPoint"];
  /**
   * Whether to loop the keyboard navigation.
   */
  loop?: boolean;
  /**
   * The options used to dynamically position the menu
   */
  positioning?: menu.Context["positioning"];
  /**
   * Whether to close the menu when an option is selected
   */
  closeOnSelect?: boolean;
  /**
   * The accessibility label for the menu
   */
  "aria-label"?: string;
  /**
   * Whether the menu is open by default
   */
  defaultOpen?: boolean;
  /**
   * Whether the menu is open
   */
  isOpen?: boolean;
  /**
   * Function called when the menu is opened or closed
   */
  onOpenChange?: (open: boolean) => void;
}

export function useMenu(props: MenuProps): menu.Api {
  const {
    dir,
    value,
    onValueChange,
    highlightedId,
    onAction,
    anchorPoint,
    loop,
    positioning,
    closeOnSelect,
    "aria-label": ariaLabel,
    defaultOpen,
    isOpen,
    onOpenChange,
  } = props;

  const initialContext: menu.Context = {
    id: useId(),
    dir,
    value,
    onValueChange: useEvent(onValueChange),
    highlightedId,
    onSelect: useEvent(wrapZagChange(onAction)),
    anchorPoint,
    loop,
    positioning,
    closeOnSelect,
    "aria-label": ariaLabel,
    open: isOpen ?? defaultOpen,
    onOpenChange: useEvent((details) => onOpenChange?.(details.open)),
  };

  const context = {
    ...initialContext,
    value,
    open: isOpen,
  };

  const [state, send] = useMachine(menu.machine(initialContext), {
    context,
  });

  const api = menu.connect(state, send, normalizeProps);

  return {
    ...api,
    triggerProps: {
      ...api.triggerProps,
      onPointerDown: () => {},
      onClick(event: React.MouseEvent) {
        send({ type: "TRIGGER_CLICK", target: event.currentTarget });
      },
    },
    getItemProps: (options) => {
      return {
        ...api.getItemProps(options),
        onPointerUp: () => {},
        onClick(event: React.MouseEvent) {
          send({
            type: "ITEM_CLICK",
            src: "pointerup",
            target: event.currentTarget,
            id: options.id,
          });
        },
      };
    },
  };
}

export type MenuApi = ReturnType<typeof useMenu>;
