import { useEffect, useRef } from "react";
import { BOTTOM_SHEET_HEIGHT } from "../constants";
import * as css from "./iframe.css";

import { EVENT_NAMES, logEvent } from "../event";
import type { Bridge } from "../types";

interface IframeProps {
  bridge: Bridge;
  url: string;
  visible?: boolean;
  height?: React.IframeHTMLAttributes<HTMLIFrameElement>["height"];

  previous_screen_name?: string;
}

const Iframe: React.FC<IframeProps> = ({
  bridge,
  url,
  visible = true,
  height = BOTTOM_SHEET_HEIGHT,
  previous_screen_name,
}) => {
  const isEventSent = useRef(false);

  useEffect(() => {
    if (!visible) return;
    if (isEventSent.current) return;

    logEvent(
      bridge,
      EVENT_NAMES.shownIdentificationPayPolicyAgreementBottomSheet,
      { previous_screen_name },
    );

    isEventSent.current = true;
  }, [bridge, visible, previous_screen_name]);

  return (
    <iframe
      className={css.wrapper({ visible })}
      height={height}
      src={url}
      title="Iframe"
      width="100%"
    />
  );
};

export default Iframe;
