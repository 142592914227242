import type { BaseStyleProps } from "@daangn/sprout-style-utils";
import { useStyleProps } from "@daangn/sprout-style-utils";
import type { Ref } from "react";
import * as React from "react";

import { useTabsContext } from "./context";

export interface SeedTabPanelProps extends BaseStyleProps {
  value: string;

  children: React.ReactElement | React.ReactElement[];
}

const TabPanel = (props: SeedTabPanelProps, ref: Ref<HTMLDivElement>) => {
  const { children, value: tabValue, ...otherProps } = props;
  const { styleProps } = useStyleProps(otherProps);
  const { api } = useTabsContext();
  const { getPanelProps, shouldPanelRender } = api;
  const panelProps = getPanelProps({ value: tabValue });
  const shouldRender = shouldPanelRender(tabValue);

  return (
    <div ref={ref} {...styleProps} {...panelProps}>
      {shouldRender && children}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _TabPanel = React.forwardRef(TabPanel);
export { _TabPanel as TabPanel };
