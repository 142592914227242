import "@daangn/sprout-css/chips/index.css";

import type { ButtonElementType } from "@daangn/sprout-hooks-button";
import { useButton } from "@daangn/sprout-hooks-button";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import { classNames } from "@seed-design/design-token";
import type { ElementType, Ref } from "react";
import React from "react";

import FilterSuffixIcon from "./FilterSuffixIcon";
import type { SeedChipFilterProps } from "./types";

const ChipFilter = <T extends ButtonElementType>(
  props: SeedChipFilterProps<T>,
  ref: Ref<HTMLDivElement>,
) => {
  const {
    elementType: Element = "button" as ElementType,
    label,
    selectedValue,
    prefix,
    ...otherProps
  } = props;
  const { buttonProps } = useButton(props);
  const { styleProps } = useStyleProps(otherProps);

  const variant = selectedValue ? "emphasized" : "default";

  return (
    <Element
      ref={ref}
      {...styleProps}
      {...buttonProps}
      data-size="small"
      data-variant={variant}
      data-part="root"
      className={composeClassName("seed-chip", styleProps.className)}
    >
      {prefix && <div data-part="prefix">{prefix}</div>}
      <div
        className={classNames.$semantic.typography.bodyM2Regular}
        data-part="label"
      >
        {selectedValue ? selectedValue : label}
      </div>
      <div data-part="suffix">
        <FilterSuffixIcon />
      </div>
    </Element>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _ChipFilter = React.forwardRef(ChipFilter);
export { _ChipFilter as ChipFilter };
