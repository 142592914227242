import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const IconCarHeatedSteeringWheelThin = (
  {
    size = 24,
    ...props
  }: SVGProps<SVGSVGElement> & {
    size?: number | string,
  },
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-seed-icon="true"
    data-seed-icon-version="0.6.1"
    width={size}
    height={size}
    ref={ref}
    {...props}
  >
    <g>
      <g>
        <path
          d="M9 5.2002L11 2.2002L13 5.2002H11.4352C11.3248 5.50805 11.3027 5.80289 11.3465 6.04526C11.3945 6.31065 11.5621 6.56126 11.8428 6.9361L11.8885 6.99693C12.1327 7.32145 12.4607 7.75736 12.5917 8.28451C12.6815 8.64599 12.7144 9.04304 12.613 9.45686C12.5108 9.87389 12.2833 10.2628 11.9228 10.6232C11.6892 10.8568 11.3105 10.8568 11.077 10.6232C10.8434 10.3896 10.8434 10.0109 11.077 9.77734C11.3071 9.54724 11.4086 9.34564 11.4511 9.17216C11.4944 8.99547 11.4877 8.80201 11.4308 8.57297C11.3614 8.29388 11.1742 8.03875 10.8854 7.65316L10.8759 7.64044C10.6214 7.30077 10.2742 6.83719 10.1694 6.2582C10.1079 5.91821 10.1174 5.55823 10.194 5.2002H9Z"
          fill="currentColor"
        />
        <path
          d="M15.4352 5.2002H17L15 2.2002L13 5.2002H14.194C14.1174 5.55823 14.1079 5.91821 14.1694 6.2582C14.2742 6.83719 14.6214 7.30077 14.8759 7.64044L14.8854 7.65316C15.1742 8.03875 15.3614 8.29388 15.4308 8.57297C15.4877 8.80201 15.4944 8.99547 15.4511 9.17216C15.4086 9.34564 15.3071 9.54724 15.077 9.77734C14.8434 10.0109 14.8434 10.3896 15.077 10.6232C15.3105 10.8568 15.6892 10.8568 15.9228 10.6232C16.2833 10.2628 16.5108 9.87389 16.613 9.45686C16.7144 9.04304 16.6815 8.64599 16.5917 8.28451C16.4607 7.75736 16.1327 7.32145 15.8885 6.99693L15.8428 6.9361C15.5621 6.56126 15.3945 6.31065 15.3465 6.04526C15.3027 5.80289 15.3248 5.50805 15.4352 5.2002Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.4046 8.69338C8.677 8.5047 8.74487 8.13091 8.55618 7.8585C8.36749 7.5861 7.99371 7.51823 7.7213 7.70692C5.71521 9.09649 4.3999 11.4361 4.3999 14.0845C4.3999 18.3365 7.79331 21.8002 11.9999 21.8002C16.2065 21.8002 19.5999 18.3365 19.5999 14.0845C19.5999 12.9898 19.375 11.9466 18.9687 11.0014C18.8378 10.6969 18.4849 10.5562 18.1805 10.6871C17.8761 10.8179 17.7353 11.1708 17.8662 11.4752C18.2092 12.2732 18.3999 13.1553 18.3999 14.0845C18.3999 14.9395 18.2385 15.7551 17.9452 16.502L14.5315 14.7952C14.5762 14.6041 14.5999 14.4048 14.5999 14.2001C14.5999 12.7642 13.4358 11.6001 11.9999 11.6001C10.564 11.6001 9.3999 12.7642 9.3999 14.2001C9.3999 14.4049 9.42358 14.6041 9.46834 14.7953L6.05461 16.5021C5.76134 15.7552 5.5999 14.9395 5.5999 14.0845C5.5999 11.8411 6.71272 9.8653 8.4046 8.69338ZM13.9943 15.8682C13.5174 16.4378 12.8009 16.8001 11.9999 16.8001C11.1989 16.8001 10.4825 16.4379 10.0055 15.8683L6.5939 17.5741C7.73072 19.3951 9.73047 20.6002 11.9999 20.6002C14.2694 20.6002 16.2691 19.3951 17.406 17.574L13.9943 15.8682ZM10.5999 14.2001C10.5999 13.4269 11.2267 12.8001 11.9999 12.8001C12.7731 12.8001 13.3999 13.4269 13.3999 14.2001C13.3999 14.9733 12.7731 15.6001 11.9999 15.6001C11.2267 15.6001 10.5999 14.9733 10.5999 14.2001Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
const ForwardRef = forwardRef(IconCarHeatedSteeringWheelThin);
export default ForwardRef;
