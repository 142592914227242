import type { NormalizeProps, PropTypes } from "@zag-js/types";

import { parts } from "./radio-group.anatomy";
import { dom } from "./radio-group.dom";
import type { RadioGroupApi, Send, State } from "./radio-group.types";

export function connect<T extends PropTypes>(
  state: State,
  send: Send,
  normalize: NormalizeProps<T>,
): RadioGroupApi<T> {
  const focus = () => {
    const firstEnabledAndCheckedInput = dom.getFirstEnabledAndCheckedInputEl(
      state.context,
    );

    if (firstEnabledAndCheckedInput) {
      firstEnabledAndCheckedInput.focus();
      return;
    }

    const firstEnabledInput = dom.getFirstEnabledInputEl(state.context);
    firstEnabledInput?.focus();
  };

  return {
    value: state.context.value,
    setValue(value: string) {
      send({ type: "SET_VALUE", value, manual: true });
    },
    clearValue() {
      send({ type: "SET_VALUE", value: null, manual: true });
    },
    focus,
    blur() {
      const focusedElement = dom.getActiveElement(state.context);
      const inputEls = dom.getInputEls(state.context);
      const radioInputIsFocused = inputEls.some((el) => el === focusedElement);
      if (radioInputIsFocused) focusedElement?.blur();
    },

    rootProps: normalize.element({
      ...parts.root.attrs,
      role: "radiogroup",
      id: dom.getRootId(state.context),
      "aria-labelledby": dom.getLabelId(state.context),
      "data-orientation": state.context.orientation,
      "aria-orientation": state.context.orientation,
      dir: state.context.dir,
    }),

    labelProps: normalize.element({
      ...parts.label.attrs,
      id: dom.getLabelId(state.context),
      onClick: focus,
    }),
  };
}
