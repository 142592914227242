import "@daangn/sprout-components-button/index.css";

import { BoxButton, TextButton } from "@daangn/sprout-components-button";
import { useState } from "react";
import { IconIdentification } from "../assets";
import useTransferIdentification from "../hooks/use-transfer-identification";
import useVerifyIdentification from "../hooks/use-verify-identification";
import type { Bridge } from "../types";
import * as css from "./entry-point.css";
import Iframe from "./iframe";

interface EntryPointProps {
  bridge: Bridge;

  serviceName: string;
  description?: string;
  agreementUrl: string;
  transferIdentificationScheme: string;
  newIdentificationScheme: string;

  fetchVerify: () => Promise<Response>;
  onCancelClick: () => void;
  onError?: (e: Error) => void;
  onNotIdentified: () => void;
  onIdentified: () => void;

  previous_screen_name?: string;
}

const EntryPoint: React.FC<EntryPointProps> = ({
  bridge,

  serviceName,
  description,
  agreementUrl,
  transferIdentificationScheme,
  newIdentificationScheme,

  fetchVerify,
  onCancelClick,
  onError,
  onIdentified,
  onNotIdentified,

  previous_screen_name,
}) => {
  const [visible, setVisible] = useState(true);

  const transferIdentification = useTransferIdentification();
  const { onNextClick } = useVerifyIdentification({
    bridge,

    agreementUrl,
    transferIdentificationScheme,
    newIdentificationScheme,

    fetchVerify,
    onError,
    onIdentified,
    onNotIdentified,
    onMoveAgreement: () => {
      setVisible(false);
    },
  });

  return (
    <div className={css.wrapper}>
      <Iframe
        bridge={bridge}
        visible={!visible}
        url={agreementUrl}
        previous_screen_name={
          previous_screen_name ?? transferIdentification.previous_screen_name
        }
      />
      <div className={css.entryPoint({ visible })}>
        <div className={css.iconArea}>
          <IconIdentification className={css.icon} />
        </div>
        <div className={css.serviceArea}>
          <div className={css.serviceAreaTitle}>{serviceName}</div>
          <div className={css.serviceAreaDescription}>
            {description
              ? description
              : `${serviceName} 사용을 위해 본인인증이 필요해요.`}
          </div>
        </div>
        <div className={css.boxButtonArea}>
          <BoxButton minWidth="100%" onClick={onNextClick} size="xlarge">
            <span>30초 만에 인증하기</span>
          </BoxButton>
        </div>
        <div className={css.textButtonArea}>
          <TextButton
            onClick={onCancelClick}
            variant="secondary"
            size="small"
            bold
          >
            다음에 할게요
          </TextButton>
        </div>
      </div>
    </div>
  );
};

export default EntryPoint;
