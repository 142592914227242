const regex =
  /(?<userAgent>TowneersApp\/(?<version>\d+\.\d+\.\d+)\s\((?<versionCode>\d+);\s*(?<osType>iOS|Android)\s(?<osVersion>[^;]+);\s*(?<appType>\w+);\s*(?<buildType>\w+)\))/;

type AppInfo = {
  userAgent: string;
  version: string;
  versionCode: string;
  osType: string;
  osVersion: string;
  appType: string;
  buildType: string;
};

/**
 *
 * @param {string} userAgent Custom UA
 * @throws {Error} 앱 UA가 포함되어 있지 않은 경우
 */
export function parseAppInfo(userAgent: string): AppInfo {
  const result = userAgent.match(regex);
  if (!result) {
    throw new Error("Invalid user agent");
  }

  return result.groups as AppInfo;
}

export function parseAppInfoSafe(userAgent: string): AppInfo | undefined {
  try {
    return parseAppInfo(userAgent);
  } catch (error) {
    return undefined;
  }
}
