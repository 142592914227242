import { vars } from "@seed-design/design-token";

export const spinnerVariant = {
  primary: {
    trackColor: vars.$semantic.color.primaryLow,
    indicatorColor: vars.$semantic.color.primary,
  },
  white: {
    trackColor: vars.$static.color.staticWhiteAlpha200,
    indicatorColor: vars.$static.color.staticWhite,
  },
  black: {
    trackColor: vars.$scale.color.grayAlpha50,
    indicatorColor: vars.$scale.color.gray900,
  },
};
