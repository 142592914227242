import * as React from "react";
import { useEffect, useId, useRef } from "react";

import { SnackbarContext } from "./context";

export const AvoidSnackbarOverlap: React.FC<{
  children: React.ReactElement;
}> = (props) => {
  const context = React.useContext(SnackbarContext);
  if (!context) {
    throw new Error(
      `AvoidSnackbarOverlap cannot be called outside the SnackbarProvider`,
    );
  }
  const id = useId();
  const { tracker } = context;
  const { children } = props;
  const childRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      tracker.upsert(id, entries[0].target);
    });
    if (childRef.current) {
      tracker.upsert(id, childRef.current);
      observer.observe(childRef.current);
    }
    return () => {
      tracker.remove(id);
      observer.disconnect();
    };
  }, [tracker, id]);

  return React.cloneElement(children, { ref: childRef });
};
