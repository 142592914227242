import "@daangn/sprout-css/snackbar/index.css";

import { useFocusVisible } from "@daangn/sprout-hooks-interactions";
import type { SnackbarApi } from "@daangn/sprout-hooks-snackbar";
import { composeClassName } from "@daangn/sprout-style-utils";
import { classNames } from "@seed-design/design-token";
import { visuallyHiddenStyle } from "@zag-js/visually-hidden";
import * as React from "react";

import SnackbarIcon from "./SnackbarIcon";
import type { SeedSnackbarPayload } from "./types";

const Snackbar: React.FC<{ api: SnackbarApi<SeedSnackbarPayload> }> = (
  props,
) => {
  const { api } = props;
  const { focusVisibleProps } = useFocusVisible();
  const { onClick, onAction, shouldCloseOnAction, type, actionLabel, message } =
    api.currentSnackbar!.payload;

  const handleAction = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (onAction) {
      onAction();
    }

    if (shouldCloseOnAction) {
      api.dismiss();
    }
  };

  function renderMessage() {
    if (typeof message === "undefined") {
      return null;
    }
    if (typeof message === "string") {
      return message;
    }

    return message();
  }

  return (
    <div
      data-type={type}
      {...api.rootProps}
      {...focusVisibleProps}
      onClick={onClick}
      className={composeClassName("seed-snackbar")}
    >
      <SnackbarIcon type={type} />
      <p
        {...api.titleProps}
        className={classNames.$semantic.typography.bodyL2Regular}
      >
        {renderMessage()}
      </p>
      {actionLabel && (
        <button
          onClick={handleAction}
          className={composeClassName(
            "seed-snackbar-action-trigger",
            classNames.$semantic.typography.label3Bold,
          )}
        >
          {actionLabel}
        </button>
      )}
      <button style={visuallyHiddenStyle} {...api.closeTriggerProps}>
        Close
      </button>
    </div>
  );
};

export default Snackbar;
