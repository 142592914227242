import "@daangn/sprout-css/callout/index.css";

import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import type { Ref } from "react";
import React from "react";

import type { SeedDismissableCalloutProps } from "./types";
import { useDismissable } from "./useDismissable";

const CloseIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.79474 2.79474C3.02107 2.56842 3.38802 2.56842 3.61435 2.79474L9 8.1804L14.3857 2.79474C14.612 2.56842 14.9789 2.56842 15.2053 2.79474C15.4316 3.02107 15.4316 3.38802 15.2053 3.61435L9.8196 9L15.2053 14.3857C15.4316 14.612 15.4316 14.9789 15.2053 15.2053C14.9789 15.4316 14.612 15.4316 14.3857 15.2053L9 9.8196L3.61435 15.2053C3.38802 15.4316 3.02107 15.4316 2.79474 15.2053C2.56842 14.9789 2.56842 14.612 2.79474 14.3857L8.1804 9L2.79474 3.61435C2.56842 3.38802 2.56842 3.02107 2.79474 2.79474Z"
      fill="currentColor"
    />
  </svg>
);

const DismissableCallout = (
  props: SeedDismissableCalloutProps,
  ref: Ref<HTMLDivElement>,
) => {
  const { variant = "normal", children, ...otherProps } = props;
  const { styleProps } = useStyleProps(otherProps);
  const { isOpen, closeButtonProps } = useDismissable(otherProps);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      ref={ref}
      {...styleProps}
      data-variant={variant}
      data-part="root"
      className={composeClassName(
        "seed-callout",
        "seed-dismissable-callout",
        styleProps.className,
      )}
    >
      <div data-part="content">{children}</div>
      <div {...closeButtonProps} data-part="close-button">
        {CloseIcon}
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _DismissableCallout = React.forwardRef(DismissableCallout);
export { _DismissableCallout as DismissableCallout };
