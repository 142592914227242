import "@daangn/sprout-css/spinner/index.css";

import { useProgressBar } from "@daangn/sprout-hooks-progress";
import type { BaseStyleProps } from "@daangn/sprout-style-utils";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import { vars } from "@seed-design/design-token";
import type { Ref } from "react";
import React from "react";

export interface SeedInternalSpinnerProps
  extends Omit<BaseStyleProps, "width" | "height"> {
  /**
   * The size of the spinner.
   * @default 24
   */
  size?: string | number;

  /**
   * The color of the spinner track.
   * @default vars.$scale.color.grayAlpha50
   */
  trackColor?: string;

  /**
   * The color of the spinner indicator.
   * @default vars.$scale.color.gray400
   */
  indicatorColor?: string;

  /**
   * Defines a string value that labels the current element.
   */
  "aria-label"?: string;
  /**
   * Identifies the element (or elements) that labels the current element.
   */
  "aria-labelledby"?: string;
}

/**
 * @description
 * 해당 컴포넌트는 sprout 내부에서 사용되는 Spinner 컴포넌트입니다.
 * 이 컴포넌트를 사용하지 말아주세요.
 */
const InternalSpinner = (
  props: SeedInternalSpinnerProps,
  ref: Ref<HTMLDivElement>,
) => {
  const {
    size = 24,
    trackColor = vars.$scale.color.grayAlpha50,
    indicatorColor = vars.$scale.color.gray400,
    ...otherProps
  } = props;
  const { progressBarProps } = useProgressBar({
    ...otherProps,
    isIndeterminate: true,
  });
  const { styleProps } = useStyleProps({
    ...otherProps,
    width: size,
    height: size,
  });

  return (
    <div
      ref={ref}
      {...styleProps}
      {...progressBarProps}
      className={composeClassName("seed-spinner", styleProps.className)}
    >
      <svg
        viewBox="0 0 40 40"
        fill={trackColor}
        xmlns="http://www.w3.org/2000/svg"
        data-part="track"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 5.12821C11.7865 5.12821 5.12821 11.7865 5.12821 20C5.12821 28.2135 11.7865 34.8718 20 34.8718C28.2135 34.8718 34.8718 28.2135 34.8718 20C34.8718 11.7865 28.2135 5.12821 20 5.12821ZM0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
          data-part="track-path"
        />
      </svg>

      <svg
        viewBox="20 20 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-part="indicator"
        color={indicatorColor}
      >
        <circle
          cx="40"
          cy="40"
          r="17.5"
          fill="none"
          data-part="indicator-path"
        />
      </svg>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _InternalSpinner = React.forwardRef(InternalSpinner);
export { _InternalSpinner as InternalSpinner };
