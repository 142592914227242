import * as avatar from "@daangn/sprout-machines-avatar";
import { normalizeProps, useMachine } from "@zag-js/react";
import { useId } from "react";

export interface AvatarProps {
  /**
   * @default 0
   */
  fallbackDelay?: number;

  /**
   * avatar의 이미지 로드 시작 시 호출되는 이벤트에요.
   */
  onLoadStart?: () => void;

  /**
   * avatar의 이미지 로드 성공 시 호출되는 이벤트에요.
   */
  onLoadSuccess?: () => void;

  /**
   * avatar의 이미지 로드 폴백 시 호출되는 이벤트에요.
   */
  onLoadFallback?: () => void;

  /**
   * avatar의 이미지 로드 실패 시 호출되는 이벤트에요.
   */
  onLoadError?: () => void;
}

export function useAvatar(props: AvatarProps) {
  const id = useId();

  const {
    fallbackDelay,
    onLoadStart,
    onLoadSuccess,
    onLoadError,
    onLoadFallback,
  } = props;

  const context = {
    fallbackDelay,
    onLoadStart,
    onLoadSuccess,
    onLoadError,
    onLoadFallback,
  };

  const [state, send] = useMachine(avatar.machine({ id, ...context }), {
    context,
  });

  const api: avatar.Api = avatar.connect(state, send, normalizeProps);

  return {
    api,
  };
}
