import { MinikarrotParams, SchemeParams, Stage } from "./types";

export const protocols: Record<Stage, string> = {
  alpha: "karrot.alpha",
  production: "karrot",
};

export default function createScheme({
  stage,
  path,
  searchParams,
}: SchemeParams) {
  const protocol = protocols[stage];
  const scheme = new URL(`${protocol}://${path}`);
  if (searchParams) scheme.search = searchParams.toString();
  return scheme.toString();
}

function isKorean(char: string) {
  const match = char.match(
    /[\uac00-\ud7af]|[\u1100-\u11ff]|[\u3130-\u318f]|[\ua960-\ua97f]|[\ud7b0-\ud7ff]/g,
  );
  return char === match?.[0];
}

export function createMinikarrotScheme({
  url,
  stage,
  navbar = false,
  scrollable = false,
  presentTop = false,
}: MinikarrotParams) {
  const searchParams = new URLSearchParams();

  let koreanEncodedRemote = "";

  for (const c of url) {
    koreanEncodedRemote += isKorean(c) ? encodeURIComponent(c) : c;
  }

  searchParams.set("remote", koreanEncodedRemote);

  searchParams.set("navbar", String(navbar));
  searchParams.set("scrollable", String(scrollable));

  if (presentTop) {
    searchParams.set("present", "top");
  }

  return createScheme({
    stage,
    path: "minikarrot/router",
    searchParams,
  });
}
