import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const IconCarVentilationSeatThin = (
  {
    size = 24,
    ...props
  }: SVGProps<SVGSVGElement> & {
    size?: number | string,
  },
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-seed-icon="true"
    data-seed-icon-version="0.6.1"
    width={size}
    height={size}
    ref={ref}
    {...props}
  >
    <g>
      <g>
        <path
          d="M8.18193 8.18187C8.18193 7.50501 8.4218 6.92671 8.90156 6.44695C9.38131 5.9672 9.95961 5.72733 10.6365 5.72733C11.3133 5.72733 11.8916 5.9672 12.3714 6.44695C12.8511 6.92671 13.091 7.50501 13.091 8.18187H8.18193ZM5.72738 10.6364C5.05052 10.6364 4.47222 10.3965 3.99246 9.91679C3.51271 9.43704 3.27284 8.85873 3.27284 8.18187H8.18193C8.18193 8.85873 7.94205 9.43704 7.4623 9.91679C6.98255 10.3965 6.40424 10.6364 5.72738 10.6364ZM8.18193 8.18187C7.50507 8.18187 6.92676 7.942 6.44701 7.46224C5.96726 6.98249 5.72738 6.40419 5.72738 5.72733C5.72738 5.05047 5.96726 4.47216 6.44701 3.99241C6.92676 3.51266 7.50507 3.27278 8.18193 3.27278V8.18187ZM8.18193 13.091V8.18187C8.85879 8.18187 9.43709 8.42175 9.91684 8.9015C10.3966 9.38125 10.6365 9.95956 10.6365 10.6364C10.6365 11.3133 10.3966 11.8916 9.91684 12.3713C9.43709 12.8511 8.85879 13.091 8.18193 13.091Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2281 3.51809C18.1213 3.22151 16.9836 3.87836 16.687 4.98519L14.1019 14.6331C13.8977 15.3953 13.207 15.9253 12.4179 15.9253H6.00011C4.85423 15.9253 3.92531 16.8542 3.92531 18.0001C3.92531 19.1459 4.85423 20.0749 6.00011 20.0749L16.6168 20.0749C16.8154 20.0749 16.9892 19.9414 17.0404 19.7496L20.6951 6.05976C20.9917 4.95293 20.335 3.81466 19.2281 3.51809ZM15.4266 4.64744C15.9097 2.84446 17.7629 1.7745 19.5659 2.2576C21.3689 2.74071 22.4388 4.59395 21.9557 6.39693L18.3012 20.0862C18.0975 20.8491 17.4064 21.3799 16.6168 21.3799L6.00011 21.3798C4.13353 21.3798 2.62036 19.8666 2.62036 18.0001C2.62036 16.1335 4.13353 14.6203 6.00011 14.6203H12.4179C12.6164 14.6203 12.7901 14.487 12.8414 14.2953L15.4266 4.64744Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
const ForwardRef = forwardRef(IconCarVentilationSeatThin);
export default ForwardRef;
