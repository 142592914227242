import "@daangn/sprout-css/tabs/index.css";

import type { TabsProps } from "@daangn/sprout-hooks-tabs";
import { useTabs } from "@daangn/sprout-hooks-tabs";
import type { BaseStyleProps } from "@daangn/sprout-style-utils";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import type { Ref } from "react";
import * as React from "react";

import { TabsContext } from "./context";

export interface SeedTabsProps extends TabsProps, BaseStyleProps {
  /**
   * tablist가 포함된 컨테이너의 width 관련 속성
   * @default "hug"
   */
  layout: "hug" | "fill";

  children: React.ReactNode;
}

const Tabs = (props: SeedTabsProps, ref: Ref<HTMLDivElement>) => {
  const { children, layout, ...otherProps } = props;
  const { styleProps } = useStyleProps(otherProps);

  const api = useTabs({ ...otherProps });
  const { rootProps } = api;

  const childrenList = React.Children.toArray(children);
  if (childrenList.length !== 2) {
    throw new Error(
      "Tabs must have exactly two children: TabList and TabPanelGroup.",
    );
  }

  return (
    <div
      ref={ref}
      {...styleProps}
      data-layout={layout}
      className={composeClassName("seed-tabs")}
      {...rootProps}
    >
      <TabsContext.Provider value={{ api }}>{children}</TabsContext.Provider>
    </div>
  );
};
// eslint-disable-next-line @typescript-eslint/naming-convention
const _Tabs = React.forwardRef(Tabs);
export { _Tabs as Tabs };
