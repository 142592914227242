/* eslint-disable @typescript-eslint/no-shadow */
import * as tabs from "@daangn/sprout-machines-tabs";
import { useEvent, wrapZagChange } from "@daangn/sprout-react-utils";
import { normalizeProps, useMachine } from "@zag-js/react";
import { useId } from "react";

export interface TabsProps {
  /**
   * 포커스 됐을 때 Panel을 자동으로 활성화 할지 여부
   * @default 'automatic'
   */
  activationMode?: "automatic" | "manual";
  /**
   * tab swipe 기능 활성화 여부 (모바일에서만 동작)
   * @default true
   */
  isSwipeable?: boolean;

  /**
   * lazy loading 활성화 여부
   * @default false
   */
  isLazy?: boolean;

  /**
   * lazy loading 모드
   * @default "keepMounted"
   */
  lazyMode?: "unmount" | "keepMounted";

  value?: string;

  defaultValue?: string;

  /**
   * Callback to be called when the selected/active tab changes
   */
  onChange?: (value: string | null) => void;
  /**
   * Callback to be called when the focused tab changes
   */
  onFocus?: (value: string | null) => void;

  onSwipeStart?: () => void;
  onSwipeEnd?: () => void;
}

export function useTabs(props: TabsProps): tabs.Api {
  const {
    activationMode = "automatic",
    isSwipeable = true,
    isLazy,
    lazyMode,
    value,
    defaultValue,
    onChange,
    onFocus,
    onSwipeStart,
    onSwipeEnd,
  } = props;

  const id = useId();

  const initialContext = {
    id,
    isLazy,
    lazyMode,
    value: defaultValue,
    activationMode,
    isSwipeable,
    onChange: useEvent(({ value }: { value: string | null }) =>
      wrapZagChange(onChange)({ value }),
    ),
    onFocus: useEvent(({ value }: { value: string | null }) =>
      wrapZagChange(onFocus)({ value }),
    ),
    onSwipeStart: useEvent(onSwipeStart),
    onSwipeEnd: useEvent(onSwipeEnd),
  };

  const context = {
    ...initialContext,
    value,
  } satisfies tabs.Context;

  const [state, send] = useMachine(tabs.machine(initialContext), {
    context,
  });

  const api = tabs.connect(state, send, normalizeProps);

  return api;
}

export type TabsApi = ReturnType<typeof tabs.connect>;
