/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
import { createMachine } from "@zag-js/core";
import { raf } from "@zag-js/dom-query";
import { compact } from "@zag-js/utils";
import { graphemeSegments } from "unicode-segmenter/grapheme";

import { dom } from "./text-field.dom";
import type {
  MachineContext,
  MachineState,
  UserDefinedContext,
} from "./text-field.types";

export function machine(userContext: UserDefinedContext) {
  const ctx = compact(userContext);
  return createMachine<MachineContext, MachineState>(
    {
      id: "text-field",
      initial: "unknown",

      context: {
        value: userContext.value ?? "",
        graphemes: getGraphemes(userContext.value ?? ""),
        focused: false,
        ...ctx,
        renderedElements: {
          description: true,
          errorMessage: true,
        },
      },

      watch: {
        isDisabled: "removeFocusIfNeeded",
        value: ["syncInputValue"],
      },

      entry: ["checkRenderedElements", "syncInputValue"],

      on: {
        CHANGE: {
          actions: "setValue",
        },
        SET_FOCUSED: {
          actions: "setFocused",
        },
      },

      states: {
        unknown: {},
      },
    },
    {
      actions: {
        checkRenderedElements(ctx) {
          raf(() => {
            Object.assign(ctx.renderedElements, {
              description: !!dom.getDescriptionEl(ctx),
              errorMessage: !!dom.getErrorMessageEl(ctx),
            });
          });
        },
        syncInputValue(ctx) {
          sync.value(ctx);
        },
        setValue(ctx, evt) {
          set.value(ctx, evt.value);
        },
        setFocused(ctx, evt) {
          ctx.focused = evt.value;
        },
        removeFocusIfNeeded(ctx) {
          if (ctx.isDisabled && ctx.focused) {
            ctx.focused = false;
          }
        },
      },
    },
  );
}

const getGraphemes = (value: string) => {
  return Array.from(graphemeSegments(value)).map((g) => g.segment);
};

const sync = {
  value(ctx: MachineContext) {
    const input = dom.getInputEl(ctx);
    if (!input) return;
    input.value = ctx.value;
    ctx.graphemes = getGraphemes(ctx.value ?? "");
  },
};

const invoke = {
  change(ctx: MachineContext) {
    ctx.onChange?.(ctx.value);
  },
};

const set = {
  value(ctx: MachineContext, value: string) {
    const prevValue = ctx.value;
    const maxLength = ctx.maxLength ?? Infinity;
    const graphemes = getGraphemes(value ?? "");

    const slicedGraphemes = ctx.allowExceedLength
      ? graphemes
      : graphemes.slice(0, maxLength);

    ctx.value = slicedGraphemes.join("");
    ctx.graphemes = slicedGraphemes;

    /**
     * NOTE(june):
     * maxLength 상태에서 input에 입력을 가하면 onChange는 호출되지 않게 하기 위한 로직
     * Logic to prevent onChange from being called when input is entered in maxLength state
     */
    if (prevValue !== ctx.value) {
      invoke.change(ctx);
    }

    /**
     * NOTE(june):
     * invoke 이후에 외부에서 입력된 value(ctx.value)와 내부 value(value)가 다를 때 sync 해줌.
     * sync when the value(ctx.value) entered from outside and the internal value(value) are different after invoke.
     */
    if (value !== ctx.value) {
      sync.value(ctx);
    }
  },
};
