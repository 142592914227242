import { createAnatomy } from "@zag-js/anatomy";

export const anatomy = createAnatomy("textfield").parts(
  "root",
  "label",
  "input",
  "description",
  "errorMessage",
);
export const parts = anatomy.build();
