import { AppInfo } from "./types";

const ecmaUserAgentRegex =
  /(?<userAgent>TowneersApp\/(?<version>\d+\.\d+\.\d+)\s\((?<versionCode>\d+);\s*(?<osType>iOS|Android)\s(?<osVersion>[^;]+);\s*(?<appType>\w+);\s*(?<buildType>\w+)\))/;

const appInfoFallback = {
  appVersion: "0.0.0",
  platform: "Android",
};

export default function getAppInfo(): AppInfo {
  if (typeof navigator === "undefined") {
    return appInfoFallback;
  }

  const regex = ecmaUserAgentRegex;

  const { userAgent } = navigator;

  const result = userAgent.match(regex);

  const appVersion = result?.groups?.version;
  const platform = result?.groups?.osType;

  if (!appVersion || !platform) {
    return appInfoFallback;
  }

  return {
    appVersion,
    platform,
  };
}
