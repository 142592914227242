import { createAnatomy } from "@zag-js/anatomy";

export const anatomy = createAnatomy("tabs").parts(
  "root",
  "tabList",
  "tab",
  "panelGroup",
  "panelGroupCamera",
  "panel",
  "indicator",
);

export const parts = anatomy.build();
