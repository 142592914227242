/* eslint-disable @typescript-eslint/no-shadow */
import { createMachine, ref } from "@zag-js/core";
import { addDomEvent } from "@zag-js/dom-event";
import { callAll, compact } from "@zag-js/utils";

import { dom } from "./button.dom";
import type {
  MachineContext,
  MachineState,
  UserDefinedContext,
} from "./button.types";
import { isOverTarget } from "./button.utils";

export function machine(userContext: UserDefinedContext) {
  const ctx = compact(userContext);
  return createMachine<MachineContext, MachineState>(
    {
      id: "button",
      initial: "idle",

      context: {
        active: false,
        hovered: false,
        focused: false,
        disabled: false,
        activePointerId: null,
        targetEl: null,
        ...ctx,
      },

      watch: {
        disabled: "removeFocusIfNeeded",
      },

      on: {
        SET_HOVERED: {
          actions: "setHovered",
        },
        SET_FOCUSED: {
          actions: "setFocused",
        },
      },

      states: {
        idle: {
          entry: ["resetContext"],
          on: {
            POINTER_DOWN: {
              actions: ["setPointerId", "setTarget"],
              target: "pressed:in",
            },
            KEY_DOWN: {
              actions: ["setTarget"],
              target: "pressed:in",
            },
          },
        },
        "pressed:in": {
          activities: ["trackDocumentPointerEvents"],
          on: {
            DOC_POINTER_UP: {
              target: "idle",
            },
            DOC_POINTER_CANCEL: {
              target: "idle",
            },
            POINTER_LEAVE: {
              target: "pressed:out",
            },
            KEY_UP: {
              target: "idle",
            },
          },
        },
        "pressed:out": {
          activities: ["trackDocumentPointerEvents"],
          on: {
            DOC_POINTER_UP: {
              target: "idle",
            },
            DOC_POINTER_CANCEL: {
              target: "idle",
            },
            POINTER_MOVE: {
              target: "pressed:in",
            },
          },
        },
      },
    },
    {
      activities: {
        trackDocumentPointerEvents(ctx, _evt, { send }) {
          const doc = dom.getDoc(ctx);

          const onPointerMove = (event: PointerEvent) => {
            if (event.pointerId !== ctx.activePointerId) return;
            const isOver = isOverTarget(event, ctx.targetEl);
            if (!isOver) {
              send({ type: "POINTER_LEAVE", event });
            }
          };

          const onPointerUp = (event: PointerEvent) => {
            if (event.pointerId !== ctx.activePointerId || event.button !== 0)
              return;
            send({ type: "DOC_POINTER_UP", event });
          };

          const onPointerCancel = (event: PointerEvent | MouseEvent) => {
            send({ type: "DOC_POINTER_CANCEL", event });
          };

          return callAll(
            addDomEvent(doc, "pointermove", onPointerMove, false),
            addDomEvent(doc, "pointerup", onPointerUp, false),
            addDomEvent(doc, "pointercancel", onPointerCancel, false),
          );
        },
      },
      // TODO: trackDocumentKeyUp 추가할 것, keypress 중 focus가 빠지면 keyup이 발생하지 않는다.
      actions: {
        setPointerId(ctx, { event }) {
          ctx.activePointerId = event.pointerId;
        },
        setTarget(ctx, { event }) {
          ctx.targetEl = ref(event.currentTarget);
        },
        resetContext(ctx) {
          ctx.activePointerId = null;
        },
        setHovered(ctx, evt) {
          ctx.hovered = evt.value;
        },
        setFocused(ctx, evt) {
          ctx.focused = evt.value;
        },
        removeFocusIfNeeded(ctx) {
          if (ctx.disabled && ctx.focused) {
            ctx.focused = false;
          }
        },
      },
    },
  );
}
