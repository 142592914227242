import "@daangn/sprout-css/chips/index.css";

import {
  useRadioGroup,
  useRadioGroupState,
} from "@daangn/sprout-hooks-radio-group";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import type { Ref } from "react";
import React from "react";

import { RadioContext } from "./context";
import type { SeedChipRadioGroupProps } from "./types";

const ChipRadioGroup = (
  props: SeedChipRadioGroupProps,
  ref: Ref<HTMLDivElement>,
) => {
  const { children, size = "medium", ...otherProps } = props;
  const { styleProps } = useStyleProps(otherProps);
  const state = useRadioGroupState({
    ...otherProps,
    orientation: "horizontal",
  });
  const api = useRadioGroup(state);

  return (
    <div
      ref={ref}
      {...styleProps}
      {...api.rootProps}
      className={composeClassName("seed-chip-group", styleProps.className)}
      data-size={size}
    >
      <RadioContext.Provider value={{ state, size }}>
        {children}
      </RadioContext.Provider>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _ChipRadioGroup = React.forwardRef(ChipRadioGroup);
export { _ChipRadioGroup as ChipRadioGroup };
