import type { MenuApi } from "@daangn/sprout-hooks-menu";
import React from "react";

export const MenuFabContext = React.createContext<{ api: MenuApi } | null>(
  null,
);

export const useMenuFabContext = () => {
  const context = React.useContext(MenuFabContext);
  if (!context) {
    throw new Error(
      `MenuFab child components Cannot be rendered outside the MenuFab`,
    );
  }
  return context;
};
