import "@daangn/sprout-css/dialog/index.css";

import { useDialog } from "@daangn/sprout-hooks-dialog";
import { composeClassName } from "@daangn/sprout-style-utils";
import { Portal } from "@zag-js/react";
import * as React from "react";

import { AlertDialogContext } from "./context";
import type { SeedDialogContainerProps } from "./types";

export const DialogContainer = (props: SeedDialogContainerProps) => {
  const {
    children,
    trapFocus = true,
    restoreFocus = false,
    ...otherProps
  } = props;
  const childArray = React.Children.toArray(children);
  const child = React.isValidElement(childArray[0]) ? childArray[0] : null;

  if (childArray.length > 1) {
    throw new Error("Only a single child can be passed to DialogContainer.");
  }

  const api = useDialog({
    ...otherProps,
    isOpen: !!child,
    closeOnEsc: false,
    closeOnOutsideClick: false,
    trapFocus,
    restoreFocus,
    role: "alertdialog",

    /**
     * @todo
     * 해당 코드는 추후에 없어질 예정입니다.
     * 하지만 포커스에 대한 동작은 그대로지만 포커스 링을 보여줄 지 말지는 논의가 필요합니다.
     * 고민 포인트는 탭을 계속 눌러서 포커스가 이동됐을 때도 포커스링이 보여야 할지 말지입니다.
     *
     * @note
     * `initialFocusEl`는 현재 zag에서 제공해주는 타입으로는 false이 없지만 본래 MaybeElement | false의 타입을 가지고 있습니다.
     * false를 넘김으로써 웹뷰에서 다이얼로그가 처음 켜질 때 포커스 링이 보이는 것을 방지할 수 있습니다.
     *
     * @todo
     * This code will be removed in the future.
     * However, the behavior of focus remains the same, but it is necessary to discuss whether to show the focus ring or not.
     * The point of concern is whether the focus ring should be shown even when the focus is moved by pressing the tab continuously.
     *
     * @note
     * `initialFocusEl` is currently not provided by zag, but it originally has a type of MaybeElement | false.
     * By passing false, you can prevent the focus ring from appearing when the dialog is first turned on in the web view.
     */
    initialFocusEl: false as any,
  });

  const { isOpen, backdropProps, positionerProps } = api;

  return (
    <AlertDialogContext.Provider
      value={{
        api,
      }}
    >
      {isOpen && (
        <Portal>
          <div
            {...backdropProps}
            className={composeClassName("seed-alert-dialog")}
          />
          <section
            {...positionerProps}
            className={composeClassName("seed-alert-dialog")}
          >
            {children}
          </section>
        </Portal>
      )}
    </AlertDialogContext.Provider>
  );
};
