import type React from "react";

export const controlStyle: React.CSSProperties = {
  touchAction: "none",
  userSelect: "none",
};

export const trackStyle: React.CSSProperties = {
  position: "relative",
};

export const rangeStyle: React.CSSProperties = {
  position: "absolute",
};

export const thumbStyle: React.CSSProperties = {
  display: "block",
};
