import { dataAttr } from "@zag-js/dom-query";
import type { NormalizeProps, PropTypes } from "@zag-js/types";

import { dom } from "./toggle.dom";
import type { MachineApi, Send, State } from "./toggle.types";

export function connect<T extends PropTypes>(
  state: State,
  send: Send,
  normalize: NormalizeProps<T>,
): MachineApi<T> {
  const isSelected = state.matches("selected");

  const isDisabled = state.context.disabled ?? false;

  return {
    isSelected,
    isDisabled,
    setSelected(selected: boolean) {
      send({ type: "SET_STATE", selected, manual: true });
    },
    toggle() {
      send({ type: "TOGGLE" });
    },

    toggleProps: normalize.button({
      "data-part": "root",
      id: dom.getRootId(state.context),
      "data-disabled": dataAttr(isDisabled),
      "data-selected": dataAttr(isSelected),
      "aria-pressed": isSelected,
      onClick() {
        send({ type: "TOGGLE" });
      },
    }),
  };
}
