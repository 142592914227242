import * as React from "react";

import type { SeedSnackbarType } from "./types";

const SnackbarIcon: React.FC<{ type: SeedSnackbarType }> = ({ type }) => {
  if (type === "success") {
    return (
      <svg
        data-part="icon"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.3566 3.83004C19.738 4.10741 19.8223 4.64144 19.545 5.02283L10.0895 17.6803C9.93845 17.888 9.70192 18.0167 9.44547 18.0307C9.18902 18.0447 8.9399 17.9425 8.76713 17.7525L2.51373 10.9654C2.19651 10.6164 2.22222 10.0764 2.57117 9.75919C2.92011 9.44197 3.46014 9.46769 3.77737 9.81663L9.32527 15.8277L18.1638 4.01838C18.4412 3.63699 18.9752 3.55267 19.3566 3.83004Z"
          fill="currentcolor"
        />
      </svg>
    );
  }
  if (type === "warning") {
    return (
      <svg
        data-part="icon"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 6.5102C11.4133 6.5102 11.7483 6.84523 11.7483 7.2585V11.4677C11.7483 11.881 11.4133 12.216 11 12.216C10.5867 12.216 10.2517 11.881 10.2517 11.4677V7.2585C10.2517 6.84523 10.5867 6.5102 11 6.5102Z"
          fill="currentcolor"
        />
        <path
          d="M11.9352 14.7415C11.9352 15.2581 11.5164 15.6769 10.9998 15.6769C10.4832 15.6769 10.0644 15.2581 10.0644 14.7415C10.0644 14.2249 10.4832 13.8061 10.9998 13.8061C11.5164 13.8061 11.9352 14.2249 11.9352 14.7415Z"
          fill="currentcolor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.83334 11C1.83334 5.93739 5.93739 1.83333 11 1.83333C16.0626 1.83333 20.1667 5.93739 20.1667 11C20.1667 16.0626 16.0626 20.1667 11 20.1667C5.93739 20.1667 1.83334 16.0626 1.83334 11ZM11 3.32993C6.76394 3.32993 3.32993 6.76394 3.32993 11C3.32993 15.2361 6.76394 18.6701 11 18.6701C15.2361 18.6701 18.6701 15.2361 18.6701 11C18.6701 6.76394 15.2361 3.32993 11 3.32993Z"
          fill="currentcolor"
        />
      </svg>
    );
  }
  return <></>;
};

export default SnackbarIcon;
