/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
import { createMachine } from "@zag-js/core";
import {
  dispatchInputCheckedEvent,
  trackFormControl,
} from "@zag-js/form-utils";
import { compact } from "@zag-js/utils";

import { dom } from "./radio-group.dom";
import type {
  MachineContext,
  MachineState,
  UserDefinedContext,
} from "./radio-group.types";

export function machine(userContext: UserDefinedContext) {
  const ctx = compact(userContext);
  return createMachine<MachineContext, MachineState>(
    {
      id: "radio",
      initial: "idle",
      context: {
        value: null,
        activeId: null,
        focusedId: null,
        hoveredId: null,
        ...ctx,
      },

      activities: ["trackFormControlState"],

      watch: {
        value: ["syncInputElements"],
      },

      on: {
        SET_VALUE: {
          actions: ["setValue"],
        },
        SET_HOVERED: {
          actions: "setHovered",
        },
        SET_ACTIVE: {
          actions: "setActive",
        },
        SET_FOCUSED: {
          actions: "setFocused",
        },
      },

      states: {
        idle: {},
      },
    },

    {
      activities: {
        trackFormControlState(ctx, _evt, { send, initialContext }) {
          return trackFormControl(dom.getRootEl(ctx), {
            onFieldsetDisabledChange(disabled) {
              ctx.disabled = disabled;
            },
            onFormReset() {
              send({ type: "SET_VALUE", value: initialContext.value });
            },
          });
        },
      },

      actions: {
        setValue(ctx, evt) {
          set.value(ctx, evt.value);
        },
        setHovered(ctx, evt) {
          ctx.hoveredId = evt.value;
        },
        setActive(ctx, evt) {
          ctx.activeId = evt.value;
        },
        setFocused(ctx, evt) {
          ctx.focusedId = evt.value;
        },
        syncInputElements(ctx) {
          const inputs = dom.getInputEls(ctx);
          inputs.forEach((input) => {
            input.checked = input.value === ctx.value;
          });
        },
      },
    },
  );
}

const invoke = {
  change: (ctx: MachineContext) => {
    if (ctx.value == null) return;
    ctx.onChange?.(ctx.value);

    const inputEls = dom.getInputEls(ctx);
    inputEls.forEach((inputEl) => {
      const checked = inputEl.value === ctx.value;
      if (checked === inputEl.checked) return;
      dispatchInputCheckedEvent(inputEl, { checked });
    });
  },
};

const set = {
  value: (ctx: MachineContext, value: string) => {
    if (ctx.value === value) return;
    ctx.value = value;
    invoke.change(ctx);
  },
};
