import "@daangn/sprout-css/button/index.css";

import type { ButtonElementType } from "@daangn/sprout-hooks-button";
import { useButton } from "@daangn/sprout-hooks-button";
import { useFocusVisible } from "@daangn/sprout-hooks-interactions";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import { classNames } from "@seed-design/design-token";
import type { ElementType, Ref } from "react";
import * as React from "react";

import type { SeedTextButtonProps } from "./types";

const boldTypographyClassName: Record<
  Required<SeedTextButtonProps>["size"],
  string
> = {
  large: classNames.$semantic.typography.label1Bold,
  medium: classNames.$semantic.typography.label2Bold,
  small: classNames.$semantic.typography.label3Bold,
};

const regularTypographyClassName: Record<
  Required<SeedTextButtonProps>["size"],
  string
> = {
  large: classNames.$semantic.typography.label1Regular,
  medium: classNames.$semantic.typography.label2Regular,
  small: classNames.$semantic.typography.label3Regular,
};

const TextButton = <T extends ButtonElementType>(
  props: SeedTextButtonProps<T>,
  ref: Ref<HTMLElement>,
) => {
  const {
    elementType: Element = "button" as ElementType,
    size = "medium",
    variant = "primary",
    style = "basic",
    bold = false,
    resetPadding = false,
    children,
    ...otherProps
  } = props;
  const { buttonProps } = useButton(props);
  const { focusVisibleProps } = useFocusVisible();
  const { styleProps } = useStyleProps(otherProps);

  return (
    <Element
      ref={ref}
      {...styleProps}
      {...focusVisibleProps}
      {...buttonProps}
      data-size={size}
      data-variant={variant}
      data-style={style}
      data-reset-padding={resetPadding ? "" : undefined}
      className={composeClassName(
        "seed-text-button",
        bold ? boldTypographyClassName[size] : regularTypographyClassName[size],
        styleProps.className,
      )}
    >
      {typeof children === "string" ? <span>{children}</span> : children}
    </Element>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _TextButton = React.forwardRef(TextButton);
export { _TextButton as TextButton };
