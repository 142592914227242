import { createScope } from "@zag-js/dom-query";

import type { MachineContext as Ctx } from "./text-field.types";

export const dom = createScope({
  getRootId: (ctx: Ctx) => ctx.ids?.root ?? `textfield:${ctx.id}`,
  getLabelId: (ctx: Ctx) => ctx.ids?.label ?? `textfield:${ctx.id}:label`,
  getInputId: (ctx: Ctx) => ctx.ids?.input ?? `textfield:${ctx.id}:input`,
  getDescriptionId: (ctx: Ctx) =>
    ctx.ids?.description ?? `textfield:${ctx.id}:description`,
  getErrorMessageId: (ctx: Ctx) =>
    ctx.ids?.errorMessage ?? `textfield:${ctx.id}:errormessage`,

  getDescriptionEl: (ctx: Ctx) => dom.getById(ctx, dom.getDescriptionId(ctx)),
  getErrorMessageEl: (ctx: Ctx) => dom.getById(ctx, dom.getErrorMessageId(ctx)),
  getInputEl: (ctx: Ctx) =>
    dom.getById<HTMLInputElement>(ctx, dom.getInputId(ctx)),
});
